html {
  height: 100%;
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background: #040143;
  color: #fff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
a {
  color: #3b02b9;
  text-decoration: none;
}
a:hover {
  color: #3b02b9;
  text-decoration: underline;
}
.font-weight-light {
  font-weight: 300 !important;
}
iframe {
  display: none !important;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #777777;
}
.footer_panel_left p {
  margin: 10px;
  font-size: 16px;
  color: #A8A4FF;
}
.brand_logo {
  width: 165px;
}

.addressBox label {
    width: 150px;
    text-align: right;
}
.addressBox {
    margin-bottom: 15px;
}
.main_navbar {
  background-color: transparent  !important;
  z-index: 10;
  transition: 0.2s all;
}
.main_navbar.fixed_navbar {
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #000 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 10;
}
/*.main_navbar_home.fixed_navbar{
  top: 42px;
}*/
.navbar_right {
  display: flex;
  align-items: center;
}
.navbar_right .autopool_btn{
  background: linear-gradient(220deg, rgb(183 212 65) 0%, rgb(0 102 143) 100%)  ;
  border-color: #5cca68  ;
  /*font-family: "Oleo Script", cursive;
  font-weight: 500;*/
  padding-left: 45px;
  padding-right: 45px;
}
.autopool_btn:hover::before {
  box-shadow: inset 0 0 0 10em #389c83 !important;
}
.navbar_right .plan_btn{
  background: linear-gradient(220deg, rgb(38 73 198) 0%, rgb(12 19 89) 100%) !important ;
  border-color: #2447c2 !important ;
}
.plan_btn:hover::before {
  box-shadow: inset 0 0 0 10em #2446c3 !important;
}
.navbar_right .plan_btn a{
  height: 100%;
  display: inline-block;
}
.joinNowBg{
  background: url(http://localhost:3000/static/media/partner_bg.3caf692a8bad09deb58a.png) no-repeat center center !important;
}
.joinNowBg .inner_title{
  font-size: 60px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.4px;
  color: #fff;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.joinnowplan h2{
  color: #28229a !important;
  font-weight: 600;
}
.joinnowplan .join_now_panel .primary_box.dash_box{
  border: 1px solid #4b4eb5;
}
.logout_btn {
  background: transparent;
  border: 2px solid transparent;
  color: #fff;
  font-size: 20px;
  margin-right: 15px;
  transition: 0.2s all;
  padding: 5px 20px;
  border-radius: 30px;
}
.logout_btn:hover {
  background: #021039;
  border: 2px solid #0b1c47;
}
.logout_btn img {
  margin-right: 10px;
}
.page_header {
  position: relative;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.page_header::before {
  content: "";
  background: url("./assets/images/banner_img.png") no-repeat right;
  width: 100%;
  height: 2694px;
  position: absolute;
  top: -138px;
  right: 100px;
  z-index: -1;
}
.page_header h1 {
  color: #FFF;
  text-align: center;
  font-size: 60px;  
  font-weight: 800;
  line-height: 80px;
  letter-spacing: -2.4px;
  text-transform: capitalize;
}
.form_chk input{
  box-shadow: none !important;
}
.form_chk a {
  color: #44b1fd !important;
}
.page_header h5 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
.banner_img {
  position: relative;
}
section {
  padding: 80px 0;
}
.main_title_small {
  color: #96A0FF;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.main_title {
  font-size: 60px;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.4px;
  color: #fff;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.inner_para {
  font-size: 18px;
}
.primary_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  background: #fff;
  background-clip: padding-box;
  border: 2px solid #eeeeee;
  box-shadow: 0px 5px 5px 1px rgb(0 0 0 / 8%);
  border-radius: 20px;
  transition: 0.2s all;
  overflow: hidden;
}
section.features_section {
  position: relative;
  background: #fff;
  padding-bottom: 0;
}
.features_section::before {
  content: "";
  background: url("./assets/images/community_bg.png") no-repeat center;
  width: 100%;
  height: 1279px;
  position: absolute;
  top: -200px;
}
.features_box {
  cursor: default;
  height: 100%;
  align-items: center;
  background: transparent;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0);
  border-radius: 0;
  padding: 30px;
  min-height: 320px;
  text-align: center;
  border: 1px solid transparent;
}
.features_box:hover {
  background: linear-gradient(180deg, rgba(4, 2, 56, 0.00) 0%, #16127E 121.45%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.features_box h4 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: capitalize;
  line-height: normal;
  white-space: normal;
  word-break: break-all;
  color: #fff;
}
.features_box p {
  font-size: 16px;
  line-height: 28px;
  color: #96A0FF;
  margin-bottom: 0;
}
.features_box img {
  margin-bottom: 20px;
}
.features_box > div {
  margin-bottom: 20px;
}
.primary_box p {
  margin-bottom: 0;
}
.features_section_row [class*="col-"] {
  margin-bottom: 30px;
}
.counter_panel {
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-align: center;
  max-width: 70%;
  margin: 50px auto 0;
}
.counter_panel h3 {
  font-size: 36px;
  font-weight: 800;
  line-height: 50px;
  margin: 0;
}
.counter_panel h6 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.counter_panel img {
  margin-bottom: 10px;
}
.counter_panel > div {
  width: 30%;
}
.grey_bg {
  background: #172026;
  color: #ffffff;
}
.plan_tab.nav-pills li button.nav-link {
  border: 2px solid #223359;
  background-color: #111f3d;
  color: #7a7e89;
  margin: 0 10px;
}
.plan_tab.nav-pills li button.nav-link.active {
  color: #fff;
}
.plan_income_table {
  margin: 20px 0 0;
  width: 80%;
}
.plan_income_table th,
.plan_income_table td {
  border: 0 !important;
  vertical-align: middle;
}
.plan_income_table th {
  color: #0b162e;
  font-size: 18px;
  font-weight: 600;
}
.plan_income_table td {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 15px;
  text-align: center;
}
.plan_income_table_div {
  border-radius: 20px;
  border: 1px solid #0F0B66;
  background: rgba(13, 13, 13, 0.2);
  width: 100%;
  margin: 0px auto 0;
  padding: 30px;
  z-index: 10;
  position: relative;
}
.plan_income_table > tr:first-child {
  border-bottom: 1px solid #0F0B66;
}
.plan_income_table > tr td {
  border-left: 1px solid #0F0B66 !important;
}
.plan_income_table th {
  background-color: #e84283;
  color: #fff;
}
.plan_income_table tbody tr {
  border: 0;
}
.plan_income_table tbody tr:nth-child(even) {
  background-color: #d23e78;
}
.plan_income_table tbody tr:nth-child(odd) {
  background-color: #a61951;
}
.income_section h4 {
  color: #96A0FF;
  margin-top: 30px;
  font-size: 26px;
  font-weight: 600;
  line-height: 40px;
}
.income_section h5 {
  font-size: 18px;
  font-weight: 700;
}
.income_section h5.total_level_income {
  transform: rotate(-90deg);
  flex-shrink: 0;
  color: #FFF;  
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
}
.income_section h5.total_level_income::before {
  content: "";
  background: url("../src/assets/images/content_plus.png") center;
  width: 53.673px;
  height: 111px;
  position: absolute;
  top: -45px;
  transform: rotate(271deg);
  left: 240px;
}
.global_pool_list {
  margin: 20px 0 0;
  padding: 0;
  list-style: none;
  max-width: 78%;
}
.global_pool_list li {
  position: relative;
  color: #96A0FF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding-left: 40px;
  margin-bottom: 30px;
  position: relative;
}
.global_pool_list li span {
  font-size: 18px;
  font-weight: 700;
}
.global_pool_list li::before {
  content: "";
  background: url("./assets/images/list_arrow.png");
  width: 30px;
  height: 30px;
  position: absolute;
  top: 2px;
  left: 0;
}
.pink_bg .main_title {
  color: #ffffff;
}
.txt_bold {
  font-weight: 700;
}
.how_works_section h5 {
  font-weight: 600;
}
.how_works_section .primary_btn {
  background: #fff;
  color: #e6007a;
  font-size: 30px;
  font-weight: 800;
  padding: 0px 50px;
  border-radius: 30px;
}
.how_works_section .primary_btn:hover {
  background: transparent;
}
.how_works_section .primary_btn:hover::before {
  box-shadow: inset 0 0 0 10em #ff6ac0;
}
#faq_accodian {
  margin-top: 20px;
}
#faq_accodian .accordion-header button {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  background: transparent;
  color: #fff;
  padding-left: 30px;
}
#faq_accodian .accordion-header,
#faq_accodian .accordion-body {
  background-color: transparent;
}
#faq_accodian .accordion-item {
  position: relative;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #8490FF;
  background: #040143;
  transition: 0.2s all;
  overflow: unset;
  margin-bottom: 30px;
}
#faq_accodian .accordion-body {
  padding-top: 0;
}
#faq_accodian .accordion-body p {
  font-size: 16px;
  color: #96A0FF;
  margin-bottom: 0;
  cursor: default;
  padding-left: 10px;
  max-width: 85%;
}
.accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}
.accordion-button::after {
  filter: brightness(0) invert(1);
}
.footer {
  background-color: #02002B;
  padding: 30px 0 20px;
  z-index: 10;
  position: relative;
}
.footer_panel_left {
  text-align: center;
}
.footer_panel_left p {
  margin-bottom: 0;
  font-size: 16px;
  color: #A8A4FF;
}
.footer_panel_left a {
  font-size: 16px;
  color: #fff;
  white-space: normal;
  word-break: break-all;
}
.footer_social_links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 15px 0 0;
  padding: 0;
}
.footer_social_links li {
  margin-right: 15px;
}
.footer_social_links li a {
  width: 44px;
  height: 44px;
  background: radial-gradient(90.42% 63.33% at 43.33% 0%, #48459B 0%, #040143 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  font-size: 24px;
  text-decoration: none;
  transition: 0.2s all;
}
.footer_social_links li a i {
  background-image: radial-gradient(63.88% 63.88% at 59.87% 45.44%, #FFF 0%, #2EA7FF 95.69%);
  filter: drop-shadow(0px 0px 15px rgba(157, 226, 255, 0.50));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer_social_links li a:hover {
  background: radial-gradient(90.42% 63.33% at 43.33% -0.00%, #010101 0%, #171717 100%);
}
.primary_btn {
  position: relative;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid #e6007a;
  border-radius: 10px;
  padding: 0;
  outline: none;
  overflow: hidden;
  color: #e6007a;
  background: transparent;
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
  line-height: 250%;
  z-index: 1;
  text-transform: uppercase;
  padding: 5px 30px;
}

.primary_btn:hover {
  color: #fff;
}
.primary_btn:hover::before {
  box-shadow: inset 0 0 0 10em #e6007a;
}
.primary_btn .wallet_icon {
  transition: 0.02s all ease-in-out;
  margin-right: 10px;
  height: 20px;
}
.primary_btn:hover .wallet_icon {
  filter: brightness(0) contrast(1) invert(1);
}
.navbar_right .primary_btn {
  margin: 0;
}
.navbar_right .primary_btn a {
  color: #fff;
  text-decoration: none;
}
.d_d_none {
  display: none !important;
}
.inner_para a {
  word-break: break-all;
  font-weight: 500;
}

/* Inner Pages */
.inner_wrapper {
  min-height: 80vh;
  padding: 80px 0;
  position: relative;
  background: #fafafa;
}
.dashboard_panel.join_now_panel {
  min-height: 92vh;
}
.title_flex {
  display: flex;
  text-align: center;
  margin-bottom: 30px;
  justify-content: space-between;
}
.dashboard_panel.join_now_panel .title_flex {
  display: block;
}
.dashboard_panel.join_now_panel .primary_btn {
  margin-top: 30px;
}
.inner_title {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0 !important;
}
.wallet_div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet_div label {
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  margin: 0px 5px 0 0;
}
.primary_inp {
  border: 1px solid #212121;
  background: transparent;
  font-size: 16px;
  border-radius: 10px;
  padding: 7px 12px;
}
.primary_inp:focus {
  border: 1px solid #212121;
  background: transparent;
  box-shadow: none !important;
}
.primary_inp::placeholder {
  color: #909090;
}
.inp_grp {
  position: relative;
}
.inp_append {
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}
.wallet_div input.primary_inp {
  width: 300px;
  background: transparent;
  box-shadow: none;
  color: #e6007a !important;
  font-size: 16px;
  padding: 8px 40px 8px 15px;
  border: 1px solid #e6007a;
}
button.copy_btn {
  background: transparent;
  border: 0;
  position: absolute;
  right: -10px;
  top: -7px;
}
.primary_box.dash_box.pink_bg {
  border: 0;
  margin-bottom: 30px;
  padding: 15px;
  box-shadow: none;
}
.grey_dash_box.primary_box.dash_box {
  box-shadow: none;
  background: #e7e7e7;
}
.grey_dash_box_div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
}
.grey_dash_box_div h2 {
  font-size: 24px;
  font-weight: 400;
}
.grey_dash_box_div h2 span {
  font-weight: 600;
}
.grey_dash_box_div h4 {
  font-size: 16px;
  font-weight: 400;
}
.grey_dash_box_div .primary_btn,
.dash_sidebox .primary_btn {
  padding: 0 25px;
  line-height: 200%;
}
.grey_dash_box_div div button + button {
  margin-left: 15px;
}
img.copy_icon.wallet_icon {
  margin-right: 0;
}
.wallet_div .inp_grp {
  display: flex;
  align-items: center;
}
.dash_box_left {
  padding: 10px 0px;
  border-bottom: 1px solid #031542;
}
.dash_box_left:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.dash_box_left:first-child {
  padding-bottom: 20px;
}
.dash_box_left h2 {
  font-size: 24px;
  font-weight: 700;
}
.dash_box_left h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.dash_box_left h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}
.dash_box_left h3 span {
  color: #e8d7f8;
  font-size: 20px;
}
.dash_box_left p {
  color: #e8d7f8;
  font-size: 18px;
  margin-bottom: 0;
}
.dash_box_left h4 {
  color: #e8d7f8;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.dash_box .form-control.primary_inp{
  color: #ffffff;
  background: #0F0B66 !important;
  box-shadow: none !important;
  border: 0 !important;
}
.dashbox_middle_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
  flex-wrap: wrap;
}
.dashbox_middle_top h2 {
  font-size: 32px;
  font-weight: 700;
  color: #fff;
}
.dashbox_middle_top h4 {
  color: #FFF;
  font-size: 36px;
  font-weight: 700;
  line-height: normal;
}
.dashbox_middle_top > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: default;
  position: relative;
}
/* .dashbox_middle_top > div::after {
  content: "";
  background: url("./assets/images/line_shape.png") no-repeat;
  width: 2px;
  height: 69px;
  position: absolute;
  top: 8px;
  right: -60px;
} */
.dashbox_middle_top > div:last-child:after {
  display: none;
}
.grey_dash_box.primary_box.dash_box .primary_btn .wallet_icon {
  filter: brightness(0) invert(1);
}
.dashbox_middle_bottom h2 {
  font-size: 20px;
  font-weight: 700;
  color: #e6007a;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
.dashbox_middle_bottom h5 {
  font-size: 24px;
  font-weight: 500;
}
.dashboard_top_wrap [class*="col-"] {
  margin-bottom: 30px;
}
.affiliate_box h3 {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.affiliate_box ul.footer_social_links li a {
  width: 28px;
  height: 28px;
  font-size: 14px;
  background: #e6007a;
  border: 1px solid #e6007a;
  color: #fff;
}
.affiliate_box ul.footer_social_links {
  justify-content: center;
  margin-top: 15px;
}
.affiliate_box ul.footer_social_links li {
  margin: 0 10px 0px 0;
}
.affiliate_box input.primary_inp {
  width: 100%;
  background: transparent;
  box-shadow: none;
  color: #e6007a !important;
  font-size: 16px;
  padding: 8px 40px 8px 15px;
  border: 1px solid #e6007a;
}
.dash_sidebox {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 2px solid #eeeeee;
  border-radius: 20px;
  box-shadow: 0px 5px 5px 1px rgb(0 0 0 / 8%);
  overflow: hidden;
}
.dash_sidebox_left {
  width: 20%;
  min-width: 20%;
  background: #e6007a;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.dash_sidebox_right h3 {
  font-size: 20px;
  font-weight: 600;
  color: #e6007a;
}
.dash_sidebox_right h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.dashboard_top_wrap > [class*="col-"]:last-child {
  margin-bottom: 0;
}
.search_div {
  width: 50%;
  margin: 50px auto 0;
}
.search_div button {
  background: linear-gradient(220deg, rgba(249,150,36,1) 0%, rgba(248,66,0,1) 100%);
  border: none;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  top: 1px;
  right: 0;
  border-radius: 0 10px 10px 0;
  padding: 8px 20px;
}
.plan_wrapper {
  /* display: flex;
  flex-flow: wrap;
  justify-content: center; */
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.buy_item {
  background-color: #0b162e;
  padding: 20px 10px 20px;
  border-radius: 20px;
  position: relative;
  /* width: 200px; */
  margin: 50px 0 20px;
  cursor: default;
}
.buy_item h4 {
  font-size: 16px;
  font-weight: 500;
  color: #e6007a;
}
.buy_item h5 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.buy_item .primary_btn {
  padding: 4px 12px;
  font-size: 16px;
  margin: 15px auto 0;
  width: auto !important;
}
.dashbox_middle_bottom .primary_btn {
  width: 100%;
  justify-content: center;
}
.buy_count {
  background: #e6007a;
  border: 1px solid #e6007a;
  position: relative;
  border-radius: 50%;
  margin: -75px auto 15px;
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.buy_count p {
  font-size: 14px;
  color: #0b162e;
  font-weight: 700;
}
.buy_count h2 {
  font-size: 36px;
  color: #0b162e;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 40px;
}
.buy_count h3 {
  font-size: 24px;
  color: #0b162e;
  font-weight: 700;
  margin: 0;
}
.buy_data {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.buy_data_item {
  display: flex;
  align-items: center;
}
.buy_data_count p {
  font-size: 18px;
  color: #fff;
  line-height: 26px;
  margin-left: 5px;
}
.chance_panel h2 {
  font-size: 24px;
  text-transform: uppercase;
}
.buy_item.buy_item_disabled {
  opacity: 0.6;
  pointer-events: none;
  user-select: none;
}
.buy_item:hover .buy_count {
  animation-name: hvr-pulse;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.primary_box.dash_box {
  padding: 20px;
}
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(344deg, #040146 8.19%, #030043 13.2%, #040146 21.45%, #030142 41.51%);

}
.primary_modal .modal-header {
  border-bottom: 1px solid #0F0B66;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  background-color: transparent;
  border: 1px solid #8490ff;
  border-radius: 23px;
  color: #fff;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}
.connect_wallet_button:hover {
  border-color: #f84a04;
}
.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: #fff;  
}
.connect_wallet_button img {
  margin-right: 15px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close_icon.png") no-repeat;
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}
.btn-close.autopool_modal_close {
  width: 42px;
  height: 42px;
  color: #000;
  border: 0;
  background: transparent url("../src/assets/images/close-icon.png") no-repeat;
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
  position: absolute;
  background-size: 100%;
  right: 15px;
  top: 15px;
}
.btn-close:hover {
  opacity: 1;
}
.primary_modal a {
  text-decoration: none;
}
.node circle {
  fill: #f98d20 !important;
  stroke: #f98d20 !important;
  stroke-width: 1.5px !important;
  background: url("../src/assets/images/tree_icon.png");
  width: 13px;
  height: 21px;
}
.node text {
  font-size: 11px !important;
  background-color: #444 !important;
  fill: #1e1e1e !important;
}
.node {
  cursor: pointer;
}
path.link {
  stroke: #f98d20 !important;
  stroke-width: 1.5px !important;
}
.dash_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0px;
  cursor: pointer;
  margin-right: 15px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #e6007a;
  box-shadow: 0 0 0 3px #e6007a;
  transition: color 0.3s;
}
.dash_icon:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  background: #e6007a;
  transition: transform 0.2s, opacity 0.2s;
}
.dash_icon:after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.dashbox_middle_top > div:hover .dash_icon::after {
  transform: scale(0.85);
}
.address_btn {
  display: inline-block;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  width: 200px;
}
ul.walletdrop {
  background: radial-gradient(232.58% 162.91% at 31% 158.89%, #717171 0%, #1B124E 50.34%, #201B89 100%);
  border: 0px solid #f99624;
}
ul.walletdrop li a {
  color: #eee;
  text-decoration: none;
}
ul.walletdrop li a:hover {
  background-color: rgb(0 0 0 / 20%);
  color: #fff;
}
.modal-body label,
.modal-body input,
.modal-body input:focus {
  color: #fff;
}
.modal-body .primary_btn,
.join_now_panel .primary_btn {
  padding: 0 25px;
  line-height: 200%;
  text-transform: capitalize;
  font-size: 16px;
}
.modal-body button + button {
  margin-left: 10px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.st-center {
  display: flex !important;
}
.st-btn {
  width: 28px;
  height: 28px;
  font-size: 14px;
  background-color: #e6007a !important;
  border: 1px solid #e6007a !important;
  color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.st-btn > img {
  top: unset !important;
}
.join_now_panel .primary_box.dash_box {
  height: 100%; 
  border-radius: 20px;
  border: 1px solid #0F0B66;
  background: rgba(13, 13, 13, 0.2);
  padding: 30px;
  text-align: center;
  border: 1px solid #44c1fa;
}
.settings_row [class*="col-"] {
  margin-bottom: 30px;
}
.join_now_panel .primary_box.dash_box h2 {
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
div#google_translate_element {
  position: fixed;
  top: 8px;
  right: 8px;
  background: #051254;
  /*width: 100%;*/
  z-index: 999;
}
select.goog-te-combo {
  background: #021039;
  border: 2px solid #0b1c47;
  border-radius: 30px;
  color: #fff;
  font-size: 12px;
  height: 35px;
  outline: none;
  padding: 4px;
  position: absolute;
  right: 15px;
  top: 5px;
  transition: all 0.2s;
  z-index: 10;
}
.lottery_list .inner_title{
  margin-bottom: 20px !important;
}
.goog-te-gadget {
  color: transparent !important;
}
.goog-logo-link {
  display: none !important;
}

#presale_info h2 {
  color: #008bd0;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}
#presale_info img {
  width: 150px;
  margin: 0 auto;
  text-align: center;
  display: block;
}
#presale_info .primary_btn {
  border-color: #008bd0;
  color: #008bd0;
  padding: 6px 35px;
}
#presale_info .primary_btn a {
  color: #008bd0;
}
#presale_info .primary_btn:hover::before {
  box-shadow: inset 0 0 0 10em #008bd0;
}
#presale_info .primary_btn:hover a {
  color: #fff;
}


.settings .primary_btn {
  line-height: unset;
}


.lottery_list .table-responsive {
  background: #181818;
  border-radius: 30px;
}
.lottery_list .as-react-table {
  padding: 15px;
  color: #ffffff;
  white-space: nowrap;
  border-radius: 30px;
  background: #000000;
  border: 1px solid #1e1e1e;
}
.lottery_list table {
  color: #ffffff;
  border-color: #1e1e1e;
}
.lottery_list table button {
  background: #3b00b9;
  border: 0;
  box-shadow: none !important;
}
.lottery_list tbody {
  border-color: #1e1e1e !important;
}
.lottery_list td {
  color: #ffffff !important;
  vertical-align: middle;
}
.lottery_list .butn {
  font-size: 16px;
  border-radius: 5px;
  margin: 0;
}
.lottery_list .input-group-addon {
  padding: 6px !important;
  background: #beaed2 !important;
}
.lottery_list .input-group-text {
  color: #1e1e1e;
}
.lottery_list select {
  background: #beaed2;
  color: #1e1e1e;
  border: 0;
  box-shadow: none !important;
}
.lottery_list select:focus {
  background: #beaed2;
  color: #1e1e1e;
}
.lottery_list option {
  background: #beaed2;
  color: #1e1e1e;
}
.lottery_list .page-item.disabled .page-link {
  background-color: #cecdea;
  color: #939393;
  border-color: #dcdcdc;
}
.lottery_list .page-link {
  background-color: #cecdea;
  color: #1e1e1e;
  border-color: #dcdcdc;
  box-shadow: none !important;
}
.lottery_list .page-link input {
  background-color: #d0cfee;
  color: #1e1e1e;
  box-shadow: 0px 0px 1px 1px rgb(138 104 158);
  outline: 0 !important;
}
.dflx-none {
  display: block;
}
.autopool_modal .modal-content {

  background: #fff;  
  border: 4px solid #f05831;
  /* border: 4px solid #5c78e5; */
  border-radius: 50px;
}
.autopool_modal_v1 .modal-content {
  background: url("../src/assets/images/autopool_modal_bg2.png") no-repeat center;
  background-size: cover;
}
.autopool_modal .modal-body {
  text-align: center;
  padding: 50px;
}
.autopool_modal .modal-body h1 {
  font-family: 'Changa One', cursive;
  font-size: 56px;
  font-weight: 700;
  color: #f58863;
  margin: 30px 0 15px;
}
.autopool_modal .modal-body h2 {
  font-size: 38px;
  font-weight: 500;
  color: #111;
  margin-bottom: 30px;
}
.autopool_modal_v1  h2{
  color: #fff !important;
}
.autopool_modal_chgs .modal-body  img{
  border-radius: 20px;
  height: 600px;
}
.autopool_modal_chgs .modal-content {
  background: transparent;
  border: 0;
}
.autopool_modal_chgs .text-end{
  position: absolute;
  right: 19%;
  z-index: 1;
  top: -15px;
}
.autopool_modal_chgs .whole_div{
  position: relative;
}
.buttn {
  color: #fff !important;
  border: 2px solid #fff !important;
  border-radius: 14px;
  padding: 8px 55px !important;
  font-size: 20px !important;
  margin-bottom: 50px;
  font-weight: 800;
}
.fontadj {
  font-size: 32px !important;
  line-height: 46px;
  font-family: 'Changa One', cursive;
}
.fontadj span {
  color: #ed5480;
}
.ulcls {
  list-style-type: none;
  margin: 0 auto;
  max-width: 50%;
  padding: 0;
  font-size: 24px;
  color: #fff;
  font-family: "Oleo Script", cursive;
}
.ulcls li {
  display: flex;
  justify-content: space-between;
  margin: -10px 0;
}
.ulcls li span {
  color: #f58863;
}

/* auto pool  */
.autopool {
  background: #f1eef5;
  min-height: 100vh;
}
.autopool .details {
}
.autopool .details .box {
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.autopool .details .box .round {
  background: #3b04b9;
  padding: 10px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -36px;
}
.autopool .details .box p {
  font-size: 20px;
  font-family: 600;
  color: #fff;
  margin-bottom: 0;
}
.autopool .details .box h4 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}
.autopool .details .box .shape {
  /* background: linear-gradient(#408fc0,#3b04b9);
  position: relative;
  padding: 30px;
  text-align: center;
  border: 2px solid #fff;
  border-radius: 0 80px 80px 0;
  border-left: 0;
  padding-left: 60px; */
  background: url("./assets/images/poolimg.png") no-repeat center;
  height: 140px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
}
.autopool .details .box .shape::before {
  /* content: "";
  position: absolute;
  width: 20%;
  height: 103%;
  background: #f0edf4;
  top: -2px;
  left: 0;
  border-radius: 0 50% 50% 0;
  border: 3px solid #fff;
  border-width: 0px 2px 0px 0; */
}

.pooladdress {
  background: #f1eef5;
  padding-top: 0;
}
.pooladdress .table-responsive {
  background: #f9f8fb;
  padding-bottom: 6px;
}

.pooladdress table {
  color: #1e1e1e;
  border-color: #eeeeee;
}
.pooladdress table button {
  background: #3b00b9;
  border: 0;
  box-shadow: none !important;
}
.pooladdress tbody {
  border-color: #eeeeee !important;
}
.pooladdress td {
  color: #574f4f !important;
  vertical-align: middle;
}
.pooladdress .butn {
  font-size: 16px;
  border-radius: 5px;
  margin: 0;
}
.pooladdress .input-group-addon {
  padding: 6px !important;
  background: #beaed2 !important;
}
.pooladdress .input-group-text {
  color: #1e1e1e;
}
.pooladdress select {
  background: #beaed2;
  color: #1e1e1e;
  border: 0;
  box-shadow: none !important;
}
.pooladdress select:focus {
  background: #beaed2;
  color: #1e1e1e;
}
.pooladdress option {
  background: #beaed2;
  color: #1e1e1e;
}
.pooladdress .page-item.disabled .page-link {
  background-color: #fbfbff;
  color: #939393;
  border-color: #dcdcdc;
}
.pooladdress .page-link {
  background-color: #fbfbff;
  color: #1e1e1e;
  border-color: #dcdcdc;
  box-shadow: none !important;
}
.pooladdress .page-link input {
  background-color: #fbfbff;
  color: #1e1e1e;
  box-shadow: 0px 0px 1px 1px rgb(194, 189, 197);
  outline: 0 !important;
}

.pooladdress .box {
  padding: 30px;
  border-radius: 30px;
  background: #f9f8fb;
  border: 2px solid #fff;
}
.pooladdress th {
  border: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 10px;
  background: #4d31b6;
}
.pooladdress td {
  color: #1e1e1e !important ;
  border: 0;
  border-bottom: 2px solid #ffffff;
}
.pooladdress td:nth-child(1),
.pooladdress th:nth-child(1) {
  padding-left: 20px;
}
.pooladdress td:nth-last-child(1),
.pooladdress th:nth-last-child(1) {
  padding-right: 20px;
  text-align: right;
}
.pooladdress td:nth-last-child(2),
.pooladdress th:nth-last-child(2) {
  text-align: right;
}
.pooladdress table tbody > tr:nth-of-type(odd) {
  background: #eae5ff;
}
.pooladdress h2 {
  background: -webkit-linear-gradient(0deg, #3b02b9 40%, #2873b7 81%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  word-break: break-word;
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
}

.autopoolban {
  text-align: center;
  background: url("./assets/images/autopoolbg.png") no-repeat center;
  background-position: 50% 100%;
  padding-bottom: 140px;
}
.autopoolban .autopool_box h1 {
  font-size: 54px;
  color: #ffffff;
  font-weight: 900;
}
.autopoolban .autopool_box p {
  color: #96A0FF;  
  font-size: 22px;
  margin: 40px auto 0;
}
.autopoolban .autopool_box p span {
  color: #fff;
  font-weight: 700;
}
.autopoolban .autopool_box button {
  line-height: unset;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 2px solid #fff;
}
.autopoolban .autopool_box .input-group {
  max-width: 50%;
  margin: 5px auto;
}
.autopoolban .autopool_box .joinnow {
  background: #3b00b9;
  color: #fff;
}
.autopoolban .autopool_box .withdraw {
  background: #d4326a;
  color: #fff;
  margin-left: 15px;
}
.autopoolban .autopool_box input.form-control {
  padding: 10px;
  box-shadow: none;
  border: 0;
}
.autopoolban .autopool_box .input-group-text {
  color: #3b04b9;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  background: #f1eef5;
}
.autopool_wallet_div input {
  background: #ffffff !important ;
  border: 0 !important ;
  color: #909090 !important ;
}
.autopoollive_modal .modal-content {
  background: #040143 url("../src/assets/images/cosmos_modal_bg.png") no-repeat center top -10px;
  background-size: 100%;
  border: 2px solid #0F0B66;
  border-radius: 50px;
}
.autopoollive_modal .autopoolban {
  background: transparent;
  padding: 0;
}
.autopoollive_modal h2 {
  text-align: center;
  font-size: 32px;
  color: #fff;
  font-weight: 700;
}
.timer_panel {
  text-align: center;
  display: flex;
  justify-content: center;
}
span.timer_label {
  display: block;
  color: #fff;
  margin-left: 3px;
}
span.timer_time {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  background: #2e817b;
  padding: 10px 8px;
}
span.timer_dots {
  color: #fff;
  margin-top: 0px !important;
}
.timer_panel > span {
  margin: 0 5px;
}
.main_navbarinner .primary_btn a {
  color: #fff;
  text-decoration: none;
}
.bonjour_modal_full {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
}
.bonjour_autopool_modalinfo,
.bonjour_presale_modalinfo {
  width: 42%;
  position: relative;
}
.autopool_modal_v1 .bonjour_autopool_modalinfo,
.autopool_modal_v1 .bonjour_presale_modalinfo {
   width: 100%;
}
.autopool_modal_v1 .bonjour_autopool_modalinfo::after{
  display: none;
}
.autopool_modal_v1 .bonjour_modal_full{
  flex-direction: column;
}
.bonjour_autopool_modalinfo::after {
  content: "";
  background: #878787;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -45px;
}
.modal_footer {
  position: relative;
}
.fontadj span.airdrop_txt {
  color: #cedd65;
  font-size: 42px;
  display: block;
  line-height: 56px;
}
.bonjour_modal_full_chg .box img{
  height: 100px;
  width: unset !important;
  margin-bottom: 20px;
}
.bonjour_modal_full_chg .box p{
  color: #e3e3e3;
}
.bonjour_modal_full_chg .box h5{
  font-size: 24px;
  color: #222;
}
.bonjour_modal_full_chg .popup_para{
  color: #000;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 400;
}
.ul li{
  margin-bottom: 10px;
  text-align: left !important;
}
.bonjour_modal_full_chg .popup_para.heads{
      font-size: 28px;
    font-weight: 500;
}
.bonjour_modal_full_chg .buttn{
  text-decoration: none !important;
  font-size: 26px !important;
  margin-top: 14px;
  font-family: "Oleo Script", cursive;
  padding: 2px 28px !important;
}
.bonjour_modal_full_chg .popup_para span{
  background: #f2d23b;
  color: #000;
}
.bonjour_modal_full_chg .popup_para.blw{
  font-weight: 600;
  margin-top: 30px;
  font-size: 29px;
}
.bonjour_autopool_modalinfo button.primary_btn {
  padding: 8px 35px;  
  font-size: 20px;
  font-weight: 600;
}
.bonjour_autopool_modalinfo button.primary_btn a {
  color: #fff;
  text-decoration: none;
}
.modal_title {
  position: relative;
  margin-bottom: 80px;
}
.modal_title img {
  position: absolute;
  width: 150px;
  left: 20px;
}
.modal_title h2 {
  font-size: 36px !important;
  font-weight: 700 !important;
}
.modal_title::before {
  content: "";
  background: url("./assets/images/heading_before.png") no-repeat;
  width: 82px;
  height: 49px;
  position: absolute;
  right: 120px;
  top: -50px;
  background-size: 70%;
}
.modal_title::after {
  content: "";
  background: url("./assets/images/heading_after.png") no-repeat;
  width: 112px;
  height: 68px;
  position: absolute;
  right: 80px;
  bottom: -70px;
  background-size: 70%;
}
.modal_footer::before {
  content: "";
  background: url("./assets/images/footer_before.png") no-repeat;
  width: 145px;
  height: 121px;
  position: absolute;
  left: 120px;
  bottom: -50px;
  background-size: 70%;
}
.modal_footer::after {
  content: "";
  background: url("./assets/images/footer_after.png") no-repeat;
  width: 145px;
  height: 121px;
  position: absolute;
  right: 80px;
  bottom: -50px;
  background-size: 70%;
}
.modal_footer h1 {
  font-family: inherit !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  color: #111 !important;
}
.modal_footer h2 {
  font-family: inherit !important;
  font-size: 40px !important;
  font-weight: 700 !important;
  color: #111 !important;
}
/* .bonjour_modal_full_chg .bonjour_presale_modalinfo{
  width: 100%;
} */
.bonjour_modal_full_chg h2{
    font-weight: 700 !important;
    color: #fff;
    margin-bottom: 30px;
    font-size: 33px !important;
}
.bonjour_modal_full_chg h1{
  font-size: 58px !important;
  color: #ffffff !important;
}
.bonjour_modal_full_chg .duplicate{
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: #ffffff00;
   margin-top: -63px !important ;
}
.infobox{
  width: 100%;
  margin: 80px auto 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}
.infobox::before,
.infobox::after {
  content: "";
  background: url("../src/assets/images/corner_rect.png") no-repeat left;
  width: 15px;
  height: 15px;
  position: absolute;
}
.infobox::before {    
  top: -30px;
}
.infobox::after { 
  transform: rotate(180deg);
  right: 0;
  bottom: 0; 
}
.infobox .box h6 {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}
.infobox .box h5 {
  font-size: 60px;  
  font-weight: 800;
  line-height: normal;
}
.infobox .box p { 
  color: #96A0FF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.box.box_purple {
  text-align: right;
  position: relative;
}
.box.box_purple::before {
  content: "";
  background: #8490FF;
  width: 1px;
  height: 110px;
  position: absolute;
  top: 0;
  left: -170px;
}

.platform_activity p{
  margin-bottom: 0;
  font-size: 18px;
}
.platform_activity p.violet{
  color: #FFF;  
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-left: 5px;
}
.platform_activity p.green span{
  color: #1e1e1e;
}
.platform_activity .activity_box{
  background: linear-gradient(176deg, #ebe4f0, #fbfafc 50%) padding-box, linear-gradient(356deg, #ffffff, #ffffff)border-box;
  border: 3px solid transparent;
  padding: 20px;
  border-radius: 20px;
  padding-top: 10px;
  padding-right: 10px;
}
.platform_activity .activity_box_flx{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 5px;
}
.platform_activity .activity_box_flx:hover{
  background: #f1ecf5;
}
.platform_activity .activity_box_flx:nth-last-child(1){
  margin-bottom: 20px;
}
.platform_activity .activity_scroll{
    padding-right: 12px;
}
.platform_activity .flxbox{
  display: flex;
  align-items: center;
}
.platform_activity .flxbox div{
  margin-right: 30px;
}
.platform_activity .flxbox .btn{
  background: #ffffff;
  color: #1e1e1e;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  margin-right: 0;
  border: 0;
  padding: 7px 25px;
}
.platform_activity .rightbtn p{
  display: flex;
  align-items: center;  
  justify-content: center;
  color: #FFF !important;   
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.platform_activity .rightbtn a {
  color: #6c757d;
}
.platform_activity .activity_box .seemorebutn{
  width: 100%;
  font-size: 18px;
  color: #fff;
  padding: 15px;
  background: #020459;
  border: 0;
  border-radius: 10px;
}
.platform_activity .activity_box .seemorebutn .arrowhvr{
  display: none;
  }
.platform_activity .activity_box .seemorebutn:hover .arrow{
  display: none;
}
.platform_activity .activity_box .seemorebutn:hover .arrowhvr{
  display: inline-block;
}


/* Globe Animation Starts */
:root {
  --sphereSize: 500px;
}
.globe {
  position: relative;
  width: var(--sphereSize);
  height: var(--sphereSize);
  margin: 0 auto;
}
.sphere,
.outer_shadow,
.inner_shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--sphereSize);
  height: var(--sphereSize);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100%;
}
.worldmap {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--sphereSize);
  height: var(--sphereSize);
  overflow: hidden;
  border-radius: 50%;
}
.worldmap_front {
  background-position: 0 0;
  animation: textureSpinreverse 18s linear infinite;
}
.worldmap_back {
  background-position: 0px -220px;
  animation: textureSpin 18s linear infinite;
}

@keyframes textureSpin {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-47.5%);
  }
}
@keyframes textureSpinreverse {
  from {
    transform: translateX(-47.5%);
  }
  to {
    transform: translateX(0);
  }
}
.worldmap_front,
.worldmap_back {
  position: absolute;
  left: 0;
  top: 0;
  width: 2439px;
  height: 1000px;
  background-image: url("./assets/images/world_map.png");
  background-repeat: no-repeat;
}
/* .outer_shadow {
  top: calc(var(--sphereSize) - 1rem);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjMwIiB2aWV3Qm94PSIwIDAgMjAwIDMwIj4NCjxkZWZzPg0KICA8cmFkaWFsR3JhZGllbnQgaWQ9InJnIiBjeD0iMjM1Mi4wNzUyIiBjeT0iLTY1MzQuODA1NyIgcj0iNDIuMTI2MiIgZ3JhZGllbnRUcmFuc2Zvcm09Im1hdHJpeCgyLjM3MzUgMCAwIDAuMzU2MSAtNTQ4Mi43NTI5IDIzNDIuMTU2NSkiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4NCgkJPHN0b3Agb2Zmc2V0PSIwIiAgICAgIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPg0KCQk8c3RvcCBvZmZzZXQ9IjAuMDg1NSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjc4Ii8+DQoJCTxzdG9wIG9mZnNldD0iMC4xNzAxIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuNTkiLz4NCgkJPHN0b3Agb2Zmc2V0PSIwLjI1ODEiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC40MyIvPg0KCQk8c3RvcCBvZmZzZXQ9IjAuMzUwMyIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjI5Ii8+DQoJCTxzdG9wIG9mZnNldD0iMC40NDc5IiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMTgiLz4NCgkJPHN0b3Agb2Zmc2V0PSIwLjU1MjYiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMCIvPg0KCQk8c3RvcCBvZmZzZXQ9IjAuNjY4IiAgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjA0Ii8+DQoJCTxzdG9wIG9mZnNldD0iMC44MDIxIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuMDEiLz4NCgkJPHN0b3Agb2Zmc2V0PSIxIiAgICAgIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPg0KICA8L3JhZGlhbEdyYWRpZW50Pg0KPC9kZWZzPg0KPGVsbGlwc2UgZmlsbD0idXJsKCNyZykiIG9wYWNpdHk9IjAuODUiIGN4PSIxMDAiIGN5PSIxNSIgcng9IjEwMCIgcnk9IjE1Ii8+DQo8L3N2Zz4=");
} */
.inner_shadow {
  background-image: url("./assets/images/inner_shadow.svg");
}

/* Globe Animation Ends */

.recent_activity_slider .recent_activity_panel {
  transition: all 300ms ease;
  position: relative;
  text-align: center;
  padding: 30px;  
  border-radius: 40px;
  background: linear-gradient(360deg, rgb(154 213 255 / 20%) 0%, rgba(133, 111, 255, 0.00) 15.63%, rgba(0, 0, 0, 0.00) 39.06%, #050505 63.02%);
  box-shadow: 0px -29px 23px -24px rgba(255, 255, 255, 0.25) inset;
  width: 400px;
  margin: 30px auto 0;
}
.slider_img {  
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 20px auto 15px;
}
.recent_activity_slider .slick-initialized .slick-slide.slick-active.slick-center.slick-current {
  z-index: 10;
}
.recent_activity_slider .slick-initialized .slick-slide {
  z-index: 1;
  position: relative; 
}
.activity_type {
  display: flex;
  align-items: center;
  justify-content: center;
}
.activity_type p {
  color: #FFF;  
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}
.activity_id {  
  font-size: 40px;
  font-weight: 700;
  line-height: 28px;
  margin: 10px 0;
}
.platform_activity {
  position: relative;
  z-index: 1;
  padding-top: 300px;
}
.platform_activity {
  content: "";
  background: url("./assets/images/recent_activity_bg.png") no-repeat center;
  background-size: 100%;
  width: 100%;
  height: 1200px;
  z-index: -1;
}
.platform_activity .main_title_small span {
  transform: rotate(90deg);
  display: block;
  margin-top: 50px;
}
.platform_activity .main_title_small { 
  position: absolute;
  left: 0;
  right: 0;
  top: -250px;
}
.recent_activity_slider .slick-center .recent_activity_panel .activity_type p,
.recent_activity_slider .slick-center .recent_activity_panel .activity_id {
  color: #fff;
}
.recent_activity_slider .slick-center .recent_activity_panel .rightbtn a,
.recent_activity_slider .slick-center .recent_activity_panel .rightbtn p {
  color: #fff;
}
.recent_activity_slider .slick-list {
  padding-bottom: 80px !important;
}
.recent_activity_slider .slick-prev:before,
.recent_activity_slider .slick-next:before {
  display: none;
}
.recent_activity_slider .slick-prev {
  left: unset;
  right: 400px;
  top: 110px;
  background: url("./assets/images/slick_prev.png") no-repeat !important;
}
.recent_activity_slider .slick-next {
  right: 400px;
  top: unset;
  bottom: 80px;
  background: url("./assets/images/slick_nxt.png") no-repeat !important;
}
.recent_activity_slider .slick-next, 
.recent_activity_slider .slick-prev {
  width: 38px;
  height: 40px;
  transition: .2ms;
  z-index: 5;
}
.banner_mobile {
  display: none;
}
.bonjour_popup_whole .modal-body{
  padding: 0;
}
.bonjour_popup_whole h1{
  font-family: "Inter", sans-serif  !important ;
  color: #fff !important ;
}
.bonjour_popup_whole .modal-content {
  background: #fff0;
  margin-left: 20px;
  border: 0;
}
.bonjour_popup_whole .modal{
  padding: 0;
}
.bonjour_popup_whole .btn-close {
  background: url("./assets/images/btnimg.png") no-repeat center;
  background-size: contain;
  right: 29px;
  width: 56px;
  height: 56px;
  opacity: 1;
}
.bonjour_popup_whole .respons{
  display: none;
}
.nfc_card_img {
  width: 100%;
  display: block;
  padding-top: 300px;
  transform-style: preserve-3d;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: url("./assets/images/nfc_card.png") no-repeat center;
  background-size: contain;
}
.nfc_card_wrap {
  position: relative;
  display: block;
  perspective: 1500px;
}
.trigger {
  position: absolute;
  height: 33.333333%;
  width: 33.333333%;
  display: block;
  z-index: 2;
}
.trigger:nth-child(1) {
  left: 0%;
  top: 0%;
}
.trigger:nth-child(1):hover ~ .nfc_card_img {
  transform: rotateY(8deg) rotateX(-5deg);
}
.trigger:nth-child(2) {
  left: 33.333333%;
  top: 0%;
}
.trigger:nth-child(2):hover ~ .nfc_card_img {
  transform: rotateY(0deg) rotateX(-5deg);
}
.trigger:nth-child(3) {
  left: 66.666666%;
  top: 0%;
}
.trigger:nth-child(3):hover ~ .nfc_card_img {
  transform: rotateY(-8deg) rotateX(-5deg);
}
.trigger:nth-child(4) {
  left: 0%;
  top: 33.333333%;
}
.trigger:nth-child(4):hover ~ .nfc_card_img {
  transform: rotateY(8deg);
}
.trigger:nth-child(5) {
  left: 33.333333%;
  top: 33.333333%;
}
.trigger:nth-child(5):hover ~ .nfc_card_img {
  transform: rotateY(0deg) rotateX(0deg);
}
.trigger:nth-child(6) {
  left: 66.666666%;
  top: 33.333333%;
}
.trigger:nth-child(6):hover ~ .nfc_card_img {
  transform: rotateY(-8deg) rotateX(0deg);
}
.trigger:nth-child(7) {
  left: 0%;
  top: 66.666666%;
}
.trigger:nth-child(7):hover ~ .nfc_card_img {
  transform: rotateY(8deg) rotateX(5deg);
}
.trigger:nth-child(8) {
  left: 33.333333%;
  top: 66.666666%;
}
.trigger:nth-child(8):hover ~ .nfc_card_img {
  transform: rotateY(0deg) rotateX(5deg);
}
.trigger:nth-child(9) {
  left: 66.666666%;
  top: 66.666666%;
}
.trigger:nth-child(9):hover ~ .nfc_card_img {
  transform: rotateY(-8deg) rotateX(5deg);
}
.nfc_qr {
  margin: 0 auto;
}
.nfc_admin_address {
  display: block;
  margin-top: 20px;
}
.nfc_admin_address .inp_grp {
  width: 50%;
  margin: 0 auto;
}
.nfc_admin_address label {
  margin-right: 0 !important;
}
.nfc_admin_address input.form-control.primary_inp {
  width: 100%;
}
.nfc_form {
  margin-top: 30px;
}
.nfc_form h3 {
  background: linear-gradient(95deg, rgba(62,91,169,1) 0%, rgba(235,12,132,1) 20%, rgba(248,158,70,1) 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 700;
}
.nfc_card_buy_box.primary_box.dash_box h2 {
  font-size: 30px;
} 
.nfc_form .form-group {
  margin-bottom: 15px;
}
.nfc_card_price {
  background: linear-gradient(-90deg, rgba(32,167,223,1) 15%, rgba(18,150,72,1) 35%, rgba(214,224,43,1) 75%, rgba(237,46,46,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
}
.nfc_form .primary_btn {  
  margin-top: 15px !important;
  padding: 8px 30px;
}
.nfc_card_buy_box .custom_width {
  max-width: 70%;
  margin: 0 auto 15px;
}
.dash_sidebox_level img {
  width: 100px;
  margin-right: 15px;
} 
.dash_sidebox_level h4 {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(220deg, rgba(249,150,36,1) 0%, rgba(248,66,0,1) 100%);
  -webkit-background-clip: text;
} 
.level_table_section .plan_income_table_div {
  width: 80%;
}
.level_table_section .plan_income_table_div tbody td img {
  width: 36px;
}
.level_box_wrap {
  grid-gap: 30px;
  /* display: grid;
  grid-template-columns: repeat(5,minmax(0,1fr)); */
  position: relative;

  display: flex;
  flex-wrap: wrap;
  justify-content:center;
}
.level_box_div {
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  color: #212529;
  background: #fff;
  background-clip: padding-box;  
  border: solid 5px transparent;
  border-radius: 1em;
  text-align: center;
  box-shadow: 0 0 6px 1px rgb(254 105 105 / 25%);
  width: 18%;
}
.level_box_div::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -5px;
  border-radius: inherit;  
  background: rgb(62,71,114);
  background: linear-gradient(0deg, rgba(62,71,114,1) 0%, rgba(212,82,69,1) 15%, rgba(253,205,97,1) 30%, rgba(221,16,75,1) 45%, rgba(215,151,27,1) 60%, rgba(243,110,46,1) 75%, rgba(37,102,36,1) 90%, rgba(32,154,200,1) 100%);
}
.level_box_div h1 {
  background: linear-gradient(90deg, rgba(62,91,169,1) 0%, rgba(235,12,132,1) 50%, rgba(248,158,70,1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  min-height: 60px;
}
.level_box_div p {
  color: #212529;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}
.level_box_div h2 {  
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
}
.level_box_div h3 {  
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;  
}
.level_box_div .slick-slider{
  position: relative;
}
.level_box_div .slick-list h3{
  font-size: 20px;
  line-height: 30px;
}
.level_box_div .slick-prev:before,
.level_box_div .slick-next:before {
  display: none;
}
.level_box_div .slick-prev {
  left: -15px;
  background: url("./assets/images/slick_prev.png") no-repeat !important;
}

.level_box_div .slick-next {
  right: -15px;
  background: url("./assets/images/slick_nxt.png") no-repeat !important;
}

.level_box_div .slick-next, 
.level_box_div .slick-prev {
  width: 24px;
  height: 24px;
  transition: .2ms;
  z-index: 5;
  top: 50%px;
}

.level_box_div h2::before,
.level_box_div h2::after {
  content: "";
  width: 45px;
  height: 1px;
  position: absolute;
  top: 16px;
}
.level_box_div h2::before {
  left: 0;  
}
.level_box_div h2::after { 
  right: 0;  
}
.level_box_one_one h2::before,
 .level_box_one_one h2::after{
  background: linear-gradient(270deg, #63c392 0%, #63c392 100%); 
 }
.level_box_one h2::before,
.level_box_one h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #979797 0%, #474545 100%); 
  display: none;
}
.level_box_two h2::before,
.level_box_two h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #ab7751 0%, #7e4d2a 100%); 
}
.level_box_three h2::before,
.level_box_three h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #c8edff 0%, #4d6a86 100%); 
}
.level_box_four h2::before,
.level_box_four h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #fac05e 0%, #866936 100%); 
}
.level_box_five h2::before,
.level_box_five h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #7b8df7 0%, #36409a 100%); 
}
.level_box_six h2::before,
.level_box_six h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #e1e1e1 0%, #9a9191 100%); 
}
.level_box_seven h2::before,
.level_box_seven h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #4ba5cf 0%, #98cdfe 100%); 
}
.level_box_eight h2::before,
.level_box_eight h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #979797 0%, #3b3b3b 100%); 
}
.level_box_nine h2::before,
.level_box_nine h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #f6ac81 0%, #cf692d 100%); 
}
.level_box_ten h2::before,
.level_box_ten h2::after {
  background: rgb(151,151,151);
  background: linear-gradient(270deg, #fc736b 0%, #b63635 100%); 
}

/* New Dashboard Css */
.dashboard_panel_new.dashboard_panel h2.inner_title {
  color: #191919;
  font-size: 26px;
  -webkit-text-fill-color: unset;
} 
.dashboard_panel_new.dashboard_panel .wallet_div input.primary_inp {
  border: none;
  color: #f88515 !important;
  padding: 0 !important;
  width: 270px !important;
  padding-right: 45px !important;
}
.dashboard_panel_new.dashboard_panel .wallet_div label {
  margin-right: 10px !important;
}
.dashboard_panel_new.dashboard_panel button.copy_btn {  
  right: 0;
  top: -4px;
}
.primary_box_new {
  border: 1px solid #fcaec7;
  box-shadow: 0px 5px 5px 1px rgb(241 7 81 / 5%);
  flex-direction: revert;
  justify-content: space-evenly;
  padding: 50px 30px;
}
.primary_box_new_div {
  position: relative;
  display: flex;
  align-items: center;
  width: 18%;
  border: 1px solid #fcaec7;
  border-radius: 10px;
  padding: 15px 30px 15px 56px;
}
.primary_box_new_div h2 {
  color: #333333;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 0;
}
.primary_box_new_div h3 {
  color: #495057;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}
.primary_box_new_div img {
  position: absolute;
  left: -42px;
}
.total_income_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -36px;
  z-index: 10;
  position: relative;
}
.total_income_div h2 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0;
  background: linear-gradient(90deg, rgba(62,91,169,1) 0%, rgba(235,12,132,1) 0%, rgba(248,158,70,1) 100%) !important;
  padding: 22px 50px;
  border-radius: 20px;
}
.total_income_div h2 span {
  font-size: 26px;
  font-weight: 700;
}
.primary_box.primary_box_new.primary_box_new_mt {
  padding-top: 80px;
}
.dashboard_panel.dashboard_panel_new input.primary_inp {
  border: 1px solid #f88515;
  padding: 15px;
}
.dashboard_panel.dashboard_panel_new .search_div button {
  padding: 15px 20px;
  border-radius: 10px;
}
.tree_structure {
  max-width: 50%;
  margin: 30px auto 50px;
}
.tree_first_level {
  position: relative;
  margin: 5px auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tree_first_level::after {
  content: "";
  background-color: #9ea2a5;
  width: 2px;
  height: 55px;
  position: absolute;
  bottom: -30px;
}
.tree_second_level {
  display: flex;
  justify-content: space-around;
  position: relative;
}
/* .tree_second_level::after {
  content: "";
  background-color: #9ea2a5;
  width: 50%;
  height: 2px;
  position: absolute;
} */
.tree_second_level > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  margin: 50px 5px 0;
}
.tree_second_level > div::before {
  content: "";
  background-color: #9ea2a5;
  width: 2px;
  height: 61px;
  position: absolute;
  top: -50px;
}
.rebirth_table_div {
  border-radius: 30px;
  border: 1px solid #fcaec7;
  box-shadow: 1px 1px 9px 5px rgb(241 7 81 / 5%); 
  margin: 15px auto 0;
  padding: 10px 30px 0;
}
.rebirth_table {
  margin-bottom: 0;
}
.rebirth_table thead th {
  font-size: 16px;
  color: #f88515;
  font-weight: 400;
  white-space: nowrap;
  border-bottom: 0;
  vertical-align: middle;
  padding: 15px 5px;
}
.rebirth_table tbody td {
  font-size: 16px;
  color: #495057;
  font-weight: 400;
  white-space: nowrap;
  border-bottom: 0;
  border-top: 1px solid #fcaec7;
  vertical-align: middle;
  padding: 15px 5px;
}
.dash_new_address {
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
}



/*.above_info_box {
  padding: 0px 0;
  background: linear-gradient(45deg, #e93c6c, #ec3874);
  border-bottom: 1px solid #f0572c;
  z-index: 100;
  position: relative;
}*/

/* .above_info_box  ::-webkit-scrollbar {
  width: 6px;
  height: 2px;
} */
/* .above_info_box  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.above_info_box  ::-webkit-scrollbar-thumb {
  background-color: #ff0000;
} */


.above_info_box .above_box_flx {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  align-items: center;
}

/* .above_info_box .container div > div{
  margin-right: 0 !important;
} */
.above_info_box .above_box_flx div {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 4px;
}

.above_info_box .above_box_flx div p {
  font-size: 14px;
  color: #fff;
  margin-right: 20px;
  margin-bottom: 0;
  white-space: nowrap;
}

.above_info_box .above_box_flx div span {
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.above_info_box.above_fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

.announcement-dark .modal-content{
  background: rgb(23,6,5);
  background: linear-gradient(0deg, rgba(23,6,5,1) 0%, rgba(28,18,28,1) 50%, rgba(38,41,70,1) 100%); 
  border-radius: 15px;
  min-height: 550px;
  padding-top: 30px;
}
.announcement-dark .modal-content::before{
  content: "";
  background: url(../src/assets/images/left-sparkles.png) no-repeat scroll 0 0;
  width: 209px;
  height: 191px;
  position: absolute;
  left: 0;
  top: 0;
}
.rightImagePOpup{
  position: relative;
}
.rightImagePOpup img{
  position: absolute;
  top: -30px;
  right: 0px;
}
.leftPopupContent h2{
  font-size: 46px;
  color: #ffffff !important;
  text-align: center;
  position: relative;
}
.leftPopupContent h2::after{
  content: "";
  background: url(../src/assets/images/speaker.png) no-repeat scroll 0 0;
  position: absolute;
  right: -35px;
  top: 0;
  width: 127px;
  height: 136px;
}
.leftPopupContent h2 span{
  color: #fffa74;
  font-size: 68px;
  display: block;
  font-family: "Oleo Script", cursive;
}
.leftPopupContent h4{
  font-size: 24px;
  color: #819cff;
  text-align: left;
  padding-left: 35px;
  margin-bottom: 25px;
}
.leftPopupContent ul{
  margin: 0;
  padding: 0 0 0 35px;
  text-align: left;
  list-style: none;
}
.leftPopupContent ul li{
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 18px;
  background: url(../src/assets/images/ba-bullet.png) no-repeat scroll 0 6px;
  padding-left: 32px;
}
.signature-text{
  text-align: left;
  padding-left: 35px;
  margin-bottom: 30px;
}
.signature-text *{
  display: block;
}
.signature-text label{
  font-size: 18px;
  color: #ffffff;
}
.signature-text span{
  font-size: 18px;
  color: #fffa74;
}
.signature-text a{
  font-size: 18px;
  color: #819cff;
}
.bonjour_popup_whole.announcement-dark .btn-close{
  position: absolute;
  top: -45px;
  right: -15px;
  z-index: 9;
}
.popLogo{
  margin-top: -150px;
}
.termspage .agree{
 font-size: 22px;
}
.termspage h5{
  font-size: 20px;
  color: #000;
}
.termspage ul{
  margin-bottom: 25px;
}
.termspage .inner_title{
  font-size: 35px;
}

.onedollarplan{
  color: #fff;
}
.onedollarplan h5 {
 font-size: 26px;
 font-weight: 700;
}
.onedollarplan .h_flx{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.onedollarplan .h_flx h5{
  color: #28229a;
}
.onedollarplan .h_flx div{
  color: #1341aa;
  font-size: 14px;
}
.onedollarplan .h_flx .copy{
  box-shadow: 0 0 53px 0 #7285d2;
  box-shadow: 0 0 23px 1px #c8d1fc;
  border-radius: 50%;
  margin-left: 8px;
  cursor: pointer;
}
.onedollarplan .h_flx a{
  text-decoration: none !important ;
  color: #1341aa;
}
.onedollarplan .h_flx div{
  display: flex;
  align-items: center;
} 
.onedollarplan .left_boxes {
  margin-left: 30px;
}
.onedollarplan .left_boxes .box{
  background: url("../src/assets/images/onedollarplan/left_boxbg.png") no-repeat center;
  padding: 21px 10px;
  padding-left: 70px;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 18px;
  background-size: cover;
  box-shadow: 0 0 40px 0 #c3ceff;
  position: relative;
} 
.onedollarplan .box p{
  margin-bottom: 0;
}
.onedollarplan .box .img_r {
 background :url("../src/assets/images/onedollarplan/img_shade.png") no-repeat center ;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 64px;
 height: 64px;
 background-size: contain;
 position: absolute;
 left: -32px;
 top: 0;
 bottom: 0;
 margin: auto 0;
 box-shadow: 0 0 90px 0 #2124aa;
 box-shadow: -20px -2px 40px 0 #aebdff;
}

.onedollarplan .claim_box{
  background: url("../src/assets/images/onedollarplan/claim_boxbg.png") no-repeat center;
  padding: 21px 10px;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  position: relative;
  margin: 28px 0 50px;
  box-shadow: -1px 0px 40px 0 #aebdff;
}
.onedollarplan .claim_box .box{
  width: 50%;
}
.onedollarplan .claim_box .box:nth-child(1){
  position: relative;
}
.onedollarplan .claim_box .box:nth-child(1)::before{
  content: "";
  position: absolute;
  background: #8299ff;
  height: 89%;
  width: 1px;
  right: 0;
  top: -17px;
  bottom: 0;
  margin: auto 0;
}
.onedollarplan .claim_box .box div{
  width: fit-content;
  margin:0 auto;
}
.onedollarplan .claim_box .primary_btn{
  position: absolute;
  bottom: -25px;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: fit-content;
  padding: 0px 20px;
} 
.onedollarplan .blw_box .box{
  padding: 25px 10px;
  text-align: center;
  margin-bottom: 25px;
  border-radius: 18px;
  border: 2px solid #8798ff;
  position: relative;
  background: transparent;
  color: #28229a;
  margin-left: 30px;
}
.onedollarplan .blw_box .box .img_r{
  box-shadow: -1px 0px 40px 0 #aebdff;
}
.wholebg{
  background: url("../src/assets/images/onedollarplan/bgimg.png") no-repeat center;
  background-size: cover;
  margin-top: -9px;
  padding-top: 9px;
}
.wholebg .main_navbar {
  background-color: transparent !important;
}
.wholebg .inner_wrapper{
  background-color: transparent !important ;
}
.toggle input{
  box-shadow: none !important; 
  border-color: #c4c4c4 !important;    
  width: 40px !important;
  height: 20px;
  margin-right: 10px; 
}
.toggle input:checked{
  box-shadow: none !important;
  background-color: #2a8be3;
  border-color: #2a8be3 !important;
  /* background-image: url("./assets/images/Ellipse.png") !important;  */
}
.toggle input:focus{
  border-color: #c4c4c4;
  /* background-image: url("./assets/images/Ellipse.png") !important;  */
}
.toggle .form-check-input{
  background-color: #2b2788;
  border-color: #0f0b66 !important;
} 
.box_cosmos {
  background: linear-gradient(360deg, rgba(154, 213, 255, 0.2) 0%, rgba(133, 111, 255, 0.00) 15.63%, rgba(0, 0, 0, 0.00) 39.06%, #00145b 63.02%);
  box-shadow: 0px -29px 23px -24px rgba(255, 255, 255, 0.25) inset;
  border-radius: 30px;
  padding: 15px;
  width: 256px;
  margin: 20px auto 30px;
  border: 1px solid #070707;
}
.box_cosmos h2 {
  font-size: 48px;
  line-height: 48px;
  font-weight: 900;
  margin: 0;
}
.autopoolban .autopool_box p.cosmos_note {
  font-size: 20px;
  color: #d34414;
  margin: 0;
}
.autopoolban .autopool_box p.cosmos_offer {
  font-size: 26px;
  font-weight: 800;
  color: #45c8fe;
  margin: 20px 0;
}
.autopoolban .autopool_box a {
  font-size: 18px;
  color: #fff;
}

/* Media Queries */
@media screen and (max-width: 1399px) {
  .level_box_wrap {    
    grid-template-columns: repeat(4,minmax(0,1fr));    
  }  
  .level_box_div:nth-child(9) {
    grid-column-end: 3;
  }
  .level_box_div:last-child:nth-child(3n - 2) {
    grid-column-end: 4;
  }
  .level_box_div{
    width: 28%;
  }
  .dashbox_middle_top h4 {
    font-size: 28px;
  }
}
@media screen and (max-width: 1366px) {
  .features_box h4 {
    font-size: 18px;
  }
}
@media screen and (max-width: 1199px) {
  .wholebg .main_navbar{
    background-color: #fff !important ;
  }
  .dashbox_middle_top h2 {
    font-size: 32px;
  }
  .plan_wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .dash_icon {
    width: 90px;
    height: 90px;
  }
  .navbar_right {
    display: block;
    margin-top: 30px;
  }
  .navbar_right .primary_btn {
    display: block;
    margin-bottom: 25px;    
  }
  .main_navbar {
    background: #000 !important; 
    position: fixed;
    width: 100%;
    box-shadow: 0 0 10px 5px rgb(4 4 4 / 10%);
  }
  .page_header {
    padding-top: 180px;
  }
  .inner_wrapper {
    padding-top: 150px;
  }

  .autopool .details .box .round {
    padding: 20px;
    width: 70px;
    height: 70px;
    margin-right: -27px;
  }
  .infobox{
    width: 100%;
  }
  .level_box_wrap {    
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
  .level_box_div:nth-child(9) {
    grid-column-end: unset;
  }  
  .level_box_div:last-child:nth-child(3n - 2) {
    grid-column-end: 3;
  }
}
@media screen and (max-width: 991px) {
  .wallet_div {
    display: block;
  }
  .inner_title {
    margin-bottom: 15px;
  }
  .wallet_div .inp_grp {
    justify-content: center;
  }
  .wallet_div label {
    margin: 0 !important;
  }
  .footer_panel {
    display: block;
    text-align: center;
  }
  .footer_social_links {
    justify-content: center;
    margin-top: 15px;
  }
  .page_header h1 {
    font-size: 42px;
    line-height: 46px;
    text-align: center;
    margin-bottom: 20px;
  }
  .page_header h5 {
    max-width: 80%;
    text-align: center;
    margin: 0 auto 20px;
  }
  .d_m_none {
    display: none !important;
  }
  .d_d_none {
    display: block !important;
  }
  .page_header {
    height: 100%;
  }
  .buy_count h2 {
    font-size: 28px !important;
    line-height: 32px;
  }
  .buy_count h3 {
    font-size: 20px;
  }
  .mar_top_section {
    margin-top: 0;
    padding-top: 80px;
  }
  .features_section_row {
    justify-content: center;
  }
  .counter_panel {
    max-width: 100%;
  }
  .main_title_small {
    max-width: 100%;
  }
  .plan_income_table {
    margin-bottom: 50px;
  }
  section.features_section {
    padding-bottom: 50px;
  }
  .dashbox_middle_top > div::after {
    display: none;
  }
  .title_flex {
    display: block;
  }
  .wallet_div input.primary_inp {
    width: 100%;
    text-overflow: ellipsis;
  }
  .ulcls {
    max-width: 100%;
  }
  .autopoolban {
    background: none;
    padding-bottom: 70px;
  }
  .autopoolban .autopool_box .input-group {
    max-width: 90%;
  }
  .autopool .details .box p {
    font-size: 16px;
  }
  .autopool .details .box h4 {
    font-size: 26px;
  }
  .autopool .details .box .round {
    padding: 15px;
    width: 60px;
    height: 60px;
    margin-right: -30px;
  }

  .autopool .details .box {
    justify-content: center;
  }
  .autopool .details .box .shape {
    height: 130px;
    width: 280px;
  }
  .bonjour_modal_full_chg h1 {
    font-size: 50px !important;
  }
  .bonjour_modal_full_chg h2 {
    font-size: 26px !important;
  }
  .bonjour_modal_full_chg .popup_para {
    font-size: 18px;
  }
  .bonjour_modal_full_chg .popup_para.blw {
    font-size: 18px;
  }
  .bonjour_modal_full_chg .box img {
    height: 80px;
  }
  .bonjour_popup_whole .modal-dialog {
    max-width: 100%;
}
  .bonjour_popup_whole img{
    width: 100% !important ;
    height: auto;
  }
  .bonjour_popup_whole .btn-close{
    top: 0px;
    z-index: 100;
    right: 0;
  }
  .nfc_admin_address .inp_grp {
    width: 100%;
  }
  .nfc_card_buy_box .custom_width {
    max-width: 100%;  
  }
  .nfc_card_panel h2.inner_title.text-start,
  .nfc_card_panel p.text-start {
    text-align: center !important;
  }
  .level_box_wrap {    
    grid-template-columns: repeat(2,minmax(0,1fr));
    grid-gap: 20px;
  }
  .level_box_div:last-child:nth-child(3n - 2) {
    grid-column-end: unset;
  }
  .level_box_div {
    width: 48%;
  }
  .onedollarplan .claim_box{
    flex-direction: column;
  }
  .onedollarplan .claim_box .box div{
    text-align: center;
  }
  .onedollarplan .claim_box .box{
    width: 100%;
    margin-bottom: 20px;
  }
  .onedollarplan .claim_box .box:nth-child(1)::before{
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .bonjour_popup_whole .btn-close{
    top: 40px;
  }
  .bonjour_popup_whole .modal-dialog {
    width: 100%  !important ;
  }
  .bonjour_popup_whole .respons{
    display: block;
    background: url("./assets/images/popupimg_mob.png") no-repeat;
    background-size: 100%;
  }
  .bonjour_popup_whole .respons .font{
    font-size: 17px;
  }
  .bonjour_popup_whole img{
    display: none;
  }
  .bonjour_popup_whole .respons{
  display: block;
  background-color: #fff;
  padding: 20px;
  padding-bottom: 100px;
  border-radius: 20px;
  position: relative;
}
.bonjour_popup_whole .respons::before{
  content: "";
  background: url("./assets/images/popupimg_mob_blw.png") no-repeat;
  background-size: 100%;
  height: 96px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-position: 0 bottom;
}
.bonjour_popup_whole .respons h1{ 
  background: #904db2;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 24px !important;
  display: inline-block;
}
  .dashbox_middle_top {
    display: block;
  }
  .dashbox_middle_top > div {
    margin-bottom: 30px;
  }
  .dashbox_middle_top > div:last-child {
    margin-bottom: 0;
  }
  .dashbox_middle_top h2 {
    margin-bottom: 0;
  }
  .search_div {
    width: 100%;
  }
  button.logout_btn {
    margin-bottom: 15px;
  }
  .page_header h1 {
    font-size: 36px;
  }
  .page_header h5 {
    max-width: 100%;
    font-size: 20px;
    line-height: 28px;
  }
  .main_title {
    font-size: 32px !important;
  }
  .faq_section .text-right {
    text-align: left !important;
  }
  .plan_wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .navbar_right {
    margin: 20px auto 10px;
    justify-content: center;
  }
  .inner_title {
    font-size: 36px;
  }
  .plan_income_table {
    width: 100%;
  }
  .global_pool_list {
    max-width: 100%;
  }
  .autopool_modal .modal-body {
    text-align: center;
    padding: 50px 15px;
  }
  .autopool_modal .modal-body h1 {
    font-size: 36px;
  }
  .autopool_modal .modal-body h2 {
    font-size: 28px;
  }
  .bonjour_modal_full_chg .popup_para {
    font-size: 18px;
  }
  .bonjour_modal_full_chg h2 {
    font-size: 20px !important;
  }
  .bonjour_modal_full_chg .popup_para.blw {
    font-size: 18px;
}
  .infobox .box{
   margin-bottom: 15px;
  }
  .out_header{
    height: unset !important ;
  }
  
  .platform_activity .activity_box{
    padding: 17px;
  }
  .platform_activity p{
    font-size: 17px;
  }
  .platform_activity .flxbox{
    flex-wrap: wrap;
  }
  .platform_activity .flxbox div {
    margin: 5px;
    margin-right: 15px;
    margin-left: 0;
}
.platform_activity .activity_box_flx{
  flex-direction: column;
  align-items: baseline;
}
.platform_activity .rightbtn p{
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-top: 17px;
}
.level_box_wrap {    
  grid-template-columns: repeat(1,minmax(0,1fr));
}

}
.align{
    align-items: flex-start;
  }

@media screen and (max-width: 575px) {
  .popLogo{
    margin-top: 0;
  }
  .signature-text{
    padding-left: 15px;
  }
  .leftPopupContent ul{
    padding-left: 15px;
  }
  .leftPopupContent h4{
    padding-left: 15px;
  }
  .announcement-dark .modal-content{
    max-width: 90%;
  }
  .leftPopupContent h2::after{
    display: none;
  }
  .leftPopupContent h2 span{
    font-size: 48px;
  }
  .level_box_div {
    width: 100%;
  }
  .bonjour_modal_full_chg .popup_para.heads {
    font-size: 26px;
  }
    .bonjour_modal_full_chg .buttn {
    padding: 10px 20px !important;
}
  .bonjour_popup_whole{
    display: block;
  }
 
  .dashbox_middle_bottom h2 {
    font-size: 18px;
  }
  .inner_para {
    font-size: 18px;
  }
  .main_title_small {
    font-size: 18px;
  }
  .main_title {
    font-size: 28px;
  }
  .main_navbar .container-fluid {
    display: block;
    text-align: center;
  }
  .primary_btn {
    padding: 5px 25px;
    font-size: 16px;
  }
  .plan_wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .dash_icon {
    width: 70px;
    height: 70px;
    margin: 0 auto 15px;
  }
  .dashbox_middle_top > div {
    display: block;
    text-align: center;
  }
  .dashbox_middle_top h2 {
    text-align: center;
    font-size: 28px;
  }
  .dash_icon img {
    width: 32px;
  }
  .counter_panel > div {
    width: 100%;
    margin-bottom: 30px;
  }
  .counter_panel {
    flex-wrap: wrap;
  }
  .income_section h4 {
    font-size: 28px;
  }
  .how_works_section .primary_btn {
    font-size: 20px;
    padding: 0px 40px;
    border-radius: 20px;
  }
  #faq_accodian .accordion-header button {
    font-size: 18px;
  }
  .autopoolban .autopool_box {
    max-width: 100%;
  }
  .autopoolban .autopool_box p {
    max-width: 100%;
  }
  .bonjour_modal_full_chg h1 {
    font-size: 34px !important;
  }
  .bonjour_modal_full_chg .duplicate{
    margin-top: -40px !important;
  }
  .bonjour_modal_full_chg .box img {
    height: 70px;
}
.bonjour_modal_full_chg .box h5 {
  font-size: 22px;
}
.bonjour_modal_full_chg .popup_para {
  font-size: 16px;
}
.above_info_box .above_box_flx {
  flex-direction: column;
}
.above_info_box .above_box_flx div{
  margin-top: 2px;
  margin-bottom: 5px;
}
span.timer_time{
  padding: 5px 8px;
}
/*.main_navbar_home.fixed_navbar{
  top: 72px;
}*/
.autopool_modal_chgs .text-end{
  z-index: 1;
  top: -15px;
}
.onedollarplan .h_flx .flx{
  flex-direction: column;  
}
.page_header::before {  
  height: 100%;
  top: 40px;
  right: 0;
  background-size: cover;
}
}
/*body{
  background: #f1eef5;
}*/

.primary_btn:hover::before {
  box-shadow: inset 0 0 0 10em #f99624;
}
/* .navbar_right .primary_btn {
  color: #fff;
  border-color: #fff;
}
.navbar_right .primary_btn:hover::before {
  box-shadow: inset 0 0 0 10em #3b00b9;
} */
.main_navbar .navbar-brand:focus-visible {
  outline: 0px;
}
.main_navbar .navbar-toggler {
  border: 1px solid #fff;
}
.main_navbarinner .navbar-toggler {
  border: 1px solid #000;
}
.main_navbar .navbar-toggler-icon {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_navbar .navbar-toggler-icon i {
  color: #fff;
  font-size: 23px;
}
.main_navbarinner .navbar-toggler-icon i {
  color: #000;
}
.main_navbar .navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}
.out_header {
  background: #040143;
  width: 100vw;
  height: 100vh;
  /* overflow: hidden; */
  z-index: 1;
  position: relative;
}

/* .page_header {
  position: fixed;
  transform: translate3d(0, 0, 0);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 150px 0 130px;
  background: #000;
  z-index: 10;
} */

.page_header .head_title {
  max-width: 100%;
  text-align: left;
  margin: 0 auto;
  color: #fff;
}
.page_header .head_title h5 {  
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.main_wrapper {
  z-index: 1;
  position: relative;
}
.bg_img {
 background: #040143;  
  position: relative;
}

.income_section h5,
.income_section .inner_para {
  font-size: 16px;
  color: #96A0FF;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.income_section .global_pool_list {
  font-size: 18px;
}

.income_section a {
  color: #212529;
  text-decoration: underline;
}

.mar_top_section {
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 0;
}
.mar_top_section .inner_para {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  max-width: 72%;
  margin: 0 auto; 
}
.mar_top_section .inner_para::after {
  content: "";
  background: #171717;
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -91px;
}
.features_section {
  background: none !important;
}
.how_works_section .inner_para {
  font-size: 20px;
}
.how_works_section .inner_para .txt_bold {
  font-weight: 400;
}
.how_works_section .primary_btn {
  background: #3b00b9;
  color: #fff;
  border: 0;
  border-radius: 15px;
  box-shadow: 0px 5px 5px 1px rgb(0 0 0 / 8%);
}
.joining_fee_txt {
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.how_works_section .primary_btn:hover::before {
  box-shadow: inset 0 0 0 10em #3b00b9;
}
.how_works_section {
  background: url("./assets/images/partner_bg.png") no-repeat  bottom 54px center;;
  background-size: contain;
  height: 920px;
  padding-top: 61px;
}
.faq_section .main_title_small,
.faq_section .main_title,
.faq_section .inner_para {
  text-align: center;
}
.faq_section .main_title_small {
  width: 100%;
}
.faq_section .primary_btn {
  background: #3b00b9;
  color: #f1eef5;
  border: 0;
  margin-top: 35px;
}
.faq_section .primary_btn:hover::before {
  box-shadow: inset 0 0 0 10em #4006be;
}
.faq_section {
  position: relative;
  z-index: 1;
}
.faq_section::before {
  content: "";
  background: url("../src/assets/images/faq_left_shape.png") no-repeat left;
  width: 100%;
  height: 938px;
  position: absolute;
  left: 0;
  z-index: -1;
}
.faq_section::after {
  content: "";
  background: url("../src/assets/images/faq_rightshape.png") no-repeat right;
  width: 100%;
  height: 863px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.plan_income_table {
  border-radius: 30px;
  padding: 30px;
  width: 100%;
  margin: 0 auto;
}
.plan_income_table tbody tr,
.plan_income_table th {
  background: transparent !important;
}
.plan_income_table th {
  padding: 15px;
  white-space: nowrap;
  color: #FFF; 
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}
.plan_income_table tbody tr td {
  color: #ffffff;
  white-space: nowrap;
  padding-top: 20px
}
.table_scroll {
  height: 500px;
  overflow: scroll;
  display: block;
}
.inner_wrapper {
  background-color: #040143;
  position: relative;
  z-index: 1;
}
/* .inner_wrapper::before {
  content: "";
  position: absolute;
  background-image: url("./assets/images/dash_shade.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: -300px;
  z-index: -1;
} */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiPaper-root{
  background-color: #000 !important;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.dashboard_panel input.primary_inp {
  border: 1px solid #140f49;
  border-radius: 25px;
  background: #140f49;
  color: #96A0FF !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dash_sidebox,
.affiliate_box,
.primary_box.dash_box {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, #050505 97.80%);
border: 1px solid #44c1fa;
}
.grey_dash_box.primary_box.dash_box {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, #050505 97.80%);
  border: 1px solid #44c1fa;
}
.grey_dash_box_div h4,
.dash_sidebox_right h3 {
  color: #909090;
}
.primary_btn {
  border-radius: 15px;
  background: radial-gradient(232.58% 162.91% at 31% 158.89%, #FF5810 0%, #1B124E 50.34%, #201B89 100%) !important;
 border: 0px solid #ffffff !important;
  box-shadow: 0px -12px 15px 0px rgba(0, 0, 0, 0.50) inset !important;
  filter: drop-shadow(0px 4px 25px rgba(55, 55, 55, 0.30)) !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  padding: 10px 30px;
  transition: 0.2s all;
  background: radial-gradient(232.58% 162.91% at 31% 158.89%, #FF5810 0%, #1B124E 50.34%, #201B89 100%) padding-box, linear-gradient(16deg, #FF5810 20% ,#201B89 , rgb(217 217 217) 93%,#201B89 ) border-box !important;
  border: 1px solid transparent !important;
}
.primary_btn:hover {
  background: radial-gradient(232.58% 162.91% at 31% 158.89%, #717171 0%, #1B124E 50.34%, #201B89 100%);
  box-shadow: 0px -12px 15px 0px rgba(0, 0, 0, 0.50) inset, 0px 4px 25px 0px rgba(55, 55, 55, 0.30);  
box-shadow: 0px -12px 15px 0px rgba(0, 0, 0, 0.50) inset;
filter: drop-shadow(0px 4px 25px rgba(55, 55, 55, 0.30));
}
.dash_sidebox_left {
  background: linear-gradient(220deg, #d6e02b 0%, #ed2e2e 100%);
}
.st-btn {
  background-color: #bababa !important;
  border: 0px !important;
}
.st-btn:hover {
  background: #f95c0b !important;
  opacity: 1 !important;
}
.search_div input.primary_inp::placeholder,
.search_div input.primary_inp {
  color: #1e1e1e !important;
}
.wallet_div label {
  margin-right: 20px;
}

.main_navbarinner .navbar-toggler {
  filter: invert(1);
}
.fixed_navbar.main_navbarinner {
  background: #000 !important;
}
.main_navbarinner {
  background: #040143 !important;
}
.fixcanva {
  position: fixed;
  top: 0;
}
.bg_none::before {
  background-image: none;
}
.claimbox{
width: 50%;
background: #3d33bb;
margin: 0 auto;
padding: 40px 20px;
color: #fff;
border-radius: 10px;
}
.dashboard_bgbox_one {
  background: #090909;
  border: 1px solid #212121;
  border-radius: 20px;
  padding: 80px 30px;
  background: url("../src/assets/images/dash_bg_01.png") no-repeat right;
  background-size: 100%;
  margin-bottom: 30px;
}
.dashbox_middle_top.dashbox_middle_top_halfwidth {
  max-width: 60%;
}
.dashboard_bgbox_two {

  border-radius: 20px;
  padding: 20px 30px;
  background: #080251 url("../src/assets/images/dash_bg_02.png") no-repeat left;
  background-size: 100%;
}
.dashbox_middle_top.dashbox_middle_top_halfwidth_two {
  max-width: 70%;
  margin-left: auto;
  border-radius: 20px;
  background: #020034;
  padding: 30px 20px;
}
.primary_btn.claim_btn{
  border: 2px solid #e6007a;
  color: #fff;
  animation: glowing 1500ms infinite;
  display: inline-block;
  width: 200px;
  height: 60px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  box-sizing: border-box;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 30px;
  z-index: 1;
}
.primary_btn.claim_btn::before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}
/* .primary_btn.claim_btn:hover::before {
  box-shadow: inset 0 0 0 10em #e6007a;
} */


.dashboard_panel_green .dash_sidebox_left {
  background: linear-gradient(220deg, #d6e02b 0%, #62c02f 100%);
}
.dashboard_panel_green  .dash_sidebox,.dashboard_panel_green  .affiliate_box,.dashboard_panel_green  .primary_box.dash_box {
  border-radius: 20px;
  background: #080251;
}
.dashboard_panel_green .pink_bg {
  border-radius: 20px;
  background: #090909;
}
.dashboard_panel_green .inner_title{
  color: #FFF;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@keyframes animate {
  0% {
    background-position: 0%;
    box-shadow: 0 0 3px #e6007a;
  }
  50% {box-shadow: 0 0 40px #e6007a; }
  100% {
    background-position: 400%;
    box-shadow: 0 0 3px #e6007a;
  }
}
/* @keyframes glowing {
  0% { background-color: #e6007a; box-shadow: 0 0 3px #e6007a; }
  50% { background-color: #e6007a; box-shadow: 0 0 40px #e6007a; }
  100% { background-color: #e6007a; box-shadow: 0 0 3px #e6007a; }
} */
@keyframes glowing {
  0% { background-color: #e6007a; box-shadow: 0 0 3px #e6007a; }
  50% { background-color: #e6007a; box-shadow: 0 0 40px #e6007a; }
  100% { background-color: #e6007a; box-shadow: 0 0 3px #e6007a; }
}


.dashbox_middle_top h3 {
  color: #96A0FF;    
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}


.btn1 {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 60px;
  text-align: center;
  color: #fff;
  font-size: 24px;
  text-decoration: none;
  box-sizing: border-box;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background: linear-gradient(90deg, #a0a45b, #f441a5, #e6319a, #a6a66a);
  background-size: 400%;
  border-radius: 30px;
  z-index: 1;
  border: 1px solid #f441a5;
}
 
.btn1 {
  animation: animate 8s linear infinite;
  /* overflow: hidden; */
}
 
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
 
.btn1:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #a02b87, #03a9f4);
  background: linear-gradient(90deg, #3b00b9, #f441a5, #a02b87, #3b00b9);
  /* background: linear-gradient(90deg,  #f441a5, #a02b87); */
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  transition: .5s;
}
 
.btn1::before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}
.imgpopup .modal-content{
 background: transparent;
 border: 0;
}
.imgpopup .modal-body img{
  width: unset !important;
}
.imgpopup .modal-body .mobile{
  display: none;
}

@media screen and (max-width: 1399px) {
  .main_title {
    font-size: 60px;
  }
  .how_works_section .primary_btn {
    font-size: 26px;
  }
  .banner_img::before {
    right: 55px;
  }
  .banner_img::after {
    right: -33px;
  }
  .recent_activity_slider .slick-prev {
    right: 300px;
    top: 150px;
  }
  .recent_activity_slider .slick-next {
    right: 300px;
  }
}
@media screen and (max-width: 1199px) {
  .main_title_small {
    font-size: 26px;
  }
  .features_box h4 {
    font-size: 22px;
  }
  .how_works_section .primary_btn {
    font-size: 22px;
    line-height: unset;
    padding: 10px 20px;
  }
  :root {
    --sphereSize: 400px;
  }
  .banner_img::before {
    right: 50px;
    background-size: 80%;
    top: -170px;
  }
  .banner_img::after {
    right: 1px;
    background-size: 70%;
    bottom: -162px;
  }
  .recent_activity_slider .slick-next {
    right: 200px;
  }
  .recent_activity_slider .slick-prev {
    right: 200px;
  }
  .joining_fee_txt {
    font-size: 56px;
  }
  .primary_box_new {
    flex-wrap: wrap;
  }
  .primary_box_new_div {
    width: 35%;
    margin-bottom: 30px;
  }
  .modal_title img {    
    left: -40px;
    top: -70px;
  }
  .modal_title::before,
  .modal_title::after {
    display: none;
  }
  .modal_footer h2 {  
    font-size: 36px !important;
  }
  .modal_footer::before {
    left: -20px;
  }
  .modal_footer::after {
    right: -70px;
  }
  .modal_title {
    margin-bottom: 0;
  }
  .autopool_modal_chgs .text-end {
    right: 4%;
  }
  .onedollarplan .left_boxes .box{
    padding-left: 45px;
  }
  .dashbox_middle_top, 
  .grey_dash_box_div {
    flex-wrap: wrap;
  }
  .dashbox_middle_top > div,
  .grey_dash_box_div > div {
    margin-bottom: 15px;
    width: 50%;
  }
  .dashbox_middle_top.dashbox_middle_top_halfwidth > div {
    width: 33%;
  }
  .dashbox_middle_top.dashbox_middle_top_halfwidth {
    max-width: 85%;
  }
  .dashboard_bgbox_one {
    padding: 50px 30px;
    background-size: cover;
    background-position: center;
  }
  .dashbox_middle_top.dashbox_middle_top_halfwidth_two {
    max-width: 100%;
    background: rgb(0 0 0 / 80%);
  }
  .dashboard_bgbox_two {
    padding: 0;
    background-size: cover;
  }
}
@media screen and (max-width: 991px) {
  .align {
    align-items: flex-start;
    display: flex !important;
    flex-direction: column-reverse;
}
  .main_title {
    font-size: 48px;
  }
  .main_title_small {
    font-size: 20px;
  }
  .features_box p {
    font-size: 18px;
  }
  .bonjour_modal_full {
    display: block;
  }
  .bonjour_autopool_modalinfo,
  .bonjour_presale_modalinfo {
    width: 100%;
  }
  .bonjour_autopool_modalinfo::after {
    width: 100%;
    height: 1px;
    bottom: -10px;
    top: unset;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .modal_footer::before,
  .modal_footer::after {
    display: none;
  }
  .how_works_section .box {
    max-width: 100%;
  }
  .features_section::before {
    top: -300px;
  }
  .recent_activity_slider .slick-prev {
    right: 0;
  }
  .recent_activity_slider .slick-next {
    right: 0;
  }
  .plan_income_table_div {
    margin-bottom: 30px;
  }
  .banner_img {
    margin-top: -30px;
    margin-bottom: 100px;
  }
  .banner_img::before {
    right: 180px;
    background-size: 75%;
    top: -179px;
  }
  .banner_img::after {
    right: 122px;
  }
  .page_header {
    position: unset;
    padding: 150px 0 0;
  }
  .head_title{
    margin-top: 30px !important;
  }
  .out_header {
    height: 100%;
  }
  .tree_structure {
    max-width: 100%;
  }
  .dashboard_panel_new.dashboard_panel .wallet_div .inp_grp {
    width: 40%;
    margin: 0 auto;
  }
  .autopool_modal_chgs .modal-body{
    padding: 0;
  }
  .autopool_modal_chgs .text-end{
    right: -15px;
  }
  .box.box_purple::before {
    display: none;
  }
  .infobox {
    flex-wrap: wrap;
  }
  .infobox .box {
    text-align: center;
    width: 100%;
  }
  .infobox .box h5 {
    font-size: 42px;
  }
  .features_box {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #1D1D1D 99.01%);
  }
  .dashbox_middle_top.dashbox_middle_top_halfwidth {
    max-width: 100%;
  }
  .dashbox_middle_top.dashbox_middle_top_halfwidth > div {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .page_header .head_title {
    max-width: 100%;
  }
  .page_header .head_title h5,
  .mar_top_section .inner_para {
    max-width: 100%;
  }
  .plan_income_table {
    width: 100%;
  }
  .plan_income_table_div {
    width: 100%;
  }
  .pooladdress .asrt-pagination {
    margin: 7px;
    margin-left: 3px;
    justify-content: flex-start !important;
  }
  .autopool_modal .modal-dialog {
    width: 90%;
    margin: 15px auto;
  }
  .banner_img::before {
    right: 92px;
  }
  .banner_img::after {
    right: 31px;
  }
  .imgpopup .modal-body .mobile{
    display:block;
  }
  .imgpopup .modal-body .pc{
    display:none;
  }
  .dashboard_panel_new.dashboard_panel .wallet_div .inp_grp {
    width: 60%;
  }
  .primary_box_new_div {
    width: 85%;
  }
  .modal_title h2 {
    font-size: 28px !important;
  }
  .modal_footer h2 {
    font-size: 26px !important;
  }
  .modal_footer h1 {   
    font-size: 28px !important;
  }
  .onedollarplan .h_flx {
    flex-direction: column;
    align-items: center;
  }
  .how_works_section {
    height: 100%;
  }
  .recent_activity_slider .recent_activity_panel {    
    width: 300px;
  }
  .activity_id {
    font-size: 28px;
  }
  .plan_income_table_div {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #1D1D1D 99.01%);
  }
}
@media screen and (max-width: 575px) {
  .bg_img {
    background-size: 100% 105%;
    background-position: inherit;
  }
  .grey_dash_box_div div.btnflx {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .grey_dash_box_div div button + button {
    margin-left: 0;
  }
  .grey_dash_box_div div button {
    margin: 10px 0;
  }
  .banner_img::before {
    display: none;
  }
  .banner_img::after {
    display: none;
  }
  .globe { 
    display: none;
  }
  .banner_mobile {
    display: block;
  }
  .banner_img {
    margin-top: -150px;
    margin-bottom: 0;
  }
  .dashboard_panel_new.dashboard_panel .wallet_div .inp_grp {
    width: 100%;
  }
  .total_income_div h2 {
    text-align: center;
  }
  .total_income_div h2 span {
    display: block;
  }
  button.primary_btn.autopool_btn {
    width: 70%
  }
  /*.navbar_right .primary_btn{
    width: 100%;
  }
  .navbar_right .primary_btn.address_btn {
    width: 100% !important;
    white-space: normal;
    word-break: break-all;
    overflow: unset;
    text-overflow: unset;
  }
  .primary_btn.address_btn::before {
    display: none;
  }
  .navbar_right .dropdown.me-3 {
    margin-right: 0 !important;
  }*/
  .autopool_modal_chgs .modal-body img {
    height: 100%;
  }
  .recent_activity_slider .recent_activity_panel {    
    width: 230px;
    padding: 15px;
  }
  .autopoolban .autopool_box h1 {
    font-size: 42px;
  }
  .box_cosmos h2 {
    font-size: 36px;
    line-height: 36px;
  }
  .autopoollive_modal .modal-content {
    background: #070707 url("../src/assets/images/cosmos_modal_bg.png") no-repeat center top -120px;
  }
  .autopoolban .autopool_box p.cosmos_offer {
    font-size: 24px;
  }
  .autopoolban .autopool_box p {
    font-size: 20px;
  }
  .autopoolban .autopool_box p.cosmos_note {
    font-size: 18px;
  }
}
@media screen and (max-width: 350px) {
  .banner_img::before {
    right: 38px;
    background-size: 48%;
    top: -218px;
  }
  .banner_img::after {
    right: 3px;
    background-size: 42%;
    bottom: -174px;
  }
}

/* home animation round dots */
.red {
  color: hsl(201deg 61% 73%);
  color: hsl(203deg 66% 47%);
  color: hsl(156deg 72% 35%);
  color: hsl(63deg 74% 63%);
  color: hsl(8deg 83% 68%);
  color: hsl(327deg 82% 61%);
  color: hsl(347deg 84% 63%);
  color: hsl(233deg 38% 47%);
  color: hsl(337deg 69% 55%);
  color: hsl(35deg 95% 68%);
}

.wrap_mobile {
  display: none;
}
.wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform-style: preserve-3d;
  perspective: 1000px;
  animation: rotate 14s infinite linear;
  z-index: -10;
}
@media screen and (max-width: 767px) {
  .wrap {
    display: none;
  }
  .wrap_mobile {
    display: block;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: -10;
    background: url("./assets/images/s_anime.gif") no-repeat center;
    width: 100%;
    height: 100%;
    z-index: -10px;
  }
  .dashbox_middle_top > div, .grey_dash_box_div > div {
    width: 100%;
    margin-bottom: 30px;
  }
}
@keyframes rotate {
  100% {
    transform: rotateY(360deg) rotateX(360deg);
  }
}
.c {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  opacity: 0;
}

.c:nth-child(1) {
  animation: orbit1 14s infinite;
  animation-delay: 0.01s;
  background-color: #00fcff;
}

@keyframes orbit1 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-341deg) rotateY(52deg) translateX(200px) rotateZ(341deg);
  }
  80% {
    transform: rotateZ(-341deg) rotateY(52deg) translateX(200px) rotateZ(341deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-341deg) rotateY(52deg) translateX(600px) rotateZ(341deg);
  }
}
.c:nth-child(2) {
  animation: orbit2 14s infinite;
  animation-delay: 0.02s;
  background-color: #00f9ff;
}

@keyframes orbit2 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-63deg) rotateY(316deg) translateX(200px) rotateZ(63deg);
  }
  80% {
    transform: rotateZ(-63deg) rotateY(316deg) translateX(200px) rotateZ(63deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-63deg) rotateY(316deg) translateX(600px) rotateZ(63deg);
  }
}
.c:nth-child(3) {
  animation: orbit3 14s infinite;
  animation-delay: 0.03s;
  background-color: #00f7ff;
}

@keyframes orbit3 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-342deg) rotateY(278deg) translateX(200px)
      rotateZ(342deg);
  }
  80% {
    transform: rotateZ(-342deg) rotateY(278deg) translateX(200px)
      rotateZ(342deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-342deg) rotateY(278deg) translateX(600px)
      rotateZ(342deg);
  }
}
.c:nth-child(4) {
  animation: orbit4 14s infinite;
  animation-delay: 0.04s;
  background-color: #00f4ff;
}

@keyframes orbit4 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-205deg) rotateY(302deg) translateX(200px)
      rotateZ(205deg);
  }
  80% {
    transform: rotateZ(-205deg) rotateY(302deg) translateX(200px)
      rotateZ(205deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-205deg) rotateY(302deg) translateX(600px)
      rotateZ(205deg);
  }
}
.c:nth-child(5) {
  animation: orbit5 14s infinite;
  animation-delay: 0.05s;
  background-color: #00f1ff;
}

@keyframes orbit5 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-25deg) rotateY(241deg) translateX(200px) rotateZ(25deg);
  }
  80% {
    transform: rotateZ(-25deg) rotateY(241deg) translateX(200px) rotateZ(25deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-25deg) rotateY(241deg) translateX(600px) rotateZ(25deg);
  }
}
.c:nth-child(6) {
  animation: orbit6 14s infinite;
  animation-delay: 0.06s;
  background-color: #00eeff;
}

@keyframes orbit6 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-334deg) rotateY(220deg) translateX(200px)
      rotateZ(334deg);
  }
  80% {
    transform: rotateZ(-334deg) rotateY(220deg) translateX(200px)
      rotateZ(334deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-334deg) rotateY(220deg) translateX(600px)
      rotateZ(334deg);
  }
}
.c:nth-child(7) {
  animation: orbit7 14s infinite;
  animation-delay: 0.07s;
  background-color: #00ebff;
}

@keyframes orbit7 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-32deg) rotateY(39deg) translateX(200px) rotateZ(32deg);
  }
  80% {
    transform: rotateZ(-32deg) rotateY(39deg) translateX(200px) rotateZ(32deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-32deg) rotateY(39deg) translateX(600px) rotateZ(32deg);
  }
}
.c:nth-child(8) {
  animation: orbit8 14s infinite;
  animation-delay: 0.08s;
  background-color: #00e8ff;
}

@keyframes orbit8 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-135deg) rotateY(20deg) translateX(200px) rotateZ(135deg);
  }
  80% {
    transform: rotateZ(-135deg) rotateY(20deg) translateX(200px) rotateZ(135deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-135deg) rotateY(20deg) translateX(600px) rotateZ(135deg);
  }
}
.c:nth-child(9) {
  animation: orbit9 14s infinite;
  animation-delay: 0.09s;
  background-color: #00e6ff;
}

@keyframes orbit9 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-353deg) rotateY(131deg) translateX(200px)
      rotateZ(353deg);
  }
  80% {
    transform: rotateZ(-353deg) rotateY(131deg) translateX(200px)
      rotateZ(353deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-353deg) rotateY(131deg) translateX(600px)
      rotateZ(353deg);
  }
}
.c:nth-child(10) {
  animation: orbit10 14s infinite;
  animation-delay: 0.1s;
  background-color: #00e3ff;
}

@keyframes orbit10 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-272deg) rotateY(122deg) translateX(200px)
      rotateZ(272deg);
  }
  80% {
    transform: rotateZ(-272deg) rotateY(122deg) translateX(200px)
      rotateZ(272deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-272deg) rotateY(122deg) translateX(600px)
      rotateZ(272deg);
  }
}
.c:nth-child(11) {
  animation: orbit11 14s infinite;
  animation-delay: 0.11s;
  background-color: #00e0ff;
}

@keyframes orbit11 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-182deg) rotateY(19deg) translateX(200px) rotateZ(182deg);
  }
  80% {
    transform: rotateZ(-182deg) rotateY(19deg) translateX(200px) rotateZ(182deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-182deg) rotateY(19deg) translateX(600px) rotateZ(182deg);
  }
}
.c:nth-child(12) {
  animation: orbit12 14s infinite;
  animation-delay: 0.12s;
  background-color: #00ddff;
}

@keyframes orbit12 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-247deg) rotateY(57deg) translateX(200px) rotateZ(247deg);
  }
  80% {
    transform: rotateZ(-247deg) rotateY(57deg) translateX(200px) rotateZ(247deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-247deg) rotateY(57deg) translateX(600px) rotateZ(247deg);
  }
}
.c:nth-child(13) {
  animation: orbit13 14s infinite;
  animation-delay: 0.13s;
  background-color: #00daff;
}

@keyframes orbit13 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-54deg) rotateY(296deg) translateX(200px) rotateZ(54deg);
  }
  80% {
    transform: rotateZ(-54deg) rotateY(296deg) translateX(200px) rotateZ(54deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-54deg) rotateY(296deg) translateX(600px) rotateZ(54deg);
  }
}
.c:nth-child(14) {
  animation: orbit14 14s infinite;
  animation-delay: 0.14s;
  background-color: #00d7ff;
}

@keyframes orbit14 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-255deg) rotateY(167deg) translateX(200px)
      rotateZ(255deg);
  }
  80% {
    transform: rotateZ(-255deg) rotateY(167deg) translateX(200px)
      rotateZ(255deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-255deg) rotateY(167deg) translateX(600px)
      rotateZ(255deg);
  }
}
.c:nth-child(15) {
  animation: orbit15 14s infinite;
  animation-delay: 0.15s;
  background-color: #00d5ff;
}

@keyframes orbit15 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-327deg) rotateY(228deg) translateX(200px)
      rotateZ(327deg);
  }
  80% {
    transform: rotateZ(-327deg) rotateY(228deg) translateX(200px)
      rotateZ(327deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-327deg) rotateY(228deg) translateX(600px)
      rotateZ(327deg);
  }
}
.c:nth-child(16) {
  animation: orbit16 14s infinite;
  animation-delay: 0.16s;
  background-color: #00d2ff;
}

@keyframes orbit16 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-204deg) rotateY(49deg) translateX(200px) rotateZ(204deg);
  }
  80% {
    transform: rotateZ(-204deg) rotateY(49deg) translateX(200px) rotateZ(204deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-204deg) rotateY(49deg) translateX(600px) rotateZ(204deg);
  }
}
.c:nth-child(17) {
  animation: orbit17 14s infinite;
  animation-delay: 0.17s;
  background-color: #00cfff;
}

@keyframes orbit17 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-278deg) rotateY(199deg) translateX(200px)
      rotateZ(278deg);
  }
  80% {
    transform: rotateZ(-278deg) rotateY(199deg) translateX(200px)
      rotateZ(278deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-278deg) rotateY(199deg) translateX(600px)
      rotateZ(278deg);
  }
}
.c:nth-child(18) {
  animation: orbit18 14s infinite;
  animation-delay: 0.18s;
  background-color: #00ccff;
}

@keyframes orbit18 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-196deg) rotateY(275deg) translateX(200px)
      rotateZ(196deg);
  }
  80% {
    transform: rotateZ(-196deg) rotateY(275deg) translateX(200px)
      rotateZ(196deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-196deg) rotateY(275deg) translateX(600px)
      rotateZ(196deg);
  }
}
.c:nth-child(19) {
  animation: orbit19 14s infinite;
  animation-delay: 0.19s;
  background-color: #00c9ff;
}

@keyframes orbit19 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-95deg) rotateY(160deg) translateX(200px) rotateZ(95deg);
  }
  80% {
    transform: rotateZ(-95deg) rotateY(160deg) translateX(200px) rotateZ(95deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-95deg) rotateY(160deg) translateX(600px) rotateZ(95deg);
  }
}
.c:nth-child(20) {
  animation: orbit20 14s infinite;
  animation-delay: 0.2s;
  background-color: #00c6ff;
}

@keyframes orbit20 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-164deg) rotateY(6deg) translateX(200px) rotateZ(164deg);
  }
  80% {
    transform: rotateZ(-164deg) rotateY(6deg) translateX(200px) rotateZ(164deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-164deg) rotateY(6deg) translateX(600px) rotateZ(164deg);
  }
}
.c:nth-child(21) {
  animation: orbit21 14s infinite;
  animation-delay: 0.21s;
  background-color: #00c4ff;
}

@keyframes orbit21 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-189deg) rotateY(216deg) translateX(200px)
      rotateZ(189deg);
  }
  80% {
    transform: rotateZ(-189deg) rotateY(216deg) translateX(200px)
      rotateZ(189deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-189deg) rotateY(216deg) translateX(600px)
      rotateZ(189deg);
  }
}
.c:nth-child(22) {
  animation: orbit22 14s infinite;
  animation-delay: 0.22s;
  background-color: #00c1ff;
}

@keyframes orbit22 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-213deg) rotateY(324deg) translateX(200px)
      rotateZ(213deg);
  }
  80% {
    transform: rotateZ(-213deg) rotateY(324deg) translateX(200px)
      rotateZ(213deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-213deg) rotateY(324deg) translateX(600px)
      rotateZ(213deg);
  }
}
.c:nth-child(23) {
  animation: orbit23 14s infinite;
  animation-delay: 0.23s;
  background-color: #00beff;
}

@keyframes orbit23 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-74deg) rotateY(73deg) translateX(200px) rotateZ(74deg);
  }
  80% {
    transform: rotateZ(-74deg) rotateY(73deg) translateX(200px) rotateZ(74deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-74deg) rotateY(73deg) translateX(600px) rotateZ(74deg);
  }
}
.c:nth-child(24) {
  animation: orbit24 14s infinite;
  animation-delay: 0.24s;
  background-color: #00bbff;
}

@keyframes orbit24 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-177deg) rotateY(49deg) translateX(200px) rotateZ(177deg);
  }
  80% {
    transform: rotateZ(-177deg) rotateY(49deg) translateX(200px) rotateZ(177deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-177deg) rotateY(49deg) translateX(600px) rotateZ(177deg);
  }
}
.c:nth-child(25) {
  animation: orbit25 14s infinite;
  animation-delay: 0.25s;
  background-color: #00b8ff;
}

@keyframes orbit25 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-244deg) rotateY(128deg) translateX(200px)
      rotateZ(244deg);
  }
  80% {
    transform: rotateZ(-244deg) rotateY(128deg) translateX(200px)
      rotateZ(244deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-244deg) rotateY(128deg) translateX(600px)
      rotateZ(244deg);
  }
}
.c:nth-child(26) {
  animation: orbit26 14s infinite;
  animation-delay: 0.26s;
  background-color: #00b5ff;
}

@keyframes orbit26 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-80deg) rotateY(138deg) translateX(200px) rotateZ(80deg);
  }
  80% {
    transform: rotateZ(-80deg) rotateY(138deg) translateX(200px) rotateZ(80deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-80deg) rotateY(138deg) translateX(600px) rotateZ(80deg);
  }
}
.c:nth-child(27) {
  animation: orbit27 14s infinite;
  animation-delay: 0.27s;
  background-color: #00b3ff;
}

@keyframes orbit27 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-195deg) rotateY(53deg) translateX(200px) rotateZ(195deg);
  }
  80% {
    transform: rotateZ(-195deg) rotateY(53deg) translateX(200px) rotateZ(195deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-195deg) rotateY(53deg) translateX(600px) rotateZ(195deg);
  }
}
.c:nth-child(28) {
  animation: orbit28 14s infinite;
  animation-delay: 0.28s;
  background-color: #00b0ff;
}

@keyframes orbit28 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-74deg) rotateY(13deg) translateX(200px) rotateZ(74deg);
  }
  80% {
    transform: rotateZ(-74deg) rotateY(13deg) translateX(200px) rotateZ(74deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-74deg) rotateY(13deg) translateX(600px) rotateZ(74deg);
  }
}
.c:nth-child(29) {
  animation: orbit29 14s infinite;
  animation-delay: 0.29s;
  background-color: #00adff;
}

@keyframes orbit29 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-301deg) rotateY(170deg) translateX(200px)
      rotateZ(301deg);
  }
  80% {
    transform: rotateZ(-301deg) rotateY(170deg) translateX(200px)
      rotateZ(301deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-301deg) rotateY(170deg) translateX(600px)
      rotateZ(301deg);
  }
}
.c:nth-child(30) {
  animation: orbit30 14s infinite;
  animation-delay: 0.3s;
  background-color: #00aaff;
}

@keyframes orbit30 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-355deg) rotateY(143deg) translateX(200px)
      rotateZ(355deg);
  }
  80% {
    transform: rotateZ(-355deg) rotateY(143deg) translateX(200px)
      rotateZ(355deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-355deg) rotateY(143deg) translateX(600px)
      rotateZ(355deg);
  }
}
.c:nth-child(31) {
  animation: orbit31 14s infinite;
  animation-delay: 0.31s;
  background-color: #00a7ff;
}

@keyframes orbit31 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-344deg) rotateY(48deg) translateX(200px) rotateZ(344deg);
  }
  80% {
    transform: rotateZ(-344deg) rotateY(48deg) translateX(200px) rotateZ(344deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-344deg) rotateY(48deg) translateX(600px) rotateZ(344deg);
  }
}
.c:nth-child(32) {
  animation: orbit32 14s infinite;
  animation-delay: 0.32s;
  background-color: #00a4ff;
}

@keyframes orbit32 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-158deg) rotateY(29deg) translateX(200px) rotateZ(158deg);
  }
  80% {
    transform: rotateZ(-158deg) rotateY(29deg) translateX(200px) rotateZ(158deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-158deg) rotateY(29deg) translateX(600px) rotateZ(158deg);
  }
}
.c:nth-child(33) {
  animation: orbit33 14s infinite;
  animation-delay: 0.33s;
  background-color: #00a2ff;
}

@keyframes orbit33 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-130deg) rotateY(116deg) translateX(200px)
      rotateZ(130deg);
  }
  80% {
    transform: rotateZ(-130deg) rotateY(116deg) translateX(200px)
      rotateZ(130deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-130deg) rotateY(116deg) translateX(600px)
      rotateZ(130deg);
  }
}
.c:nth-child(34) {
  animation: orbit34 14s infinite;
  animation-delay: 0.34s;
  background-color: #009fff;
}

@keyframes orbit34 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-259deg) rotateY(69deg) translateX(200px) rotateZ(259deg);
  }
  80% {
    transform: rotateZ(-259deg) rotateY(69deg) translateX(200px) rotateZ(259deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-259deg) rotateY(69deg) translateX(600px) rotateZ(259deg);
  }
}
.c:nth-child(35) {
  animation: orbit35 14s infinite;
  animation-delay: 0.35s;
  background-color: #009cff;
}

@keyframes orbit35 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-85deg) rotateY(18deg) translateX(200px) rotateZ(85deg);
  }
  80% {
    transform: rotateZ(-85deg) rotateY(18deg) translateX(200px) rotateZ(85deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-85deg) rotateY(18deg) translateX(600px) rotateZ(85deg);
  }
}
.c:nth-child(36) {
  animation: orbit36 14s infinite;
  animation-delay: 0.36s;
  background-color: #0099ff;
}

@keyframes orbit36 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-195deg) rotateY(79deg) translateX(200px) rotateZ(195deg);
  }
  80% {
    transform: rotateZ(-195deg) rotateY(79deg) translateX(200px) rotateZ(195deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-195deg) rotateY(79deg) translateX(600px) rotateZ(195deg);
  }
}
.c:nth-child(37) {
  animation: orbit37 14s infinite;
  animation-delay: 0.37s;
  background-color: #0096ff;
}

@keyframes orbit37 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-309deg) rotateY(349deg) translateX(200px)
      rotateZ(309deg);
  }
  80% {
    transform: rotateZ(-309deg) rotateY(349deg) translateX(200px)
      rotateZ(309deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-309deg) rotateY(349deg) translateX(600px)
      rotateZ(309deg);
  }
}
.c:nth-child(38) {
  animation: orbit38 14s infinite;
  animation-delay: 0.38s;
  background-color: #0093ff;
}

@keyframes orbit38 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-179deg) rotateY(90deg) translateX(200px) rotateZ(179deg);
  }
  80% {
    transform: rotateZ(-179deg) rotateY(90deg) translateX(200px) rotateZ(179deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-179deg) rotateY(90deg) translateX(600px) rotateZ(179deg);
  }
}
.c:nth-child(39) {
  animation: orbit39 14s infinite;
  animation-delay: 0.39s;
  background-color: #0091ff;
}

@keyframes orbit39 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-179deg) rotateY(220deg) translateX(200px)
      rotateZ(179deg);
  }
  80% {
    transform: rotateZ(-179deg) rotateY(220deg) translateX(200px)
      rotateZ(179deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-179deg) rotateY(220deg) translateX(600px)
      rotateZ(179deg);
  }
}
.c:nth-child(40) {
  animation: orbit40 14s infinite;
  animation-delay: 0.4s;
  background-color: #008eff;
}

@keyframes orbit40 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-110deg) rotateY(9deg) translateX(200px) rotateZ(110deg);
  }
  80% {
    transform: rotateZ(-110deg) rotateY(9deg) translateX(200px) rotateZ(110deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-110deg) rotateY(9deg) translateX(600px) rotateZ(110deg);
  }
}
.c:nth-child(41) {
  animation: orbit41 14s infinite;
  animation-delay: 0.41s;
  background-color: #008bff;
}

@keyframes orbit41 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-132deg) rotateY(281deg) translateX(200px)
      rotateZ(132deg);
  }
  80% {
    transform: rotateZ(-132deg) rotateY(281deg) translateX(200px)
      rotateZ(132deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-132deg) rotateY(281deg) translateX(600px)
      rotateZ(132deg);
  }
}
.c:nth-child(42) {
  animation: orbit42 14s infinite;
  animation-delay: 0.42s;
  background-color: #0088ff;
}

@keyframes orbit42 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-275deg) rotateY(176deg) translateX(200px)
      rotateZ(275deg);
  }
  80% {
    transform: rotateZ(-275deg) rotateY(176deg) translateX(200px)
      rotateZ(275deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-275deg) rotateY(176deg) translateX(600px)
      rotateZ(275deg);
  }
}
.c:nth-child(43) {
  animation: orbit43 14s infinite;
  animation-delay: 0.43s;
  background-color: #0085ff;
}

@keyframes orbit43 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-201deg) rotateY(212deg) translateX(200px)
      rotateZ(201deg);
  }
  80% {
    transform: rotateZ(-201deg) rotateY(212deg) translateX(200px)
      rotateZ(201deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-201deg) rotateY(212deg) translateX(600px)
      rotateZ(201deg);
  }
}
.c:nth-child(44) {
  animation: orbit44 14s infinite;
  animation-delay: 0.44s;
  background-color: #0082ff;
}

@keyframes orbit44 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-302deg) rotateY(34deg) translateX(200px) rotateZ(302deg);
  }
  80% {
    transform: rotateZ(-302deg) rotateY(34deg) translateX(200px) rotateZ(302deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-302deg) rotateY(34deg) translateX(600px) rotateZ(302deg);
  }
}
.c:nth-child(45) {
  animation: orbit45 14s infinite;
  animation-delay: 0.45s;
  background-color: #0080ff;
}

@keyframes orbit45 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-314deg) rotateY(32deg) translateX(200px) rotateZ(314deg);
  }
  80% {
    transform: rotateZ(-314deg) rotateY(32deg) translateX(200px) rotateZ(314deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-314deg) rotateY(32deg) translateX(600px) rotateZ(314deg);
  }
}
.c:nth-child(46) {
  animation: orbit46 14s infinite;
  animation-delay: 0.46s;
  background-color: #007dff;
}

@keyframes orbit46 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-206deg) rotateY(333deg) translateX(200px)
      rotateZ(206deg);
  }
  80% {
    transform: rotateZ(-206deg) rotateY(333deg) translateX(200px)
      rotateZ(206deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-206deg) rotateY(333deg) translateX(600px)
      rotateZ(206deg);
  }
}
.c:nth-child(47) {
  animation: orbit47 14s infinite;
  animation-delay: 0.47s;
  background-color: #007aff;
}

@keyframes orbit47 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-299deg) rotateY(291deg) translateX(200px)
      rotateZ(299deg);
  }
  80% {
    transform: rotateZ(-299deg) rotateY(291deg) translateX(200px)
      rotateZ(299deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-299deg) rotateY(291deg) translateX(600px)
      rotateZ(299deg);
  }
}
.c:nth-child(48) {
  animation: orbit48 14s infinite;
  animation-delay: 0.48s;
  background-color: #0077ff;
}

@keyframes orbit48 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-92deg) rotateY(82deg) translateX(200px) rotateZ(92deg);
  }
  80% {
    transform: rotateZ(-92deg) rotateY(82deg) translateX(200px) rotateZ(92deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-92deg) rotateY(82deg) translateX(600px) rotateZ(92deg);
  }
}
.c:nth-child(49) {
  animation: orbit49 14s infinite;
  animation-delay: 0.49s;
  background-color: #0074ff;
}

@keyframes orbit49 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-267deg) rotateY(110deg) translateX(200px)
      rotateZ(267deg);
  }
  80% {
    transform: rotateZ(-267deg) rotateY(110deg) translateX(200px)
      rotateZ(267deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-267deg) rotateY(110deg) translateX(600px)
      rotateZ(267deg);
  }
}
.c:nth-child(50) {
  animation: orbit50 14s infinite;
  animation-delay: 0.5s;
  background-color: #0071ff;
}

@keyframes orbit50 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-73deg) rotateY(262deg) translateX(200px) rotateZ(73deg);
  }
  80% {
    transform: rotateZ(-73deg) rotateY(262deg) translateX(200px) rotateZ(73deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-73deg) rotateY(262deg) translateX(600px) rotateZ(73deg);
  }
}
.c:nth-child(51) {
  animation: orbit51 14s infinite;
  animation-delay: 0.51s;
  background-color: #006fff;
}

@keyframes orbit51 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-221deg) rotateY(92deg) translateX(200px) rotateZ(221deg);
  }
  80% {
    transform: rotateZ(-221deg) rotateY(92deg) translateX(200px) rotateZ(221deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-221deg) rotateY(92deg) translateX(600px) rotateZ(221deg);
  }
}
.c:nth-child(52) {
  animation: orbit52 14s infinite;
  animation-delay: 0.52s;
  background-color: #006cff;
}

@keyframes orbit52 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-189deg) rotateY(220deg) translateX(200px)
      rotateZ(189deg);
  }
  80% {
    transform: rotateZ(-189deg) rotateY(220deg) translateX(200px)
      rotateZ(189deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-189deg) rotateY(220deg) translateX(600px)
      rotateZ(189deg);
  }
}
.c:nth-child(53) {
  animation: orbit53 14s infinite;
  animation-delay: 0.53s;
  background-color: #0069ff;
}

@keyframes orbit53 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-349deg) rotateY(192deg) translateX(200px)
      rotateZ(349deg);
  }
  80% {
    transform: rotateZ(-349deg) rotateY(192deg) translateX(200px)
      rotateZ(349deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-349deg) rotateY(192deg) translateX(600px)
      rotateZ(349deg);
  }
}
.c:nth-child(54) {
  animation: orbit54 14s infinite;
  animation-delay: 0.54s;
  background-color: #0066ff;
}

@keyframes orbit54 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-145deg) rotateY(63deg) translateX(200px) rotateZ(145deg);
  }
  80% {
    transform: rotateZ(-145deg) rotateY(63deg) translateX(200px) rotateZ(145deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-145deg) rotateY(63deg) translateX(600px) rotateZ(145deg);
  }
}
.c:nth-child(55) {
  animation: orbit55 14s infinite;
  animation-delay: 0.55s;
  background-color: #0063ff;
}

@keyframes orbit55 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-239deg) rotateY(266deg) translateX(200px)
      rotateZ(239deg);
  }
  80% {
    transform: rotateZ(-239deg) rotateY(266deg) translateX(200px)
      rotateZ(239deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-239deg) rotateY(266deg) translateX(600px)
      rotateZ(239deg);
  }
}
.c:nth-child(56) {
  animation: orbit56 14s infinite;
  animation-delay: 0.56s;
  background-color: #0060ff;
}

@keyframes orbit56 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-93deg) rotateY(188deg) translateX(200px) rotateZ(93deg);
  }
  80% {
    transform: rotateZ(-93deg) rotateY(188deg) translateX(200px) rotateZ(93deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-93deg) rotateY(188deg) translateX(600px) rotateZ(93deg);
  }
}
.c:nth-child(57) {
  animation: orbit57 14s infinite;
  animation-delay: 0.57s;
  background-color: #005eff;
}

@keyframes orbit57 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-27deg) rotateY(227deg) translateX(200px) rotateZ(27deg);
  }
  80% {
    transform: rotateZ(-27deg) rotateY(227deg) translateX(200px) rotateZ(27deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-27deg) rotateY(227deg) translateX(600px) rotateZ(27deg);
  }
}
.c:nth-child(58) {
  animation: orbit58 14s infinite;
  animation-delay: 0.58s;
  background-color: #005bff;
}

@keyframes orbit58 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-304deg) rotateY(246deg) translateX(200px)
      rotateZ(304deg);
  }
  80% {
    transform: rotateZ(-304deg) rotateY(246deg) translateX(200px)
      rotateZ(304deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-304deg) rotateY(246deg) translateX(600px)
      rotateZ(304deg);
  }
}
.c:nth-child(59) {
  animation: orbit59 14s infinite;
  animation-delay: 0.59s;
  background-color: #0058ff;
}

@keyframes orbit59 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-62deg) rotateY(45deg) translateX(200px) rotateZ(62deg);
  }
  80% {
    transform: rotateZ(-62deg) rotateY(45deg) translateX(200px) rotateZ(62deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-62deg) rotateY(45deg) translateX(600px) rotateZ(62deg);
  }
}
.c:nth-child(60) {
  animation: orbit60 14s infinite;
  animation-delay: 0.6s;
  background-color: #0055ff;
}

@keyframes orbit60 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-103deg) rotateY(57deg) translateX(200px) rotateZ(103deg);
  }
  80% {
    transform: rotateZ(-103deg) rotateY(57deg) translateX(200px) rotateZ(103deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-103deg) rotateY(57deg) translateX(600px) rotateZ(103deg);
  }
}
.c:nth-child(61) {
  animation: orbit61 14s infinite;
  animation-delay: 0.61s;
  background-color: #0052ff;
}

@keyframes orbit61 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-107deg) rotateY(86deg) translateX(200px) rotateZ(107deg);
  }
  80% {
    transform: rotateZ(-107deg) rotateY(86deg) translateX(200px) rotateZ(107deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-107deg) rotateY(86deg) translateX(600px) rotateZ(107deg);
  }
}
.c:nth-child(62) {
  animation: orbit62 14s infinite;
  animation-delay: 0.62s;
  background-color: #004fff;
}

@keyframes orbit62 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-222deg) rotateY(254deg) translateX(200px)
      rotateZ(222deg);
  }
  80% {
    transform: rotateZ(-222deg) rotateY(254deg) translateX(200px)
      rotateZ(222deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-222deg) rotateY(254deg) translateX(600px)
      rotateZ(222deg);
  }
}
.c:nth-child(63) {
  animation: orbit63 14s infinite;
  animation-delay: 0.63s;
  background-color: #004dff;
}

@keyframes orbit63 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-79deg) rotateY(112deg) translateX(200px) rotateZ(79deg);
  }
  80% {
    transform: rotateZ(-79deg) rotateY(112deg) translateX(200px) rotateZ(79deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-79deg) rotateY(112deg) translateX(600px) rotateZ(79deg);
  }
}
.c:nth-child(64) {
  animation: orbit64 14s infinite;
  animation-delay: 0.64s;
  background-color: #004aff;
}

@keyframes orbit64 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-60deg) rotateY(157deg) translateX(200px) rotateZ(60deg);
  }
  80% {
    transform: rotateZ(-60deg) rotateY(157deg) translateX(200px) rotateZ(60deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-60deg) rotateY(157deg) translateX(600px) rotateZ(60deg);
  }
}
.c:nth-child(65) {
  animation: orbit65 14s infinite;
  animation-delay: 0.65s;
  background-color: #0047ff;
}

@keyframes orbit65 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-36deg) rotateY(119deg) translateX(200px) rotateZ(36deg);
  }
  80% {
    transform: rotateZ(-36deg) rotateY(119deg) translateX(200px) rotateZ(36deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-36deg) rotateY(119deg) translateX(600px) rotateZ(36deg);
  }
}
.c:nth-child(66) {
  animation: orbit66 14s infinite;
  animation-delay: 0.66s;
  background-color: #0044ff;
}

@keyframes orbit66 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-156deg) rotateY(337deg) translateX(200px)
      rotateZ(156deg);
  }
  80% {
    transform: rotateZ(-156deg) rotateY(337deg) translateX(200px)
      rotateZ(156deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-156deg) rotateY(337deg) translateX(600px)
      rotateZ(156deg);
  }
}
.c:nth-child(67) {
  animation: orbit67 14s infinite;
  animation-delay: 0.67s;
  background-color: #0041ff;
}

@keyframes orbit67 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-336deg) rotateY(129deg) translateX(200px)
      rotateZ(336deg);
  }
  80% {
    transform: rotateZ(-336deg) rotateY(129deg) translateX(200px)
      rotateZ(336deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-336deg) rotateY(129deg) translateX(600px)
      rotateZ(336deg);
  }
}
.c:nth-child(68) {
  animation: orbit68 14s infinite;
  animation-delay: 0.68s;
  background-color: #003eff;
}

@keyframes orbit68 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-10deg) rotateY(341deg) translateX(200px) rotateZ(10deg);
  }
  80% {
    transform: rotateZ(-10deg) rotateY(341deg) translateX(200px) rotateZ(10deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-10deg) rotateY(341deg) translateX(600px) rotateZ(10deg);
  }
}
.c:nth-child(69) {
  animation: orbit69 14s infinite;
  animation-delay: 0.69s;
  background-color: #003cff;
}

@keyframes orbit69 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-43deg) rotateY(190deg) translateX(200px) rotateZ(43deg);
  }
  80% {
    transform: rotateZ(-43deg) rotateY(190deg) translateX(200px) rotateZ(43deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-43deg) rotateY(190deg) translateX(600px) rotateZ(43deg);
  }
}
.c:nth-child(70) {
  animation: orbit70 14s infinite;
  animation-delay: 0.7s;
  background-color: #0039ff;
}

@keyframes orbit70 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-102deg) rotateY(196deg) translateX(200px)
      rotateZ(102deg);
  }
  80% {
    transform: rotateZ(-102deg) rotateY(196deg) translateX(200px)
      rotateZ(102deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-102deg) rotateY(196deg) translateX(600px)
      rotateZ(102deg);
  }
}
.c:nth-child(71) {
  animation: orbit71 14s infinite;
  animation-delay: 0.71s;
  background-color: #0036ff;
}

@keyframes orbit71 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-249deg) rotateY(95deg) translateX(200px) rotateZ(249deg);
  }
  80% {
    transform: rotateZ(-249deg) rotateY(95deg) translateX(200px) rotateZ(249deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-249deg) rotateY(95deg) translateX(600px) rotateZ(249deg);
  }
}
.c:nth-child(72) {
  animation: orbit72 14s infinite;
  animation-delay: 0.72s;
  background-color: #0033ff;
}

@keyframes orbit72 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-252deg) rotateY(307deg) translateX(200px)
      rotateZ(252deg);
  }
  80% {
    transform: rotateZ(-252deg) rotateY(307deg) translateX(200px)
      rotateZ(252deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-252deg) rotateY(307deg) translateX(600px)
      rotateZ(252deg);
  }
}
.c:nth-child(73) {
  animation: orbit73 14s infinite;
  animation-delay: 0.73s;
  background-color: #0030ff;
}

@keyframes orbit73 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-12deg) rotateY(293deg) translateX(200px) rotateZ(12deg);
  }
  80% {
    transform: rotateZ(-12deg) rotateY(293deg) translateX(200px) rotateZ(12deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-12deg) rotateY(293deg) translateX(600px) rotateZ(12deg);
  }
}
.c:nth-child(74) {
  animation: orbit74 14s infinite;
  animation-delay: 0.74s;
  background-color: #002dff;
}

@keyframes orbit74 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-304deg) rotateY(49deg) translateX(200px) rotateZ(304deg);
  }
  80% {
    transform: rotateZ(-304deg) rotateY(49deg) translateX(200px) rotateZ(304deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-304deg) rotateY(49deg) translateX(600px) rotateZ(304deg);
  }
}
.c:nth-child(75) {
  animation: orbit75 14s infinite;
  animation-delay: 0.75s;
  background-color: #002bff;
}

@keyframes orbit75 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-285deg) rotateY(345deg) translateX(200px)
      rotateZ(285deg);
  }
  80% {
    transform: rotateZ(-285deg) rotateY(345deg) translateX(200px)
      rotateZ(285deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-285deg) rotateY(345deg) translateX(600px)
      rotateZ(285deg);
  }
}
.c:nth-child(76) {
  animation: orbit76 14s infinite;
  animation-delay: 0.76s;
  background-color: #0028ff;
}

@keyframes orbit76 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-151deg) rotateY(39deg) translateX(200px) rotateZ(151deg);
  }
  80% {
    transform: rotateZ(-151deg) rotateY(39deg) translateX(200px) rotateZ(151deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-151deg) rotateY(39deg) translateX(600px) rotateZ(151deg);
  }
}
.c:nth-child(77) {
  animation: orbit77 14s infinite;
  animation-delay: 0.77s;
  background-color: #0025ff;
}

@keyframes orbit77 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-205deg) rotateY(286deg) translateX(200px)
      rotateZ(205deg);
  }
  80% {
    transform: rotateZ(-205deg) rotateY(286deg) translateX(200px)
      rotateZ(205deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-205deg) rotateY(286deg) translateX(600px)
      rotateZ(205deg);
  }
}
.c:nth-child(78) {
  animation: orbit78 14s infinite;
  animation-delay: 0.78s;
  background-color: #0022ff;
}

@keyframes orbit78 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-314deg) rotateY(36deg) translateX(200px) rotateZ(314deg);
  }
  80% {
    transform: rotateZ(-314deg) rotateY(36deg) translateX(200px) rotateZ(314deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-314deg) rotateY(36deg) translateX(600px) rotateZ(314deg);
  }
}
.c:nth-child(79) {
  animation: orbit79 14s infinite;
  animation-delay: 0.79s;
  background-color: #001fff;
}

@keyframes orbit79 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-105deg) rotateY(285deg) translateX(200px)
      rotateZ(105deg);
  }
  80% {
    transform: rotateZ(-105deg) rotateY(285deg) translateX(200px)
      rotateZ(105deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-105deg) rotateY(285deg) translateX(600px)
      rotateZ(105deg);
  }
}
.c:nth-child(80) {
  animation: orbit80 14s infinite;
  animation-delay: 0.8s;
  background-color: #001cff;
}

@keyframes orbit80 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-327deg) rotateY(274deg) translateX(200px)
      rotateZ(327deg);
  }
  80% {
    transform: rotateZ(-327deg) rotateY(274deg) translateX(200px)
      rotateZ(327deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-327deg) rotateY(274deg) translateX(600px)
      rotateZ(327deg);
  }
}
.c:nth-child(81) {
  animation: orbit81 14s infinite;
  animation-delay: 0.81s;
  background-color: #001aff;
}

@keyframes orbit81 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-43deg) rotateY(196deg) translateX(200px) rotateZ(43deg);
  }
  80% {
    transform: rotateZ(-43deg) rotateY(196deg) translateX(200px) rotateZ(43deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-43deg) rotateY(196deg) translateX(600px) rotateZ(43deg);
  }
}
.c:nth-child(82) {
  animation: orbit82 14s infinite;
  animation-delay: 0.82s;
  background-color: #0017ff;
}

@keyframes orbit82 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-165deg) rotateY(213deg) translateX(200px)
      rotateZ(165deg);
  }
  80% {
    transform: rotateZ(-165deg) rotateY(213deg) translateX(200px)
      rotateZ(165deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-165deg) rotateY(213deg) translateX(600px)
      rotateZ(165deg);
  }
}
.c:nth-child(83) {
  animation: orbit83 14s infinite;
  animation-delay: 0.83s;
  background-color: #0014ff;
}

@keyframes orbit83 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-320deg) rotateY(263deg) translateX(200px)
      rotateZ(320deg);
  }
  80% {
    transform: rotateZ(-320deg) rotateY(263deg) translateX(200px)
      rotateZ(320deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-320deg) rotateY(263deg) translateX(600px)
      rotateZ(320deg);
  }
}
.c:nth-child(84) {
  animation: orbit84 14s infinite;
  animation-delay: 0.84s;
  background-color: #0011ff;
}

@keyframes orbit84 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-68deg) rotateY(175deg) translateX(200px) rotateZ(68deg);
  }
  80% {
    transform: rotateZ(-68deg) rotateY(175deg) translateX(200px) rotateZ(68deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-68deg) rotateY(175deg) translateX(600px) rotateZ(68deg);
  }
}
.c:nth-child(85) {
  animation: orbit85 14s infinite;
  animation-delay: 0.85s;
  background-color: #000eff;
}

@keyframes orbit85 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-59deg) rotateY(115deg) translateX(200px) rotateZ(59deg);
  }
  80% {
    transform: rotateZ(-59deg) rotateY(115deg) translateX(200px) rotateZ(59deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-59deg) rotateY(115deg) translateX(600px) rotateZ(59deg);
  }
}
.c:nth-child(86) {
  animation: orbit86 14s infinite;
  animation-delay: 0.86s;
  background-color: #000bff;
}

@keyframes orbit86 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-345deg) rotateY(337deg) translateX(200px)
      rotateZ(345deg);
  }
  80% {
    transform: rotateZ(-345deg) rotateY(337deg) translateX(200px)
      rotateZ(345deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-345deg) rotateY(337deg) translateX(600px)
      rotateZ(345deg);
  }
}
.c:nth-child(87) {
  animation: orbit87 14s infinite;
  animation-delay: 0.87s;
  background-color: #0009ff;
}

@keyframes orbit87 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-164deg) rotateY(281deg) translateX(200px)
      rotateZ(164deg);
  }
  80% {
    transform: rotateZ(-164deg) rotateY(281deg) translateX(200px)
      rotateZ(164deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-164deg) rotateY(281deg) translateX(600px)
      rotateZ(164deg);
  }
}
.c:nth-child(88) {
  animation: orbit88 14s infinite;
  animation-delay: 0.88s;
  background-color: #0006ff;
}

@keyframes orbit88 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-163deg) rotateY(8deg) translateX(200px) rotateZ(163deg);
  }
  80% {
    transform: rotateZ(-163deg) rotateY(8deg) translateX(200px) rotateZ(163deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-163deg) rotateY(8deg) translateX(600px) rotateZ(163deg);
  }
}
.c:nth-child(89) {
  animation: orbit89 14s infinite;
  animation-delay: 0.89s;
  background-color: #0003ff;
}

@keyframes orbit89 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-346deg) rotateY(261deg) translateX(200px)
      rotateZ(346deg);
  }
  80% {
    transform: rotateZ(-346deg) rotateY(261deg) translateX(200px)
      rotateZ(346deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-346deg) rotateY(261deg) translateX(600px)
      rotateZ(346deg);
  }
}
.c:nth-child(90) {
  animation: orbit90 14s infinite;
  animation-delay: 0.9s;
  background-color: blue;
}

@keyframes orbit90 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-258deg) rotateY(63deg) translateX(200px) rotateZ(258deg);
  }
  80% {
    transform: rotateZ(-258deg) rotateY(63deg) translateX(200px) rotateZ(258deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-258deg) rotateY(63deg) translateX(600px) rotateZ(258deg);
  }
}
.c:nth-child(91) {
  animation: orbit91 14s infinite;
  animation-delay: 0.91s;
  background-color: #0300ff;
}

@keyframes orbit91 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-245deg) rotateY(97deg) translateX(200px) rotateZ(245deg);
  }
  80% {
    transform: rotateZ(-245deg) rotateY(97deg) translateX(200px) rotateZ(245deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-245deg) rotateY(97deg) translateX(600px) rotateZ(245deg);
  }
}
.c:nth-child(92) {
  animation: orbit92 14s infinite;
  animation-delay: 0.92s;
  background-color: #0600ff;
}

@keyframes orbit92 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-1deg) rotateY(315deg) translateX(200px) rotateZ(1deg);
  }
  80% {
    transform: rotateZ(-1deg) rotateY(315deg) translateX(200px) rotateZ(1deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-1deg) rotateY(315deg) translateX(600px) rotateZ(1deg);
  }
}
.c:nth-child(93) {
  animation: orbit93 14s infinite;
  animation-delay: 0.93s;
  background-color: #0900ff;
}

@keyframes orbit93 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-149deg) rotateY(247deg) translateX(200px)
      rotateZ(149deg);
  }
  80% {
    transform: rotateZ(-149deg) rotateY(247deg) translateX(200px)
      rotateZ(149deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-149deg) rotateY(247deg) translateX(600px)
      rotateZ(149deg);
  }
}
.c:nth-child(94) {
  animation: orbit94 14s infinite;
  animation-delay: 0.94s;
  background-color: #0b00ff;
}

@keyframes orbit94 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-277deg) rotateY(30deg) translateX(200px) rotateZ(277deg);
  }
  80% {
    transform: rotateZ(-277deg) rotateY(30deg) translateX(200px) rotateZ(277deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-277deg) rotateY(30deg) translateX(600px) rotateZ(277deg);
  }
}
.c:nth-child(95) {
  animation: orbit95 14s infinite;
  animation-delay: 0.95s;
  background-color: #0e00ff;
}

@keyframes orbit95 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-277deg) rotateY(256deg) translateX(200px)
      rotateZ(277deg);
  }
  80% {
    transform: rotateZ(-277deg) rotateY(256deg) translateX(200px)
      rotateZ(277deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-277deg) rotateY(256deg) translateX(600px)
      rotateZ(277deg);
  }
}
.c:nth-child(96) {
  animation: orbit96 14s infinite;
  animation-delay: 0.96s;
  background-color: #1100ff;
}

@keyframes orbit96 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-262deg) rotateY(35deg) translateX(200px) rotateZ(262deg);
  }
  80% {
    transform: rotateZ(-262deg) rotateY(35deg) translateX(200px) rotateZ(262deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-262deg) rotateY(35deg) translateX(600px) rotateZ(262deg);
  }
}
.c:nth-child(97) {
  animation: orbit97 14s infinite;
  animation-delay: 0.97s;
  background-color: #1400ff;
}

@keyframes orbit97 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-213deg) rotateY(53deg) translateX(200px) rotateZ(213deg);
  }
  80% {
    transform: rotateZ(-213deg) rotateY(53deg) translateX(200px) rotateZ(213deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-213deg) rotateY(53deg) translateX(600px) rotateZ(213deg);
  }
}
.c:nth-child(98) {
  animation: orbit98 14s infinite;
  animation-delay: 0.98s;
  background-color: #1700ff;
}

@keyframes orbit98 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-205deg) rotateY(287deg) translateX(200px)
      rotateZ(205deg);
  }
  80% {
    transform: rotateZ(-205deg) rotateY(287deg) translateX(200px)
      rotateZ(205deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-205deg) rotateY(287deg) translateX(600px)
      rotateZ(205deg);
  }
}
.c:nth-child(99) {
  animation: orbit99 14s infinite;
  animation-delay: 0.99s;
  background-color: #1a00ff;
}

@keyframes orbit99 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-32deg) rotateY(64deg) translateX(200px) rotateZ(32deg);
  }
  80% {
    transform: rotateZ(-32deg) rotateY(64deg) translateX(200px) rotateZ(32deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-32deg) rotateY(64deg) translateX(600px) rotateZ(32deg);
  }
}
.c:nth-child(100) {
  animation: orbit100 14s infinite;
  animation-delay: 1s;
  background-color: #1c00ff;
}

@keyframes orbit100 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-256deg) rotateY(186deg) translateX(200px)
      rotateZ(256deg);
  }
  80% {
    transform: rotateZ(-256deg) rotateY(186deg) translateX(200px)
      rotateZ(256deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-256deg) rotateY(186deg) translateX(600px)
      rotateZ(256deg);
  }
}
.c:nth-child(101) {
  animation: orbit101 14s infinite;
  animation-delay: 1.01s;
  background-color: #1f00ff;
}

@keyframes orbit101 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-258deg) rotateY(328deg) translateX(200px)
      rotateZ(258deg);
  }
  80% {
    transform: rotateZ(-258deg) rotateY(328deg) translateX(200px)
      rotateZ(258deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-258deg) rotateY(328deg) translateX(600px)
      rotateZ(258deg);
  }
}
.c:nth-child(102) {
  animation: orbit102 14s infinite;
  animation-delay: 1.02s;
  background-color: #2200ff;
}

@keyframes orbit102 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-186deg) rotateY(170deg) translateX(200px)
      rotateZ(186deg);
  }
  80% {
    transform: rotateZ(-186deg) rotateY(170deg) translateX(200px)
      rotateZ(186deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-186deg) rotateY(170deg) translateX(600px)
      rotateZ(186deg);
  }
}
.c:nth-child(103) {
  animation: orbit103 14s infinite;
  animation-delay: 1.03s;
  background-color: #2500ff;
}

@keyframes orbit103 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-9deg) rotateY(343deg) translateX(200px) rotateZ(9deg);
  }
  80% {
    transform: rotateZ(-9deg) rotateY(343deg) translateX(200px) rotateZ(9deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-9deg) rotateY(343deg) translateX(600px) rotateZ(9deg);
  }
}
.c:nth-child(104) {
  animation: orbit104 14s infinite;
  animation-delay: 1.04s;
  background-color: #2800ff;
}

@keyframes orbit104 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-25deg) rotateY(122deg) translateX(200px) rotateZ(25deg);
  }
  80% {
    transform: rotateZ(-25deg) rotateY(122deg) translateX(200px) rotateZ(25deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-25deg) rotateY(122deg) translateX(600px) rotateZ(25deg);
  }
}
.c:nth-child(105) {
  animation: orbit105 14s infinite;
  animation-delay: 1.05s;
  background-color: #2b00ff;
}

@keyframes orbit105 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-18deg) rotateY(355deg) translateX(200px) rotateZ(18deg);
  }
  80% {
    transform: rotateZ(-18deg) rotateY(355deg) translateX(200px) rotateZ(18deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-18deg) rotateY(355deg) translateX(600px) rotateZ(18deg);
  }
}
.c:nth-child(106) {
  animation: orbit106 14s infinite;
  animation-delay: 1.06s;
  background-color: #2d00ff;
}

@keyframes orbit106 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-275deg) rotateY(235deg) translateX(200px)
      rotateZ(275deg);
  }
  80% {
    transform: rotateZ(-275deg) rotateY(235deg) translateX(200px)
      rotateZ(275deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-275deg) rotateY(235deg) translateX(600px)
      rotateZ(275deg);
  }
}
.c:nth-child(107) {
  animation: orbit107 14s infinite;
  animation-delay: 1.07s;
  background-color: #3000ff;
}

@keyframes orbit107 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-152deg) rotateY(29deg) translateX(200px) rotateZ(152deg);
  }
  80% {
    transform: rotateZ(-152deg) rotateY(29deg) translateX(200px) rotateZ(152deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-152deg) rotateY(29deg) translateX(600px) rotateZ(152deg);
  }
}
.c:nth-child(108) {
  animation: orbit108 14s infinite;
  animation-delay: 1.08s;
  background-color: #3300ff;
}

@keyframes orbit108 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-288deg) rotateY(48deg) translateX(200px) rotateZ(288deg);
  }
  80% {
    transform: rotateZ(-288deg) rotateY(48deg) translateX(200px) rotateZ(288deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-288deg) rotateY(48deg) translateX(600px) rotateZ(288deg);
  }
}
.c:nth-child(109) {
  animation: orbit109 14s infinite;
  animation-delay: 1.09s;
  background-color: #3600ff;
}

@keyframes orbit109 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-230deg) rotateY(24deg) translateX(200px) rotateZ(230deg);
  }
  80% {
    transform: rotateZ(-230deg) rotateY(24deg) translateX(200px) rotateZ(230deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-230deg) rotateY(24deg) translateX(600px) rotateZ(230deg);
  }
}
.c:nth-child(110) {
  animation: orbit110 14s infinite;
  animation-delay: 1.1s;
  background-color: #3900ff;
}

@keyframes orbit110 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-81deg) rotateY(117deg) translateX(200px) rotateZ(81deg);
  }
  80% {
    transform: rotateZ(-81deg) rotateY(117deg) translateX(200px) rotateZ(81deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-81deg) rotateY(117deg) translateX(600px) rotateZ(81deg);
  }
}
.c:nth-child(111) {
  animation: orbit111 14s infinite;
  animation-delay: 1.11s;
  background-color: #3c00ff;
}

@keyframes orbit111 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-24deg) rotateY(49deg) translateX(200px) rotateZ(24deg);
  }
  80% {
    transform: rotateZ(-24deg) rotateY(49deg) translateX(200px) rotateZ(24deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-24deg) rotateY(49deg) translateX(600px) rotateZ(24deg);
  }
}
.c:nth-child(112) {
  animation: orbit112 14s infinite;
  animation-delay: 1.12s;
  background-color: #3e00ff;
}

@keyframes orbit112 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-354deg) rotateY(360deg) translateX(200px)
      rotateZ(354deg);
  }
  80% {
    transform: rotateZ(-354deg) rotateY(360deg) translateX(200px)
      rotateZ(354deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-354deg) rotateY(360deg) translateX(600px)
      rotateZ(354deg);
  }
}
.c:nth-child(113) {
  animation: orbit113 14s infinite;
  animation-delay: 1.13s;
  background-color: #4100ff;
}

@keyframes orbit113 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-268deg) rotateY(135deg) translateX(200px)
      rotateZ(268deg);
  }
  80% {
    transform: rotateZ(-268deg) rotateY(135deg) translateX(200px)
      rotateZ(268deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-268deg) rotateY(135deg) translateX(600px)
      rotateZ(268deg);
  }
}
.c:nth-child(114) {
  animation: orbit114 14s infinite;
  animation-delay: 1.14s;
  background-color: #4400ff;
}

@keyframes orbit114 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-254deg) rotateY(110deg) translateX(200px)
      rotateZ(254deg);
  }
  80% {
    transform: rotateZ(-254deg) rotateY(110deg) translateX(200px)
      rotateZ(254deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-254deg) rotateY(110deg) translateX(600px)
      rotateZ(254deg);
  }
}
.c:nth-child(115) {
  animation: orbit115 14s infinite;
  animation-delay: 1.15s;
  background-color: #4700ff;
}

@keyframes orbit115 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-84deg) rotateY(65deg) translateX(200px) rotateZ(84deg);
  }
  80% {
    transform: rotateZ(-84deg) rotateY(65deg) translateX(200px) rotateZ(84deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-84deg) rotateY(65deg) translateX(600px) rotateZ(84deg);
  }
}
.c:nth-child(116) {
  animation: orbit116 14s infinite;
  animation-delay: 1.16s;
  background-color: #4a00ff;
}

@keyframes orbit116 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-309deg) rotateY(184deg) translateX(200px)
      rotateZ(309deg);
  }
  80% {
    transform: rotateZ(-309deg) rotateY(184deg) translateX(200px)
      rotateZ(309deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-309deg) rotateY(184deg) translateX(600px)
      rotateZ(309deg);
  }
}
.c:nth-child(117) {
  animation: orbit117 14s infinite;
  animation-delay: 1.17s;
  background-color: #4d00ff;
}

@keyframes orbit117 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-301deg) rotateY(245deg) translateX(200px)
      rotateZ(301deg);
  }
  80% {
    transform: rotateZ(-301deg) rotateY(245deg) translateX(200px)
      rotateZ(301deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-301deg) rotateY(245deg) translateX(600px)
      rotateZ(301deg);
  }
}
.c:nth-child(118) {
  animation: orbit118 14s infinite;
  animation-delay: 1.18s;
  background-color: #4f00ff;
}

@keyframes orbit118 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-234deg) rotateY(172deg) translateX(200px)
      rotateZ(234deg);
  }
  80% {
    transform: rotateZ(-234deg) rotateY(172deg) translateX(200px)
      rotateZ(234deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-234deg) rotateY(172deg) translateX(600px)
      rotateZ(234deg);
  }
}
.c:nth-child(119) {
  animation: orbit119 14s infinite;
  animation-delay: 1.19s;
  background-color: #5200ff;
}

@keyframes orbit119 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-349deg) rotateY(214deg) translateX(200px)
      rotateZ(349deg);
  }
  80% {
    transform: rotateZ(-349deg) rotateY(214deg) translateX(200px)
      rotateZ(349deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-349deg) rotateY(214deg) translateX(600px)
      rotateZ(349deg);
  }
}
.c:nth-child(120) {
  animation: orbit120 14s infinite;
  animation-delay: 1.2s;
  background-color: #5500ff;
}

@keyframes orbit120 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-22deg) rotateY(13deg) translateX(200px) rotateZ(22deg);
  }
  80% {
    transform: rotateZ(-22deg) rotateY(13deg) translateX(200px) rotateZ(22deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-22deg) rotateY(13deg) translateX(600px) rotateZ(22deg);
  }
}
.c:nth-child(121) {
  animation: orbit121 14s infinite;
  animation-delay: 1.21s;
  background-color: #5800ff;
}

@keyframes orbit121 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-128deg) rotateY(138deg) translateX(200px)
      rotateZ(128deg);
  }
  80% {
    transform: rotateZ(-128deg) rotateY(138deg) translateX(200px)
      rotateZ(128deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-128deg) rotateY(138deg) translateX(600px)
      rotateZ(128deg);
  }
}
.c:nth-child(122) {
  animation: orbit122 14s infinite;
  animation-delay: 1.22s;
  background-color: #5b00ff;
}

@keyframes orbit122 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-22deg) rotateY(314deg) translateX(200px) rotateZ(22deg);
  }
  80% {
    transform: rotateZ(-22deg) rotateY(314deg) translateX(200px) rotateZ(22deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-22deg) rotateY(314deg) translateX(600px) rotateZ(22deg);
  }
}
.c:nth-child(123) {
  animation: orbit123 14s infinite;
  animation-delay: 1.23s;
  background-color: #5e00ff;
}

@keyframes orbit123 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-27deg) rotateY(36deg) translateX(200px) rotateZ(27deg);
  }
  80% {
    transform: rotateZ(-27deg) rotateY(36deg) translateX(200px) rotateZ(27deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-27deg) rotateY(36deg) translateX(600px) rotateZ(27deg);
  }
}
.c:nth-child(124) {
  animation: orbit124 14s infinite;
  animation-delay: 1.24s;
  background-color: #6000ff;
}

@keyframes orbit124 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-180deg) rotateY(274deg) translateX(200px)
      rotateZ(180deg);
  }
  80% {
    transform: rotateZ(-180deg) rotateY(274deg) translateX(200px)
      rotateZ(180deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-180deg) rotateY(274deg) translateX(600px)
      rotateZ(180deg);
  }
}
.c:nth-child(125) {
  animation: orbit125 14s infinite;
  animation-delay: 1.25s;
  background-color: #6300ff;
}

@keyframes orbit125 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-138deg) rotateY(305deg) translateX(200px)
      rotateZ(138deg);
  }
  80% {
    transform: rotateZ(-138deg) rotateY(305deg) translateX(200px)
      rotateZ(138deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-138deg) rotateY(305deg) translateX(600px)
      rotateZ(138deg);
  }
}
.c:nth-child(126) {
  animation: orbit126 14s infinite;
  animation-delay: 1.26s;
  background-color: #6600ff;
}

@keyframes orbit126 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-159deg) rotateY(194deg) translateX(200px)
      rotateZ(159deg);
  }
  80% {
    transform: rotateZ(-159deg) rotateY(194deg) translateX(200px)
      rotateZ(159deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-159deg) rotateY(194deg) translateX(600px)
      rotateZ(159deg);
  }
}
.c:nth-child(127) {
  animation: orbit127 14s infinite;
  animation-delay: 1.27s;
  background-color: #6900ff;
}

@keyframes orbit127 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-38deg) rotateY(204deg) translateX(200px) rotateZ(38deg);
  }
  80% {
    transform: rotateZ(-38deg) rotateY(204deg) translateX(200px) rotateZ(38deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-38deg) rotateY(204deg) translateX(600px) rotateZ(38deg);
  }
}
.c:nth-child(128) {
  animation: orbit128 14s infinite;
  animation-delay: 1.28s;
  background-color: #6c00ff;
}

@keyframes orbit128 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-293deg) rotateY(29deg) translateX(200px) rotateZ(293deg);
  }
  80% {
    transform: rotateZ(-293deg) rotateY(29deg) translateX(200px) rotateZ(293deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-293deg) rotateY(29deg) translateX(600px) rotateZ(293deg);
  }
}
.c:nth-child(129) {
  animation: orbit129 14s infinite;
  animation-delay: 1.29s;
  background-color: #6f00ff;
}

@keyframes orbit129 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-100deg) rotateY(58deg) translateX(200px) rotateZ(100deg);
  }
  80% {
    transform: rotateZ(-100deg) rotateY(58deg) translateX(200px) rotateZ(100deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-100deg) rotateY(58deg) translateX(600px) rotateZ(100deg);
  }
}
.c:nth-child(130) {
  animation: orbit130 14s infinite;
  animation-delay: 1.3s;
  background-color: #7100ff;
}

@keyframes orbit130 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-145deg) rotateY(104deg) translateX(200px)
      rotateZ(145deg);
  }
  80% {
    transform: rotateZ(-145deg) rotateY(104deg) translateX(200px)
      rotateZ(145deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-145deg) rotateY(104deg) translateX(600px)
      rotateZ(145deg);
  }
}
.c:nth-child(131) {
  animation: orbit131 14s infinite;
  animation-delay: 1.31s;
  background-color: #7400ff;
}

@keyframes orbit131 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-295deg) rotateY(333deg) translateX(200px)
      rotateZ(295deg);
  }
  80% {
    transform: rotateZ(-295deg) rotateY(333deg) translateX(200px)
      rotateZ(295deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-295deg) rotateY(333deg) translateX(600px)
      rotateZ(295deg);
  }
}
.c:nth-child(132) {
  animation: orbit132 14s infinite;
  animation-delay: 1.32s;
  background-color: #7700ff;
}

@keyframes orbit132 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-18deg) rotateY(232deg) translateX(200px) rotateZ(18deg);
  }
  80% {
    transform: rotateZ(-18deg) rotateY(232deg) translateX(200px) rotateZ(18deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-18deg) rotateY(232deg) translateX(600px) rotateZ(18deg);
  }
}
.c:nth-child(133) {
  animation: orbit133 14s infinite;
  animation-delay: 1.33s;
  background-color: #7a00ff;
}

@keyframes orbit133 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-137deg) rotateY(287deg) translateX(200px)
      rotateZ(137deg);
  }
  80% {
    transform: rotateZ(-137deg) rotateY(287deg) translateX(200px)
      rotateZ(137deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-137deg) rotateY(287deg) translateX(600px)
      rotateZ(137deg);
  }
}
.c:nth-child(134) {
  animation: orbit134 14s infinite;
  animation-delay: 1.34s;
  background-color: #7d00ff;
}

@keyframes orbit134 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-59deg) rotateY(316deg) translateX(200px) rotateZ(59deg);
  }
  80% {
    transform: rotateZ(-59deg) rotateY(316deg) translateX(200px) rotateZ(59deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-59deg) rotateY(316deg) translateX(600px) rotateZ(59deg);
  }
}
.c:nth-child(135) {
  animation: orbit135 14s infinite;
  animation-delay: 1.35s;
  background-color: #8000ff;
}

@keyframes orbit135 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-251deg) rotateY(30deg) translateX(200px) rotateZ(251deg);
  }
  80% {
    transform: rotateZ(-251deg) rotateY(30deg) translateX(200px) rotateZ(251deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-251deg) rotateY(30deg) translateX(600px) rotateZ(251deg);
  }
}
.c:nth-child(136) {
  animation: orbit136 14s infinite;
  animation-delay: 1.36s;
  background-color: #8200ff;
}

@keyframes orbit136 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-287deg) rotateY(49deg) translateX(200px) rotateZ(287deg);
  }
  80% {
    transform: rotateZ(-287deg) rotateY(49deg) translateX(200px) rotateZ(287deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-287deg) rotateY(49deg) translateX(600px) rotateZ(287deg);
  }
}
.c:nth-child(137) {
  animation: orbit137 14s infinite;
  animation-delay: 1.37s;
  background-color: #8500ff;
}

@keyframes orbit137 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-311deg) rotateY(208deg) translateX(200px)
      rotateZ(311deg);
  }
  80% {
    transform: rotateZ(-311deg) rotateY(208deg) translateX(200px)
      rotateZ(311deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-311deg) rotateY(208deg) translateX(600px)
      rotateZ(311deg);
  }
}
.c:nth-child(138) {
  animation: orbit138 14s infinite;
  animation-delay: 1.38s;
  background-color: #8800ff;
}

@keyframes orbit138 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-28deg) rotateY(78deg) translateX(200px) rotateZ(28deg);
  }
  80% {
    transform: rotateZ(-28deg) rotateY(78deg) translateX(200px) rotateZ(28deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-28deg) rotateY(78deg) translateX(600px) rotateZ(28deg);
  }
}
.c:nth-child(139) {
  animation: orbit139 14s infinite;
  animation-delay: 1.39s;
  background-color: #8b00ff;
}

@keyframes orbit139 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-122deg) rotateY(98deg) translateX(200px) rotateZ(122deg);
  }
  80% {
    transform: rotateZ(-122deg) rotateY(98deg) translateX(200px) rotateZ(122deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-122deg) rotateY(98deg) translateX(600px) rotateZ(122deg);
  }
}
.c:nth-child(140) {
  animation: orbit140 14s infinite;
  animation-delay: 1.4s;
  background-color: #8e00ff;
}

@keyframes orbit140 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-270deg) rotateY(305deg) translateX(200px)
      rotateZ(270deg);
  }
  80% {
    transform: rotateZ(-270deg) rotateY(305deg) translateX(200px)
      rotateZ(270deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-270deg) rotateY(305deg) translateX(600px)
      rotateZ(270deg);
  }
}
.c:nth-child(141) {
  animation: orbit141 14s infinite;
  animation-delay: 1.41s;
  background-color: #9100ff;
}

@keyframes orbit141 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-87deg) rotateY(105deg) translateX(200px) rotateZ(87deg);
  }
  80% {
    transform: rotateZ(-87deg) rotateY(105deg) translateX(200px) rotateZ(87deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-87deg) rotateY(105deg) translateX(600px) rotateZ(87deg);
  }
}
.c:nth-child(142) {
  animation: orbit142 14s infinite;
  animation-delay: 1.42s;
  background-color: #9300ff;
}

@keyframes orbit142 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-226deg) rotateY(182deg) translateX(200px)
      rotateZ(226deg);
  }
  80% {
    transform: rotateZ(-226deg) rotateY(182deg) translateX(200px)
      rotateZ(226deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-226deg) rotateY(182deg) translateX(600px)
      rotateZ(226deg);
  }
}
.c:nth-child(143) {
  animation: orbit143 14s infinite;
  animation-delay: 1.43s;
  background-color: #9600ff;
}

@keyframes orbit143 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-115deg) rotateY(303deg) translateX(200px)
      rotateZ(115deg);
  }
  80% {
    transform: rotateZ(-115deg) rotateY(303deg) translateX(200px)
      rotateZ(115deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-115deg) rotateY(303deg) translateX(600px)
      rotateZ(115deg);
  }
}
.c:nth-child(144) {
  animation: orbit144 14s infinite;
  animation-delay: 1.44s;
  background-color: #9900ff;
}

@keyframes orbit144 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-90deg) rotateY(230deg) translateX(200px) rotateZ(90deg);
  }
  80% {
    transform: rotateZ(-90deg) rotateY(230deg) translateX(200px) rotateZ(90deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-90deg) rotateY(230deg) translateX(600px) rotateZ(90deg);
  }
}
.c:nth-child(145) {
  animation: orbit145 14s infinite;
  animation-delay: 1.45s;
  background-color: #9c00ff;
}

@keyframes orbit145 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-79deg) rotateY(216deg) translateX(200px) rotateZ(79deg);
  }
  80% {
    transform: rotateZ(-79deg) rotateY(216deg) translateX(200px) rotateZ(79deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-79deg) rotateY(216deg) translateX(600px) rotateZ(79deg);
  }
}
.c:nth-child(146) {
  animation: orbit146 14s infinite;
  animation-delay: 1.46s;
  background-color: #9f00ff;
}

@keyframes orbit146 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-158deg) rotateY(129deg) translateX(200px)
      rotateZ(158deg);
  }
  80% {
    transform: rotateZ(-158deg) rotateY(129deg) translateX(200px)
      rotateZ(158deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-158deg) rotateY(129deg) translateX(600px)
      rotateZ(158deg);
  }
}
.c:nth-child(147) {
  animation: orbit147 14s infinite;
  animation-delay: 1.47s;
  background-color: #a200ff;
}

@keyframes orbit147 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-104deg) rotateY(173deg) translateX(200px)
      rotateZ(104deg);
  }
  80% {
    transform: rotateZ(-104deg) rotateY(173deg) translateX(200px)
      rotateZ(104deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-104deg) rotateY(173deg) translateX(600px)
      rotateZ(104deg);
  }
}
.c:nth-child(148) {
  animation: orbit148 14s infinite;
  animation-delay: 1.48s;
  background-color: #a400ff;
}

@keyframes orbit148 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-126deg) rotateY(80deg) translateX(200px) rotateZ(126deg);
  }
  80% {
    transform: rotateZ(-126deg) rotateY(80deg) translateX(200px) rotateZ(126deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-126deg) rotateY(80deg) translateX(600px) rotateZ(126deg);
  }
}
.c:nth-child(149) {
  animation: orbit149 14s infinite;
  animation-delay: 1.49s;
  background-color: #a700ff;
}

@keyframes orbit149 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-315deg) rotateY(39deg) translateX(200px) rotateZ(315deg);
  }
  80% {
    transform: rotateZ(-315deg) rotateY(39deg) translateX(200px) rotateZ(315deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-315deg) rotateY(39deg) translateX(600px) rotateZ(315deg);
  }
}
.c:nth-child(150) {
  animation: orbit150 14s infinite;
  animation-delay: 1.5s;
  background-color: #aa00ff;
}

@keyframes orbit150 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-114deg) rotateY(149deg) translateX(200px)
      rotateZ(114deg);
  }
  80% {
    transform: rotateZ(-114deg) rotateY(149deg) translateX(200px)
      rotateZ(114deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-114deg) rotateY(149deg) translateX(600px)
      rotateZ(114deg);
  }
}
.c:nth-child(151) {
  animation: orbit151 14s infinite;
  animation-delay: 1.51s;
  background-color: #ad00ff;
}

@keyframes orbit151 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-327deg) rotateY(49deg) translateX(200px) rotateZ(327deg);
  }
  80% {
    transform: rotateZ(-327deg) rotateY(49deg) translateX(200px) rotateZ(327deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-327deg) rotateY(49deg) translateX(600px) rotateZ(327deg);
  }
}
.c:nth-child(152) {
  animation: orbit152 14s infinite;
  animation-delay: 1.52s;
  background-color: #b000ff;
}

@keyframes orbit152 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-162deg) rotateY(218deg) translateX(200px)
      rotateZ(162deg);
  }
  80% {
    transform: rotateZ(-162deg) rotateY(218deg) translateX(200px)
      rotateZ(162deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-162deg) rotateY(218deg) translateX(600px)
      rotateZ(162deg);
  }
}
.c:nth-child(153) {
  animation: orbit153 14s infinite;
  animation-delay: 1.53s;
  background-color: #b300ff;
}

@keyframes orbit153 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-155deg) rotateY(18deg) translateX(200px) rotateZ(155deg);
  }
  80% {
    transform: rotateZ(-155deg) rotateY(18deg) translateX(200px) rotateZ(155deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-155deg) rotateY(18deg) translateX(600px) rotateZ(155deg);
  }
}
.c:nth-child(154) {
  animation: orbit154 14s infinite;
  animation-delay: 1.54s;
  background-color: #b500ff;
}

@keyframes orbit154 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-274deg) rotateY(338deg) translateX(200px)
      rotateZ(274deg);
  }
  80% {
    transform: rotateZ(-274deg) rotateY(338deg) translateX(200px)
      rotateZ(274deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-274deg) rotateY(338deg) translateX(600px)
      rotateZ(274deg);
  }
}
.c:nth-child(155) {
  animation: orbit155 14s infinite;
  animation-delay: 1.55s;
  background-color: #b800ff;
}

@keyframes orbit155 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-77deg) rotateY(150deg) translateX(200px) rotateZ(77deg);
  }
  80% {
    transform: rotateZ(-77deg) rotateY(150deg) translateX(200px) rotateZ(77deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-77deg) rotateY(150deg) translateX(600px) rotateZ(77deg);
  }
}
.c:nth-child(156) {
  animation: orbit156 14s infinite;
  animation-delay: 1.56s;
  background-color: #bb00ff;
}

@keyframes orbit156 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-249deg) rotateY(226deg) translateX(200px)
      rotateZ(249deg);
  }
  80% {
    transform: rotateZ(-249deg) rotateY(226deg) translateX(200px)
      rotateZ(249deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-249deg) rotateY(226deg) translateX(600px)
      rotateZ(249deg);
  }
}
.c:nth-child(157) {
  animation: orbit157 14s infinite;
  animation-delay: 1.57s;
  background-color: #be00ff;
}

@keyframes orbit157 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-147deg) rotateY(126deg) translateX(200px)
      rotateZ(147deg);
  }
  80% {
    transform: rotateZ(-147deg) rotateY(126deg) translateX(200px)
      rotateZ(147deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-147deg) rotateY(126deg) translateX(600px)
      rotateZ(147deg);
  }
}
.c:nth-child(158) {
  animation: orbit158 14s infinite;
  animation-delay: 1.58s;
  background-color: #c100ff;
}

@keyframes orbit158 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-71deg) rotateY(270deg) translateX(200px) rotateZ(71deg);
  }
  80% {
    transform: rotateZ(-71deg) rotateY(270deg) translateX(200px) rotateZ(71deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-71deg) rotateY(270deg) translateX(600px) rotateZ(71deg);
  }
}
.c:nth-child(159) {
  animation: orbit159 14s infinite;
  animation-delay: 1.59s;
  background-color: #c400ff;
}

@keyframes orbit159 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-324deg) rotateY(228deg) translateX(200px)
      rotateZ(324deg);
  }
  80% {
    transform: rotateZ(-324deg) rotateY(228deg) translateX(200px)
      rotateZ(324deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-324deg) rotateY(228deg) translateX(600px)
      rotateZ(324deg);
  }
}
.c:nth-child(160) {
  animation: orbit160 14s infinite;
  animation-delay: 1.6s;
  background-color: #c600ff;
}

@keyframes orbit160 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-211deg) rotateY(175deg) translateX(200px)
      rotateZ(211deg);
  }
  80% {
    transform: rotateZ(-211deg) rotateY(175deg) translateX(200px)
      rotateZ(211deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-211deg) rotateY(175deg) translateX(600px)
      rotateZ(211deg);
  }
}
.c:nth-child(161) {
  animation: orbit161 14s infinite;
  animation-delay: 1.61s;
  background-color: #c900ff;
}

@keyframes orbit161 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-331deg) rotateY(357deg) translateX(200px)
      rotateZ(331deg);
  }
  80% {
    transform: rotateZ(-331deg) rotateY(357deg) translateX(200px)
      rotateZ(331deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-331deg) rotateY(357deg) translateX(600px)
      rotateZ(331deg);
  }
}
.c:nth-child(162) {
  animation: orbit162 14s infinite;
  animation-delay: 1.62s;
  background-color: #cc00ff;
}

@keyframes orbit162 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-299deg) rotateY(197deg) translateX(200px)
      rotateZ(299deg);
  }
  80% {
    transform: rotateZ(-299deg) rotateY(197deg) translateX(200px)
      rotateZ(299deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-299deg) rotateY(197deg) translateX(600px)
      rotateZ(299deg);
  }
}
.c:nth-child(163) {
  animation: orbit163 14s infinite;
  animation-delay: 1.63s;
  background-color: #cf00ff;
}

@keyframes orbit163 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-304deg) rotateY(339deg) translateX(200px)
      rotateZ(304deg);
  }
  80% {
    transform: rotateZ(-304deg) rotateY(339deg) translateX(200px)
      rotateZ(304deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-304deg) rotateY(339deg) translateX(600px)
      rotateZ(304deg);
  }
}
.c:nth-child(164) {
  animation: orbit164 14s infinite;
  animation-delay: 1.64s;
  background-color: #d200ff;
}

@keyframes orbit164 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-248deg) rotateY(341deg) translateX(200px)
      rotateZ(248deg);
  }
  80% {
    transform: rotateZ(-248deg) rotateY(341deg) translateX(200px)
      rotateZ(248deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-248deg) rotateY(341deg) translateX(600px)
      rotateZ(248deg);
  }
}
.c:nth-child(165) {
  animation: orbit165 14s infinite;
  animation-delay: 1.65s;
  background-color: #d500ff;
}

@keyframes orbit165 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-96deg) rotateY(145deg) translateX(200px) rotateZ(96deg);
  }
  80% {
    transform: rotateZ(-96deg) rotateY(145deg) translateX(200px) rotateZ(96deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-96deg) rotateY(145deg) translateX(600px) rotateZ(96deg);
  }
}
.c:nth-child(166) {
  animation: orbit166 14s infinite;
  animation-delay: 1.66s;
  background-color: #d700ff;
}

@keyframes orbit166 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-66deg) rotateY(244deg) translateX(200px) rotateZ(66deg);
  }
  80% {
    transform: rotateZ(-66deg) rotateY(244deg) translateX(200px) rotateZ(66deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-66deg) rotateY(244deg) translateX(600px) rotateZ(66deg);
  }
}
.c:nth-child(167) {
  animation: orbit167 14s infinite;
  animation-delay: 1.67s;
  background-color: #da00ff;
}

@keyframes orbit167 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-112deg) rotateY(332deg) translateX(200px)
      rotateZ(112deg);
  }
  80% {
    transform: rotateZ(-112deg) rotateY(332deg) translateX(200px)
      rotateZ(112deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-112deg) rotateY(332deg) translateX(600px)
      rotateZ(112deg);
  }
}
.c:nth-child(168) {
  animation: orbit168 14s infinite;
  animation-delay: 1.68s;
  background-color: #dd00ff;
}

@keyframes orbit168 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-55deg) rotateY(291deg) translateX(200px) rotateZ(55deg);
  }
  80% {
    transform: rotateZ(-55deg) rotateY(291deg) translateX(200px) rotateZ(55deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-55deg) rotateY(291deg) translateX(600px) rotateZ(55deg);
  }
}
.c:nth-child(169) {
  animation: orbit169 14s infinite;
  animation-delay: 1.69s;
  background-color: #e000ff;
}

@keyframes orbit169 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-188deg) rotateY(162deg) translateX(200px)
      rotateZ(188deg);
  }
  80% {
    transform: rotateZ(-188deg) rotateY(162deg) translateX(200px)
      rotateZ(188deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-188deg) rotateY(162deg) translateX(600px)
      rotateZ(188deg);
  }
}
.c:nth-child(170) {
  animation: orbit170 14s infinite;
  animation-delay: 1.7s;
  background-color: #e300ff;
}

@keyframes orbit170 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-105deg) rotateY(278deg) translateX(200px)
      rotateZ(105deg);
  }
  80% {
    transform: rotateZ(-105deg) rotateY(278deg) translateX(200px)
      rotateZ(105deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-105deg) rotateY(278deg) translateX(600px)
      rotateZ(105deg);
  }
}
.c:nth-child(171) {
  animation: orbit171 14s infinite;
  animation-delay: 1.71s;
  background-color: #e600ff;
}

@keyframes orbit171 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-280deg) rotateY(260deg) translateX(200px)
      rotateZ(280deg);
  }
  80% {
    transform: rotateZ(-280deg) rotateY(260deg) translateX(200px)
      rotateZ(280deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-280deg) rotateY(260deg) translateX(600px)
      rotateZ(280deg);
  }
}
.c:nth-child(172) {
  animation: orbit172 14s infinite;
  animation-delay: 1.72s;
  background-color: #e800ff;
}

@keyframes orbit172 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-23deg) rotateY(284deg) translateX(200px) rotateZ(23deg);
  }
  80% {
    transform: rotateZ(-23deg) rotateY(284deg) translateX(200px) rotateZ(23deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-23deg) rotateY(284deg) translateX(600px) rotateZ(23deg);
  }
}
.c:nth-child(173) {
  animation: orbit173 14s infinite;
  animation-delay: 1.73s;
  background-color: #eb00ff;
}

@keyframes orbit173 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-298deg) rotateY(187deg) translateX(200px)
      rotateZ(298deg);
  }
  80% {
    transform: rotateZ(-298deg) rotateY(187deg) translateX(200px)
      rotateZ(298deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-298deg) rotateY(187deg) translateX(600px)
      rotateZ(298deg);
  }
}
.c:nth-child(174) {
  animation: orbit174 14s infinite;
  animation-delay: 1.74s;
  background-color: #ee00ff;
}

@keyframes orbit174 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-169deg) rotateY(93deg) translateX(200px) rotateZ(169deg);
  }
  80% {
    transform: rotateZ(-169deg) rotateY(93deg) translateX(200px) rotateZ(169deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-169deg) rotateY(93deg) translateX(600px) rotateZ(169deg);
  }
}
.c:nth-child(175) {
  animation: orbit175 14s infinite;
  animation-delay: 1.75s;
  background-color: #f100ff;
}

@keyframes orbit175 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-334deg) rotateY(176deg) translateX(200px)
      rotateZ(334deg);
  }
  80% {
    transform: rotateZ(-334deg) rotateY(176deg) translateX(200px)
      rotateZ(334deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-334deg) rotateY(176deg) translateX(600px)
      rotateZ(334deg);
  }
}
.c:nth-child(176) {
  animation: orbit176 14s infinite;
  animation-delay: 1.76s;
  background-color: #f400ff;
}

@keyframes orbit176 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-181deg) rotateY(263deg) translateX(200px)
      rotateZ(181deg);
  }
  80% {
    transform: rotateZ(-181deg) rotateY(263deg) translateX(200px)
      rotateZ(181deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-181deg) rotateY(263deg) translateX(600px)
      rotateZ(181deg);
  }
}
.c:nth-child(177) {
  animation: orbit177 14s infinite;
  animation-delay: 1.77s;
  background-color: #f700ff;
}

@keyframes orbit177 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-19deg) rotateY(274deg) translateX(200px) rotateZ(19deg);
  }
  80% {
    transform: rotateZ(-19deg) rotateY(274deg) translateX(200px) rotateZ(19deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-19deg) rotateY(274deg) translateX(600px) rotateZ(19deg);
  }
}
.c:nth-child(178) {
  animation: orbit178 14s infinite;
  animation-delay: 1.78s;
  background-color: #f900ff;
}

@keyframes orbit178 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-28deg) rotateY(2deg) translateX(200px) rotateZ(28deg);
  }
  80% {
    transform: rotateZ(-28deg) rotateY(2deg) translateX(200px) rotateZ(28deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-28deg) rotateY(2deg) translateX(600px) rotateZ(28deg);
  }
}
.c:nth-child(179) {
  animation: orbit179 14s infinite;
  animation-delay: 1.79s;
  background-color: #fc00ff;
}

@keyframes orbit179 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-151deg) rotateY(289deg) translateX(200px)
      rotateZ(151deg);
  }
  80% {
    transform: rotateZ(-151deg) rotateY(289deg) translateX(200px)
      rotateZ(151deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-151deg) rotateY(289deg) translateX(600px)
      rotateZ(151deg);
  }
}
.c:nth-child(180) {
  animation: orbit180 14s infinite;
  animation-delay: 1.8s;
  background-color: fuchsia;
}

@keyframes orbit180 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-124deg) rotateY(141deg) translateX(200px)
      rotateZ(124deg);
  }
  80% {
    transform: rotateZ(-124deg) rotateY(141deg) translateX(200px)
      rotateZ(124deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-124deg) rotateY(141deg) translateX(600px)
      rotateZ(124deg);
  }
}
.c:nth-child(181) {
  animation: orbit181 14s infinite;
  animation-delay: 1.81s;
  background-color: #ff00fc;
}

@keyframes orbit181 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-325deg) rotateY(226deg) translateX(200px)
      rotateZ(325deg);
  }
  80% {
    transform: rotateZ(-325deg) rotateY(226deg) translateX(200px)
      rotateZ(325deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-325deg) rotateY(226deg) translateX(600px)
      rotateZ(325deg);
  }
}
.c:nth-child(182) {
  animation: orbit182 14s infinite;
  animation-delay: 1.82s;
  background-color: #ff00f9;
}

@keyframes orbit182 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-309deg) rotateY(188deg) translateX(200px)
      rotateZ(309deg);
  }
  80% {
    transform: rotateZ(-309deg) rotateY(188deg) translateX(200px)
      rotateZ(309deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-309deg) rotateY(188deg) translateX(600px)
      rotateZ(309deg);
  }
}
.c:nth-child(183) {
  animation: orbit183 14s infinite;
  animation-delay: 1.83s;
  background-color: #ff00f7;
}

@keyframes orbit183 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-209deg) rotateY(160deg) translateX(200px)
      rotateZ(209deg);
  }
  80% {
    transform: rotateZ(-209deg) rotateY(160deg) translateX(200px)
      rotateZ(209deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-209deg) rotateY(160deg) translateX(600px)
      rotateZ(209deg);
  }
}
.c:nth-child(184) {
  animation: orbit184 14s infinite;
  animation-delay: 1.84s;
  background-color: #ff00f4;
}

@keyframes orbit184 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-171deg) rotateY(30deg) translateX(200px) rotateZ(171deg);
  }
  80% {
    transform: rotateZ(-171deg) rotateY(30deg) translateX(200px) rotateZ(171deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-171deg) rotateY(30deg) translateX(600px) rotateZ(171deg);
  }
}
.c:nth-child(185) {
  animation: orbit185 14s infinite;
  animation-delay: 1.85s;
  background-color: #ff00f1;
}

@keyframes orbit185 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-304deg) rotateY(146deg) translateX(200px)
      rotateZ(304deg);
  }
  80% {
    transform: rotateZ(-304deg) rotateY(146deg) translateX(200px)
      rotateZ(304deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-304deg) rotateY(146deg) translateX(600px)
      rotateZ(304deg);
  }
}
.c:nth-child(186) {
  animation: orbit186 14s infinite;
  animation-delay: 1.86s;
  background-color: #ff00ee;
}

@keyframes orbit186 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-109deg) rotateY(64deg) translateX(200px) rotateZ(109deg);
  }
  80% {
    transform: rotateZ(-109deg) rotateY(64deg) translateX(200px) rotateZ(109deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-109deg) rotateY(64deg) translateX(600px) rotateZ(109deg);
  }
}
.c:nth-child(187) {
  animation: orbit187 14s infinite;
  animation-delay: 1.87s;
  background-color: #ff00eb;
}

@keyframes orbit187 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-35deg) rotateY(288deg) translateX(200px) rotateZ(35deg);
  }
  80% {
    transform: rotateZ(-35deg) rotateY(288deg) translateX(200px) rotateZ(35deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-35deg) rotateY(288deg) translateX(600px) rotateZ(35deg);
  }
}
.c:nth-child(188) {
  animation: orbit188 14s infinite;
  animation-delay: 1.88s;
  background-color: #ff00e8;
}

@keyframes orbit188 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-326deg) rotateY(308deg) translateX(200px)
      rotateZ(326deg);
  }
  80% {
    transform: rotateZ(-326deg) rotateY(308deg) translateX(200px)
      rotateZ(326deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-326deg) rotateY(308deg) translateX(600px)
      rotateZ(326deg);
  }
}
.c:nth-child(189) {
  animation: orbit189 14s infinite;
  animation-delay: 1.89s;
  background-color: #ff00e6;
}

@keyframes orbit189 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-10deg) rotateY(91deg) translateX(200px) rotateZ(10deg);
  }
  80% {
    transform: rotateZ(-10deg) rotateY(91deg) translateX(200px) rotateZ(10deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-10deg) rotateY(91deg) translateX(600px) rotateZ(10deg);
  }
}
.c:nth-child(190) {
  animation: orbit190 14s infinite;
  animation-delay: 1.9s;
  background-color: #ff00e3;
}

@keyframes orbit190 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-98deg) rotateY(61deg) translateX(200px) rotateZ(98deg);
  }
  80% {
    transform: rotateZ(-98deg) rotateY(61deg) translateX(200px) rotateZ(98deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-98deg) rotateY(61deg) translateX(600px) rotateZ(98deg);
  }
}
.c:nth-child(191) {
  animation: orbit191 14s infinite;
  animation-delay: 1.91s;
  background-color: #ff00e0;
}

@keyframes orbit191 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-248deg) rotateY(112deg) translateX(200px)
      rotateZ(248deg);
  }
  80% {
    transform: rotateZ(-248deg) rotateY(112deg) translateX(200px)
      rotateZ(248deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-248deg) rotateY(112deg) translateX(600px)
      rotateZ(248deg);
  }
}
.c:nth-child(192) {
  animation: orbit192 14s infinite;
  animation-delay: 1.92s;
  background-color: #ff00dd;
}

@keyframes orbit192 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-111deg) rotateY(178deg) translateX(200px)
      rotateZ(111deg);
  }
  80% {
    transform: rotateZ(-111deg) rotateY(178deg) translateX(200px)
      rotateZ(111deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-111deg) rotateY(178deg) translateX(600px)
      rotateZ(111deg);
  }
}
.c:nth-child(193) {
  animation: orbit193 14s infinite;
  animation-delay: 1.93s;
  background-color: #ff00da;
}

@keyframes orbit193 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-151deg) rotateY(120deg) translateX(200px)
      rotateZ(151deg);
  }
  80% {
    transform: rotateZ(-151deg) rotateY(120deg) translateX(200px)
      rotateZ(151deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-151deg) rotateY(120deg) translateX(600px)
      rotateZ(151deg);
  }
}
.c:nth-child(194) {
  animation: orbit194 14s infinite;
  animation-delay: 1.94s;
  background-color: #ff00d7;
}

@keyframes orbit194 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-178deg) rotateY(51deg) translateX(200px) rotateZ(178deg);
  }
  80% {
    transform: rotateZ(-178deg) rotateY(51deg) translateX(200px) rotateZ(178deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-178deg) rotateY(51deg) translateX(600px) rotateZ(178deg);
  }
}
.c:nth-child(195) {
  animation: orbit195 14s infinite;
  animation-delay: 1.95s;
  background-color: #ff00d5;
}

@keyframes orbit195 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-284deg) rotateY(282deg) translateX(200px)
      rotateZ(284deg);
  }
  80% {
    transform: rotateZ(-284deg) rotateY(282deg) translateX(200px)
      rotateZ(284deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-284deg) rotateY(282deg) translateX(600px)
      rotateZ(284deg);
  }
}
.c:nth-child(196) {
  animation: orbit196 14s infinite;
  animation-delay: 1.96s;
  background-color: #ff00d2;
}

@keyframes orbit196 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-194deg) rotateY(338deg) translateX(200px)
      rotateZ(194deg);
  }
  80% {
    transform: rotateZ(-194deg) rotateY(338deg) translateX(200px)
      rotateZ(194deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-194deg) rotateY(338deg) translateX(600px)
      rotateZ(194deg);
  }
}
.c:nth-child(197) {
  animation: orbit197 14s infinite;
  animation-delay: 1.97s;
  background-color: #ff00cf;
}

@keyframes orbit197 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-354deg) rotateY(207deg) translateX(200px)
      rotateZ(354deg);
  }
  80% {
    transform: rotateZ(-354deg) rotateY(207deg) translateX(200px)
      rotateZ(354deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-354deg) rotateY(207deg) translateX(600px)
      rotateZ(354deg);
  }
}
.c:nth-child(198) {
  animation: orbit198 14s infinite;
  animation-delay: 1.98s;
  background-color: #ff00cc;
}

@keyframes orbit198 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-325deg) rotateY(94deg) translateX(200px) rotateZ(325deg);
  }
  80% {
    transform: rotateZ(-325deg) rotateY(94deg) translateX(200px) rotateZ(325deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-325deg) rotateY(94deg) translateX(600px) rotateZ(325deg);
  }
}
.c:nth-child(199) {
  animation: orbit199 14s infinite;
  animation-delay: 1.99s;
  background-color: #ff00c9;
}

@keyframes orbit199 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-226deg) rotateY(329deg) translateX(200px)
      rotateZ(226deg);
  }
  80% {
    transform: rotateZ(-226deg) rotateY(329deg) translateX(200px)
      rotateZ(226deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-226deg) rotateY(329deg) translateX(600px)
      rotateZ(226deg);
  }
}
.c:nth-child(200) {
  animation: orbit200 14s infinite;
  animation-delay: 2s;
  background-color: #ff00c6;
}

@keyframes orbit200 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-190deg) rotateY(46deg) translateX(200px) rotateZ(190deg);
  }
  80% {
    transform: rotateZ(-190deg) rotateY(46deg) translateX(200px) rotateZ(190deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-190deg) rotateY(46deg) translateX(600px) rotateZ(190deg);
  }
}
.c:nth-child(201) {
  animation: orbit201 14s infinite;
  animation-delay: 2.01s;
  background-color: #ff00c4;
}

@keyframes orbit201 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-351deg) rotateY(292deg) translateX(200px)
      rotateZ(351deg);
  }
  80% {
    transform: rotateZ(-351deg) rotateY(292deg) translateX(200px)
      rotateZ(351deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-351deg) rotateY(292deg) translateX(600px)
      rotateZ(351deg);
  }
}
.c:nth-child(202) {
  animation: orbit202 14s infinite;
  animation-delay: 2.02s;
  background-color: #ff00c1;
}

@keyframes orbit202 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-311deg) rotateY(185deg) translateX(200px)
      rotateZ(311deg);
  }
  80% {
    transform: rotateZ(-311deg) rotateY(185deg) translateX(200px)
      rotateZ(311deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-311deg) rotateY(185deg) translateX(600px)
      rotateZ(311deg);
  }
}
.c:nth-child(203) {
  animation: orbit203 14s infinite;
  animation-delay: 2.03s;
  background-color: #ff00be;
}

@keyframes orbit203 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-310deg) rotateY(191deg) translateX(200px)
      rotateZ(310deg);
  }
  80% {
    transform: rotateZ(-310deg) rotateY(191deg) translateX(200px)
      rotateZ(310deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-310deg) rotateY(191deg) translateX(600px)
      rotateZ(310deg);
  }
}
.c:nth-child(204) {
  animation: orbit204 14s infinite;
  animation-delay: 2.04s;
  background-color: #ff00bb;
}

@keyframes orbit204 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-170deg) rotateY(117deg) translateX(200px)
      rotateZ(170deg);
  }
  80% {
    transform: rotateZ(-170deg) rotateY(117deg) translateX(200px)
      rotateZ(170deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-170deg) rotateY(117deg) translateX(600px)
      rotateZ(170deg);
  }
}
.c:nth-child(205) {
  animation: orbit205 14s infinite;
  animation-delay: 2.05s;
  background-color: #ff00b8;
}

@keyframes orbit205 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-163deg) rotateY(114deg) translateX(200px)
      rotateZ(163deg);
  }
  80% {
    transform: rotateZ(-163deg) rotateY(114deg) translateX(200px)
      rotateZ(163deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-163deg) rotateY(114deg) translateX(600px)
      rotateZ(163deg);
  }
}
.c:nth-child(206) {
  animation: orbit206 14s infinite;
  animation-delay: 2.06s;
  background-color: #ff00b5;
}

@keyframes orbit206 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-349deg) rotateY(285deg) translateX(200px)
      rotateZ(349deg);
  }
  80% {
    transform: rotateZ(-349deg) rotateY(285deg) translateX(200px)
      rotateZ(349deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-349deg) rotateY(285deg) translateX(600px)
      rotateZ(349deg);
  }
}
.c:nth-child(207) {
  animation: orbit207 14s infinite;
  animation-delay: 2.07s;
  background-color: #ff00b3;
}

@keyframes orbit207 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-344deg) rotateY(60deg) translateX(200px) rotateZ(344deg);
  }
  80% {
    transform: rotateZ(-344deg) rotateY(60deg) translateX(200px) rotateZ(344deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-344deg) rotateY(60deg) translateX(600px) rotateZ(344deg);
  }
}
.c:nth-child(208) {
  animation: orbit208 14s infinite;
  animation-delay: 2.08s;
  background-color: #ff00b0;
}

@keyframes orbit208 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-12deg) rotateY(149deg) translateX(200px) rotateZ(12deg);
  }
  80% {
    transform: rotateZ(-12deg) rotateY(149deg) translateX(200px) rotateZ(12deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-12deg) rotateY(149deg) translateX(600px) rotateZ(12deg);
  }
}
.c:nth-child(209) {
  animation: orbit209 14s infinite;
  animation-delay: 2.09s;
  background-color: #ff00ad;
}

@keyframes orbit209 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-300deg) rotateY(208deg) translateX(200px)
      rotateZ(300deg);
  }
  80% {
    transform: rotateZ(-300deg) rotateY(208deg) translateX(200px)
      rotateZ(300deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-300deg) rotateY(208deg) translateX(600px)
      rotateZ(300deg);
  }
}
.c:nth-child(210) {
  animation: orbit210 14s infinite;
  animation-delay: 2.1s;
  background-color: #ff00aa;
}

@keyframes orbit210 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-350deg) rotateY(54deg) translateX(200px) rotateZ(350deg);
  }
  80% {
    transform: rotateZ(-350deg) rotateY(54deg) translateX(200px) rotateZ(350deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-350deg) rotateY(54deg) translateX(600px) rotateZ(350deg);
  }
}
.c:nth-child(211) {
  animation: orbit211 14s infinite;
  animation-delay: 2.11s;
  background-color: #ff00a7;
}

@keyframes orbit211 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-303deg) rotateY(277deg) translateX(200px)
      rotateZ(303deg);
  }
  80% {
    transform: rotateZ(-303deg) rotateY(277deg) translateX(200px)
      rotateZ(303deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-303deg) rotateY(277deg) translateX(600px)
      rotateZ(303deg);
  }
}
.c:nth-child(212) {
  animation: orbit212 14s infinite;
  animation-delay: 2.12s;
  background-color: #ff00a4;
}

@keyframes orbit212 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-107deg) rotateY(102deg) translateX(200px)
      rotateZ(107deg);
  }
  80% {
    transform: rotateZ(-107deg) rotateY(102deg) translateX(200px)
      rotateZ(107deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-107deg) rotateY(102deg) translateX(600px)
      rotateZ(107deg);
  }
}
.c:nth-child(213) {
  animation: orbit213 14s infinite;
  animation-delay: 2.13s;
  background-color: #ff00a2;
}

@keyframes orbit213 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-153deg) rotateY(188deg) translateX(200px)
      rotateZ(153deg);
  }
  80% {
    transform: rotateZ(-153deg) rotateY(188deg) translateX(200px)
      rotateZ(153deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-153deg) rotateY(188deg) translateX(600px)
      rotateZ(153deg);
  }
}
.c:nth-child(214) {
  animation: orbit214 14s infinite;
  animation-delay: 2.14s;
  background-color: #ff009f;
}

@keyframes orbit214 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-191deg) rotateY(45deg) translateX(200px) rotateZ(191deg);
  }
  80% {
    transform: rotateZ(-191deg) rotateY(45deg) translateX(200px) rotateZ(191deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-191deg) rotateY(45deg) translateX(600px) rotateZ(191deg);
  }
}
.c:nth-child(215) {
  animation: orbit215 14s infinite;
  animation-delay: 2.15s;
  background-color: #ff009c;
}

@keyframes orbit215 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-121deg) rotateY(95deg) translateX(200px) rotateZ(121deg);
  }
  80% {
    transform: rotateZ(-121deg) rotateY(95deg) translateX(200px) rotateZ(121deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-121deg) rotateY(95deg) translateX(600px) rotateZ(121deg);
  }
}
.c:nth-child(216) {
  animation: orbit216 14s infinite;
  animation-delay: 2.16s;
  background-color: #ff0099;
}

@keyframes orbit216 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-316deg) rotateY(225deg) translateX(200px)
      rotateZ(316deg);
  }
  80% {
    transform: rotateZ(-316deg) rotateY(225deg) translateX(200px)
      rotateZ(316deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-316deg) rotateY(225deg) translateX(600px)
      rotateZ(316deg);
  }
}
.c:nth-child(217) {
  animation: orbit217 14s infinite;
  animation-delay: 2.17s;
  background-color: #ff0096;
}

@keyframes orbit217 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-343deg) rotateY(278deg) translateX(200px)
      rotateZ(343deg);
  }
  80% {
    transform: rotateZ(-343deg) rotateY(278deg) translateX(200px)
      rotateZ(343deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-343deg) rotateY(278deg) translateX(600px)
      rotateZ(343deg);
  }
}
.c:nth-child(218) {
  animation: orbit218 14s infinite;
  animation-delay: 2.18s;
  background-color: #ff0093;
}

@keyframes orbit218 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-327deg) rotateY(186deg) translateX(200px)
      rotateZ(327deg);
  }
  80% {
    transform: rotateZ(-327deg) rotateY(186deg) translateX(200px)
      rotateZ(327deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-327deg) rotateY(186deg) translateX(600px)
      rotateZ(327deg);
  }
}
.c:nth-child(219) {
  animation: orbit219 14s infinite;
  animation-delay: 2.19s;
  background-color: #ff0091;
}

@keyframes orbit219 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-207deg) rotateY(153deg) translateX(200px)
      rotateZ(207deg);
  }
  80% {
    transform: rotateZ(-207deg) rotateY(153deg) translateX(200px)
      rotateZ(207deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-207deg) rotateY(153deg) translateX(600px)
      rotateZ(207deg);
  }
}
.c:nth-child(220) {
  animation: orbit220 14s infinite;
  animation-delay: 2.2s;
  background-color: #ff008e;
}

@keyframes orbit220 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-98deg) rotateY(335deg) translateX(200px) rotateZ(98deg);
  }
  80% {
    transform: rotateZ(-98deg) rotateY(335deg) translateX(200px) rotateZ(98deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-98deg) rotateY(335deg) translateX(600px) rotateZ(98deg);
  }
}
.c:nth-child(221) {
  animation: orbit221 14s infinite;
  animation-delay: 2.21s;
  background-color: #ff008b;
}

@keyframes orbit221 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-138deg) rotateY(228deg) translateX(200px)
      rotateZ(138deg);
  }
  80% {
    transform: rotateZ(-138deg) rotateY(228deg) translateX(200px)
      rotateZ(138deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-138deg) rotateY(228deg) translateX(600px)
      rotateZ(138deg);
  }
}
.c:nth-child(222) {
  animation: orbit222 14s infinite;
  animation-delay: 2.22s;
  background-color: #ff0088;
}

@keyframes orbit222 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-102deg) rotateY(194deg) translateX(200px)
      rotateZ(102deg);
  }
  80% {
    transform: rotateZ(-102deg) rotateY(194deg) translateX(200px)
      rotateZ(102deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-102deg) rotateY(194deg) translateX(600px)
      rotateZ(102deg);
  }
}
.c:nth-child(223) {
  animation: orbit223 14s infinite;
  animation-delay: 2.23s;
  background-color: #ff0085;
}

@keyframes orbit223 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-113deg) rotateY(298deg) translateX(200px)
      rotateZ(113deg);
  }
  80% {
    transform: rotateZ(-113deg) rotateY(298deg) translateX(200px)
      rotateZ(113deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-113deg) rotateY(298deg) translateX(600px)
      rotateZ(113deg);
  }
}
.c:nth-child(224) {
  animation: orbit224 14s infinite;
  animation-delay: 2.24s;
  background-color: #ff0082;
}

@keyframes orbit224 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-80deg) rotateY(259deg) translateX(200px) rotateZ(80deg);
  }
  80% {
    transform: rotateZ(-80deg) rotateY(259deg) translateX(200px) rotateZ(80deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-80deg) rotateY(259deg) translateX(600px) rotateZ(80deg);
  }
}
.c:nth-child(225) {
  animation: orbit225 14s infinite;
  animation-delay: 2.25s;
  background-color: #ff0080;
}

@keyframes orbit225 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-244deg) rotateY(148deg) translateX(200px)
      rotateZ(244deg);
  }
  80% {
    transform: rotateZ(-244deg) rotateY(148deg) translateX(200px)
      rotateZ(244deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-244deg) rotateY(148deg) translateX(600px)
      rotateZ(244deg);
  }
}
.c:nth-child(226) {
  animation: orbit226 14s infinite;
  animation-delay: 2.26s;
  background-color: #ff007d;
}

@keyframes orbit226 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-252deg) rotateY(133deg) translateX(200px)
      rotateZ(252deg);
  }
  80% {
    transform: rotateZ(-252deg) rotateY(133deg) translateX(200px)
      rotateZ(252deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-252deg) rotateY(133deg) translateX(600px)
      rotateZ(252deg);
  }
}
.c:nth-child(227) {
  animation: orbit227 14s infinite;
  animation-delay: 2.27s;
  background-color: #ff007a;
}

@keyframes orbit227 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-164deg) rotateY(271deg) translateX(200px)
      rotateZ(164deg);
  }
  80% {
    transform: rotateZ(-164deg) rotateY(271deg) translateX(200px)
      rotateZ(164deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-164deg) rotateY(271deg) translateX(600px)
      rotateZ(164deg);
  }
}
.c:nth-child(228) {
  animation: orbit228 14s infinite;
  animation-delay: 2.28s;
  background-color: #ff0077;
}

@keyframes orbit228 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-132deg) rotateY(235deg) translateX(200px)
      rotateZ(132deg);
  }
  80% {
    transform: rotateZ(-132deg) rotateY(235deg) translateX(200px)
      rotateZ(132deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-132deg) rotateY(235deg) translateX(600px)
      rotateZ(132deg);
  }
}
.c:nth-child(229) {
  animation: orbit229 14s infinite;
  animation-delay: 2.29s;
  background-color: #ff0074;
}

@keyframes orbit229 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-326deg) rotateY(72deg) translateX(200px) rotateZ(326deg);
  }
  80% {
    transform: rotateZ(-326deg) rotateY(72deg) translateX(200px) rotateZ(326deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-326deg) rotateY(72deg) translateX(600px) rotateZ(326deg);
  }
}
.c:nth-child(230) {
  animation: orbit230 14s infinite;
  animation-delay: 2.3s;
  background-color: #ff0071;
}

@keyframes orbit230 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-347deg) rotateY(73deg) translateX(200px) rotateZ(347deg);
  }
  80% {
    transform: rotateZ(-347deg) rotateY(73deg) translateX(200px) rotateZ(347deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-347deg) rotateY(73deg) translateX(600px) rotateZ(347deg);
  }
}
.c:nth-child(231) {
  animation: orbit231 14s infinite;
  animation-delay: 2.31s;
  background-color: #ff006f;
}

@keyframes orbit231 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-119deg) rotateY(222deg) translateX(200px)
      rotateZ(119deg);
  }
  80% {
    transform: rotateZ(-119deg) rotateY(222deg) translateX(200px)
      rotateZ(119deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-119deg) rotateY(222deg) translateX(600px)
      rotateZ(119deg);
  }
}
.c:nth-child(232) {
  animation: orbit232 14s infinite;
  animation-delay: 2.32s;
  background-color: #ff006c;
}

@keyframes orbit232 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-93deg) rotateY(215deg) translateX(200px) rotateZ(93deg);
  }
  80% {
    transform: rotateZ(-93deg) rotateY(215deg) translateX(200px) rotateZ(93deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-93deg) rotateY(215deg) translateX(600px) rotateZ(93deg);
  }
}
.c:nth-child(233) {
  animation: orbit233 14s infinite;
  animation-delay: 2.33s;
  background-color: #ff0069;
}

@keyframes orbit233 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-96deg) rotateY(117deg) translateX(200px) rotateZ(96deg);
  }
  80% {
    transform: rotateZ(-96deg) rotateY(117deg) translateX(200px) rotateZ(96deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-96deg) rotateY(117deg) translateX(600px) rotateZ(96deg);
  }
}
.c:nth-child(234) {
  animation: orbit234 14s infinite;
  animation-delay: 2.34s;
  background-color: #ff0066;
}

@keyframes orbit234 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-14deg) rotateY(323deg) translateX(200px) rotateZ(14deg);
  }
  80% {
    transform: rotateZ(-14deg) rotateY(323deg) translateX(200px) rotateZ(14deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-14deg) rotateY(323deg) translateX(600px) rotateZ(14deg);
  }
}
.c:nth-child(235) {
  animation: orbit235 14s infinite;
  animation-delay: 2.35s;
  background-color: #ff0063;
}

@keyframes orbit235 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-144deg) rotateY(236deg) translateX(200px)
      rotateZ(144deg);
  }
  80% {
    transform: rotateZ(-144deg) rotateY(236deg) translateX(200px)
      rotateZ(144deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-144deg) rotateY(236deg) translateX(600px)
      rotateZ(144deg);
  }
}
.c:nth-child(236) {
  animation: orbit236 14s infinite;
  animation-delay: 2.36s;
  background-color: #ff0060;
}

@keyframes orbit236 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-224deg) rotateY(46deg) translateX(200px) rotateZ(224deg);
  }
  80% {
    transform: rotateZ(-224deg) rotateY(46deg) translateX(200px) rotateZ(224deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-224deg) rotateY(46deg) translateX(600px) rotateZ(224deg);
  }
}
.c:nth-child(237) {
  animation: orbit237 14s infinite;
  animation-delay: 2.37s;
  background-color: #ff005e;
}

@keyframes orbit237 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-207deg) rotateY(265deg) translateX(200px)
      rotateZ(207deg);
  }
  80% {
    transform: rotateZ(-207deg) rotateY(265deg) translateX(200px)
      rotateZ(207deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-207deg) rotateY(265deg) translateX(600px)
      rotateZ(207deg);
  }
}
.c:nth-child(238) {
  animation: orbit238 14s infinite;
  animation-delay: 2.38s;
  background-color: #ff005b;
}

@keyframes orbit238 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-87deg) rotateY(147deg) translateX(200px) rotateZ(87deg);
  }
  80% {
    transform: rotateZ(-87deg) rotateY(147deg) translateX(200px) rotateZ(87deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-87deg) rotateY(147deg) translateX(600px) rotateZ(87deg);
  }
}
.c:nth-child(239) {
  animation: orbit239 14s infinite;
  animation-delay: 2.39s;
  background-color: #ff0058;
}

@keyframes orbit239 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-152deg) rotateY(258deg) translateX(200px)
      rotateZ(152deg);
  }
  80% {
    transform: rotateZ(-152deg) rotateY(258deg) translateX(200px)
      rotateZ(152deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-152deg) rotateY(258deg) translateX(600px)
      rotateZ(152deg);
  }
}
.c:nth-child(240) {
  animation: orbit240 14s infinite;
  animation-delay: 2.4s;
  background-color: #ff0055;
}

@keyframes orbit240 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-315deg) rotateY(71deg) translateX(200px) rotateZ(315deg);
  }
  80% {
    transform: rotateZ(-315deg) rotateY(71deg) translateX(200px) rotateZ(315deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-315deg) rotateY(71deg) translateX(600px) rotateZ(315deg);
  }
}
.c:nth-child(241) {
  animation: orbit241 14s infinite;
  animation-delay: 2.41s;
  background-color: #ff0052;
}

@keyframes orbit241 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-196deg) rotateY(128deg) translateX(200px)
      rotateZ(196deg);
  }
  80% {
    transform: rotateZ(-196deg) rotateY(128deg) translateX(200px)
      rotateZ(196deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-196deg) rotateY(128deg) translateX(600px)
      rotateZ(196deg);
  }
}
.c:nth-child(242) {
  animation: orbit242 14s infinite;
  animation-delay: 2.42s;
  background-color: #ff004f;
}

@keyframes orbit242 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-288deg) rotateY(335deg) translateX(200px)
      rotateZ(288deg);
  }
  80% {
    transform: rotateZ(-288deg) rotateY(335deg) translateX(200px)
      rotateZ(288deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-288deg) rotateY(335deg) translateX(600px)
      rotateZ(288deg);
  }
}
.c:nth-child(243) {
  animation: orbit243 14s infinite;
  animation-delay: 2.43s;
  background-color: #ff004d;
}

@keyframes orbit243 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-283deg) rotateY(10deg) translateX(200px) rotateZ(283deg);
  }
  80% {
    transform: rotateZ(-283deg) rotateY(10deg) translateX(200px) rotateZ(283deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-283deg) rotateY(10deg) translateX(600px) rotateZ(283deg);
  }
}
.c:nth-child(244) {
  animation: orbit244 14s infinite;
  animation-delay: 2.44s;
  background-color: #ff004a;
}

@keyframes orbit244 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-96deg) rotateY(112deg) translateX(200px) rotateZ(96deg);
  }
  80% {
    transform: rotateZ(-96deg) rotateY(112deg) translateX(200px) rotateZ(96deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-96deg) rotateY(112deg) translateX(600px) rotateZ(96deg);
  }
}
.c:nth-child(245) {
  animation: orbit245 14s infinite;
  animation-delay: 2.45s;
  background-color: #ff0047;
}

@keyframes orbit245 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-160deg) rotateY(159deg) translateX(200px)
      rotateZ(160deg);
  }
  80% {
    transform: rotateZ(-160deg) rotateY(159deg) translateX(200px)
      rotateZ(160deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-160deg) rotateY(159deg) translateX(600px)
      rotateZ(160deg);
  }
}
.c:nth-child(246) {
  animation: orbit246 14s infinite;
  animation-delay: 2.46s;
  background-color: #ff0044;
}

@keyframes orbit246 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-231deg) rotateY(340deg) translateX(200px)
      rotateZ(231deg);
  }
  80% {
    transform: rotateZ(-231deg) rotateY(340deg) translateX(200px)
      rotateZ(231deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-231deg) rotateY(340deg) translateX(600px)
      rotateZ(231deg);
  }
}
.c:nth-child(247) {
  animation: orbit247 14s infinite;
  animation-delay: 2.47s;
  background-color: #ff0041;
}

@keyframes orbit247 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-207deg) rotateY(159deg) translateX(200px)
      rotateZ(207deg);
  }
  80% {
    transform: rotateZ(-207deg) rotateY(159deg) translateX(200px)
      rotateZ(207deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-207deg) rotateY(159deg) translateX(600px)
      rotateZ(207deg);
  }
}
.c:nth-child(248) {
  animation: orbit248 14s infinite;
  animation-delay: 2.48s;
  background-color: #ff003e;
}

@keyframes orbit248 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-331deg) rotateY(1deg) translateX(200px) rotateZ(331deg);
  }
  80% {
    transform: rotateZ(-331deg) rotateY(1deg) translateX(200px) rotateZ(331deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-331deg) rotateY(1deg) translateX(600px) rotateZ(331deg);
  }
}
.c:nth-child(249) {
  animation: orbit249 14s infinite;
  animation-delay: 2.49s;
  background-color: #ff003c;
}

@keyframes orbit249 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-125deg) rotateY(88deg) translateX(200px) rotateZ(125deg);
  }
  80% {
    transform: rotateZ(-125deg) rotateY(88deg) translateX(200px) rotateZ(125deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-125deg) rotateY(88deg) translateX(600px) rotateZ(125deg);
  }
}
.c:nth-child(250) {
  animation: orbit250 14s infinite;
  animation-delay: 2.5s;
  background-color: #ff0039;
}

@keyframes orbit250 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-228deg) rotateY(46deg) translateX(200px) rotateZ(228deg);
  }
  80% {
    transform: rotateZ(-228deg) rotateY(46deg) translateX(200px) rotateZ(228deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-228deg) rotateY(46deg) translateX(600px) rotateZ(228deg);
  }
}
.c:nth-child(251) {
  animation: orbit251 14s infinite;
  animation-delay: 2.51s;
  background-color: #ff0036;
}

@keyframes orbit251 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-229deg) rotateY(213deg) translateX(200px)
      rotateZ(229deg);
  }
  80% {
    transform: rotateZ(-229deg) rotateY(213deg) translateX(200px)
      rotateZ(229deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-229deg) rotateY(213deg) translateX(600px)
      rotateZ(229deg);
  }
}
.c:nth-child(252) {
  animation: orbit252 14s infinite;
  animation-delay: 2.52s;
  background-color: #ff0033;
}

@keyframes orbit252 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-18deg) rotateY(4deg) translateX(200px) rotateZ(18deg);
  }
  80% {
    transform: rotateZ(-18deg) rotateY(4deg) translateX(200px) rotateZ(18deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-18deg) rotateY(4deg) translateX(600px) rotateZ(18deg);
  }
}
.c:nth-child(253) {
  animation: orbit253 14s infinite;
  animation-delay: 2.53s;
  background-color: #ff0030;
}

@keyframes orbit253 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-121deg) rotateY(245deg) translateX(200px)
      rotateZ(121deg);
  }
  80% {
    transform: rotateZ(-121deg) rotateY(245deg) translateX(200px)
      rotateZ(121deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-121deg) rotateY(245deg) translateX(600px)
      rotateZ(121deg);
  }
}
.c:nth-child(254) {
  animation: orbit254 14s infinite;
  animation-delay: 2.54s;
  background-color: #ff002d;
}

@keyframes orbit254 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-287deg) rotateY(154deg) translateX(200px)
      rotateZ(287deg);
  }
  80% {
    transform: rotateZ(-287deg) rotateY(154deg) translateX(200px)
      rotateZ(287deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-287deg) rotateY(154deg) translateX(600px)
      rotateZ(287deg);
  }
}
.c:nth-child(255) {
  animation: orbit255 14s infinite;
  animation-delay: 2.55s;
  background-color: #ff002b;
}

@keyframes orbit255 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-298deg) rotateY(233deg) translateX(200px)
      rotateZ(298deg);
  }
  80% {
    transform: rotateZ(-298deg) rotateY(233deg) translateX(200px)
      rotateZ(298deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-298deg) rotateY(233deg) translateX(600px)
      rotateZ(298deg);
  }
}
.c:nth-child(256) {
  animation: orbit256 14s infinite;
  animation-delay: 2.56s;
  background-color: #ff0028;
}

@keyframes orbit256 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-96deg) rotateY(161deg) translateX(200px) rotateZ(96deg);
  }
  80% {
    transform: rotateZ(-96deg) rotateY(161deg) translateX(200px) rotateZ(96deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-96deg) rotateY(161deg) translateX(600px) rotateZ(96deg);
  }
}
.c:nth-child(257) {
  animation: orbit257 14s infinite;
  animation-delay: 2.57s;
  background-color: #ff0025;
}

@keyframes orbit257 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-144deg) rotateY(148deg) translateX(200px)
      rotateZ(144deg);
  }
  80% {
    transform: rotateZ(-144deg) rotateY(148deg) translateX(200px)
      rotateZ(144deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-144deg) rotateY(148deg) translateX(600px)
      rotateZ(144deg);
  }
}
.c:nth-child(258) {
  animation: orbit258 14s infinite;
  animation-delay: 2.58s;
  background-color: #ff0022;
}

@keyframes orbit258 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-63deg) rotateY(24deg) translateX(200px) rotateZ(63deg);
  }
  80% {
    transform: rotateZ(-63deg) rotateY(24deg) translateX(200px) rotateZ(63deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-63deg) rotateY(24deg) translateX(600px) rotateZ(63deg);
  }
}
.c:nth-child(259) {
  animation: orbit259 14s infinite;
  animation-delay: 2.59s;
  background-color: #ff001f;
}

@keyframes orbit259 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-278deg) rotateY(191deg) translateX(200px)
      rotateZ(278deg);
  }
  80% {
    transform: rotateZ(-278deg) rotateY(191deg) translateX(200px)
      rotateZ(278deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-278deg) rotateY(191deg) translateX(600px)
      rotateZ(278deg);
  }
}
.c:nth-child(260) {
  animation: orbit260 14s infinite;
  animation-delay: 2.6s;
  background-color: #ff001c;
}

@keyframes orbit260 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-116deg) rotateY(297deg) translateX(200px)
      rotateZ(116deg);
  }
  80% {
    transform: rotateZ(-116deg) rotateY(297deg) translateX(200px)
      rotateZ(116deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-116deg) rotateY(297deg) translateX(600px)
      rotateZ(116deg);
  }
}
.c:nth-child(261) {
  animation: orbit261 14s infinite;
  animation-delay: 2.61s;
  background-color: #ff001a;
}

@keyframes orbit261 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-121deg) rotateY(19deg) translateX(200px) rotateZ(121deg);
  }
  80% {
    transform: rotateZ(-121deg) rotateY(19deg) translateX(200px) rotateZ(121deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-121deg) rotateY(19deg) translateX(600px) rotateZ(121deg);
  }
}
.c:nth-child(262) {
  animation: orbit262 14s infinite;
  animation-delay: 2.62s;
  background-color: #ff0017;
}

@keyframes orbit262 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-203deg) rotateY(314deg) translateX(200px)
      rotateZ(203deg);
  }
  80% {
    transform: rotateZ(-203deg) rotateY(314deg) translateX(200px)
      rotateZ(203deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-203deg) rotateY(314deg) translateX(600px)
      rotateZ(203deg);
  }
}
.c:nth-child(263) {
  animation: orbit263 14s infinite;
  animation-delay: 2.63s;
  background-color: #ff0014;
}

@keyframes orbit263 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-259deg) rotateY(60deg) translateX(200px) rotateZ(259deg);
  }
  80% {
    transform: rotateZ(-259deg) rotateY(60deg) translateX(200px) rotateZ(259deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-259deg) rotateY(60deg) translateX(600px) rotateZ(259deg);
  }
}
.c:nth-child(264) {
  animation: orbit264 14s infinite;
  animation-delay: 2.64s;
  background-color: #ff0011;
}

@keyframes orbit264 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-116deg) rotateY(296deg) translateX(200px)
      rotateZ(116deg);
  }
  80% {
    transform: rotateZ(-116deg) rotateY(296deg) translateX(200px)
      rotateZ(116deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-116deg) rotateY(296deg) translateX(600px)
      rotateZ(116deg);
  }
}
.c:nth-child(265) {
  animation: orbit265 14s infinite;
  animation-delay: 2.65s;
  background-color: #ff000e;
}

@keyframes orbit265 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-212deg) rotateY(332deg) translateX(200px)
      rotateZ(212deg);
  }
  80% {
    transform: rotateZ(-212deg) rotateY(332deg) translateX(200px)
      rotateZ(212deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-212deg) rotateY(332deg) translateX(600px)
      rotateZ(212deg);
  }
}
.c:nth-child(266) {
  animation: orbit266 14s infinite;
  animation-delay: 2.66s;
  background-color: #ff000b;
}

@keyframes orbit266 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-161deg) rotateY(275deg) translateX(200px)
      rotateZ(161deg);
  }
  80% {
    transform: rotateZ(-161deg) rotateY(275deg) translateX(200px)
      rotateZ(161deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-161deg) rotateY(275deg) translateX(600px)
      rotateZ(161deg);
  }
}
.c:nth-child(267) {
  animation: orbit267 14s infinite;
  animation-delay: 2.67s;
  background-color: #ff0009;
}

@keyframes orbit267 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-295deg) rotateY(353deg) translateX(200px)
      rotateZ(295deg);
  }
  80% {
    transform: rotateZ(-295deg) rotateY(353deg) translateX(200px)
      rotateZ(295deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-295deg) rotateY(353deg) translateX(600px)
      rotateZ(295deg);
  }
}
.c:nth-child(268) {
  animation: orbit268 14s infinite;
  animation-delay: 2.68s;
  background-color: #ff0006;
}

@keyframes orbit268 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-242deg) rotateY(222deg) translateX(200px)
      rotateZ(242deg);
  }
  80% {
    transform: rotateZ(-242deg) rotateY(222deg) translateX(200px)
      rotateZ(242deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-242deg) rotateY(222deg) translateX(600px)
      rotateZ(242deg);
  }
}
.c:nth-child(269) {
  animation: orbit269 14s infinite;
  animation-delay: 2.69s;
  background-color: #ff0003;
}

@keyframes orbit269 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-102deg) rotateY(59deg) translateX(200px) rotateZ(102deg);
  }
  80% {
    transform: rotateZ(-102deg) rotateY(59deg) translateX(200px) rotateZ(102deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-102deg) rotateY(59deg) translateX(600px) rotateZ(102deg);
  }
}
.c:nth-child(270) {
  animation: orbit270 14s infinite;
  animation-delay: 2.7s;
  background-color: red;
}

@keyframes orbit270 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-352deg) rotateY(193deg) translateX(200px)
      rotateZ(352deg);
  }
  80% {
    transform: rotateZ(-352deg) rotateY(193deg) translateX(200px)
      rotateZ(352deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-352deg) rotateY(193deg) translateX(600px)
      rotateZ(352deg);
  }
}
.c:nth-child(271) {
  animation: orbit271 14s infinite;
  animation-delay: 2.71s;
  background-color: #ff0300;
}

@keyframes orbit271 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-322deg) rotateY(77deg) translateX(200px) rotateZ(322deg);
  }
  80% {
    transform: rotateZ(-322deg) rotateY(77deg) translateX(200px) rotateZ(322deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-322deg) rotateY(77deg) translateX(600px) rotateZ(322deg);
  }
}
.c:nth-child(272) {
  animation: orbit272 14s infinite;
  animation-delay: 2.72s;
  background-color: #ff0600;
}

@keyframes orbit272 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-159deg) rotateY(189deg) translateX(200px)
      rotateZ(159deg);
  }
  80% {
    transform: rotateZ(-159deg) rotateY(189deg) translateX(200px)
      rotateZ(159deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-159deg) rotateY(189deg) translateX(600px)
      rotateZ(159deg);
  }
}
.c:nth-child(273) {
  animation: orbit273 14s infinite;
  animation-delay: 2.73s;
  background-color: #ff0900;
}

@keyframes orbit273 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-295deg) rotateY(216deg) translateX(200px)
      rotateZ(295deg);
  }
  80% {
    transform: rotateZ(-295deg) rotateY(216deg) translateX(200px)
      rotateZ(295deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-295deg) rotateY(216deg) translateX(600px)
      rotateZ(295deg);
  }
}
.c:nth-child(274) {
  animation: orbit274 14s infinite;
  animation-delay: 2.74s;
  background-color: #ff0b00;
}

@keyframes orbit274 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-85deg) rotateY(62deg) translateX(200px) rotateZ(85deg);
  }
  80% {
    transform: rotateZ(-85deg) rotateY(62deg) translateX(200px) rotateZ(85deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-85deg) rotateY(62deg) translateX(600px) rotateZ(85deg);
  }
}
.c:nth-child(275) {
  animation: orbit275 14s infinite;
  animation-delay: 2.75s;
  background-color: #ff0e00;
}

@keyframes orbit275 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-236deg) rotateY(136deg) translateX(200px)
      rotateZ(236deg);
  }
  80% {
    transform: rotateZ(-236deg) rotateY(136deg) translateX(200px)
      rotateZ(236deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-236deg) rotateY(136deg) translateX(600px)
      rotateZ(236deg);
  }
}
.c:nth-child(276) {
  animation: orbit276 14s infinite;
  animation-delay: 2.76s;
  background-color: #ff1100;
}

@keyframes orbit276 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-56deg) rotateY(121deg) translateX(200px) rotateZ(56deg);
  }
  80% {
    transform: rotateZ(-56deg) rotateY(121deg) translateX(200px) rotateZ(56deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-56deg) rotateY(121deg) translateX(600px) rotateZ(56deg);
  }
}
.c:nth-child(277) {
  animation: orbit277 14s infinite;
  animation-delay: 2.77s;
  background-color: #ff1400;
}

@keyframes orbit277 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-215deg) rotateY(80deg) translateX(200px) rotateZ(215deg);
  }
  80% {
    transform: rotateZ(-215deg) rotateY(80deg) translateX(200px) rotateZ(215deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-215deg) rotateY(80deg) translateX(600px) rotateZ(215deg);
  }
}
.c:nth-child(278) {
  animation: orbit278 14s infinite;
  animation-delay: 2.78s;
  background-color: #ff1700;
}

@keyframes orbit278 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-85deg) rotateY(105deg) translateX(200px) rotateZ(85deg);
  }
  80% {
    transform: rotateZ(-85deg) rotateY(105deg) translateX(200px) rotateZ(85deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-85deg) rotateY(105deg) translateX(600px) rotateZ(85deg);
  }
}
.c:nth-child(279) {
  animation: orbit279 14s infinite;
  animation-delay: 2.79s;
  background-color: #ff1a00;
}

@keyframes orbit279 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-274deg) rotateY(313deg) translateX(200px)
      rotateZ(274deg);
  }
  80% {
    transform: rotateZ(-274deg) rotateY(313deg) translateX(200px)
      rotateZ(274deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-274deg) rotateY(313deg) translateX(600px)
      rotateZ(274deg);
  }
}
.c:nth-child(280) {
  animation: orbit280 14s infinite;
  animation-delay: 2.8s;
  background-color: #ff1c00;
}

@keyframes orbit280 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-274deg) rotateY(97deg) translateX(200px) rotateZ(274deg);
  }
  80% {
    transform: rotateZ(-274deg) rotateY(97deg) translateX(200px) rotateZ(274deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-274deg) rotateY(97deg) translateX(600px) rotateZ(274deg);
  }
}
.c:nth-child(281) {
  animation: orbit281 14s infinite;
  animation-delay: 2.81s;
  background-color: #ff1f00;
}

@keyframes orbit281 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-276deg) rotateY(129deg) translateX(200px)
      rotateZ(276deg);
  }
  80% {
    transform: rotateZ(-276deg) rotateY(129deg) translateX(200px)
      rotateZ(276deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-276deg) rotateY(129deg) translateX(600px)
      rotateZ(276deg);
  }
}
.c:nth-child(282) {
  animation: orbit282 14s infinite;
  animation-delay: 2.82s;
  background-color: #ff2200;
}

@keyframes orbit282 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-257deg) rotateY(19deg) translateX(200px) rotateZ(257deg);
  }
  80% {
    transform: rotateZ(-257deg) rotateY(19deg) translateX(200px) rotateZ(257deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-257deg) rotateY(19deg) translateX(600px) rotateZ(257deg);
  }
}
.c:nth-child(283) {
  animation: orbit283 14s infinite;
  animation-delay: 2.83s;
  background-color: #ff2500;
}

@keyframes orbit283 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-164deg) rotateY(51deg) translateX(200px) rotateZ(164deg);
  }
  80% {
    transform: rotateZ(-164deg) rotateY(51deg) translateX(200px) rotateZ(164deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-164deg) rotateY(51deg) translateX(600px) rotateZ(164deg);
  }
}
.c:nth-child(284) {
  animation: orbit284 14s infinite;
  animation-delay: 2.84s;
  background-color: #ff2800;
}

@keyframes orbit284 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-146deg) rotateY(82deg) translateX(200px) rotateZ(146deg);
  }
  80% {
    transform: rotateZ(-146deg) rotateY(82deg) translateX(200px) rotateZ(146deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-146deg) rotateY(82deg) translateX(600px) rotateZ(146deg);
  }
}
.c:nth-child(285) {
  animation: orbit285 14s infinite;
  animation-delay: 2.85s;
  background-color: #ff2b00;
}

@keyframes orbit285 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-243deg) rotateY(213deg) translateX(200px)
      rotateZ(243deg);
  }
  80% {
    transform: rotateZ(-243deg) rotateY(213deg) translateX(200px)
      rotateZ(243deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-243deg) rotateY(213deg) translateX(600px)
      rotateZ(243deg);
  }
}
.c:nth-child(286) {
  animation: orbit286 14s infinite;
  animation-delay: 2.86s;
  background-color: #ff2d00;
}

@keyframes orbit286 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-41deg) rotateY(186deg) translateX(200px) rotateZ(41deg);
  }
  80% {
    transform: rotateZ(-41deg) rotateY(186deg) translateX(200px) rotateZ(41deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-41deg) rotateY(186deg) translateX(600px) rotateZ(41deg);
  }
}
.c:nth-child(287) {
  animation: orbit287 14s infinite;
  animation-delay: 2.87s;
  background-color: #ff3000;
}

@keyframes orbit287 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-224deg) rotateY(336deg) translateX(200px)
      rotateZ(224deg);
  }
  80% {
    transform: rotateZ(-224deg) rotateY(336deg) translateX(200px)
      rotateZ(224deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-224deg) rotateY(336deg) translateX(600px)
      rotateZ(224deg);
  }
}
.c:nth-child(288) {
  animation: orbit288 14s infinite;
  animation-delay: 2.88s;
  background-color: #ff3300;
}

@keyframes orbit288 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-319deg) rotateY(142deg) translateX(200px)
      rotateZ(319deg);
  }
  80% {
    transform: rotateZ(-319deg) rotateY(142deg) translateX(200px)
      rotateZ(319deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-319deg) rotateY(142deg) translateX(600px)
      rotateZ(319deg);
  }
}
.c:nth-child(289) {
  animation: orbit289 14s infinite;
  animation-delay: 2.89s;
  background-color: #ff3600;
}

@keyframes orbit289 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-258deg) rotateY(186deg) translateX(200px)
      rotateZ(258deg);
  }
  80% {
    transform: rotateZ(-258deg) rotateY(186deg) translateX(200px)
      rotateZ(258deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-258deg) rotateY(186deg) translateX(600px)
      rotateZ(258deg);
  }
}
.c:nth-child(290) {
  animation: orbit290 14s infinite;
  animation-delay: 2.9s;
  background-color: #ff3900;
}

@keyframes orbit290 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-120deg) rotateY(51deg) translateX(200px) rotateZ(120deg);
  }
  80% {
    transform: rotateZ(-120deg) rotateY(51deg) translateX(200px) rotateZ(120deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-120deg) rotateY(51deg) translateX(600px) rotateZ(120deg);
  }
}
.c:nth-child(291) {
  animation: orbit291 14s infinite;
  animation-delay: 2.91s;
  background-color: #ff3c00;
}

@keyframes orbit291 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-224deg) rotateY(354deg) translateX(200px)
      rotateZ(224deg);
  }
  80% {
    transform: rotateZ(-224deg) rotateY(354deg) translateX(200px)
      rotateZ(224deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-224deg) rotateY(354deg) translateX(600px)
      rotateZ(224deg);
  }
}
.c:nth-child(292) {
  animation: orbit292 14s infinite;
  animation-delay: 2.92s;
  background-color: #ff3e00;
}

@keyframes orbit292 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-328deg) rotateY(169deg) translateX(200px)
      rotateZ(328deg);
  }
  80% {
    transform: rotateZ(-328deg) rotateY(169deg) translateX(200px)
      rotateZ(328deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-328deg) rotateY(169deg) translateX(600px)
      rotateZ(328deg);
  }
}
.c:nth-child(293) {
  animation: orbit293 14s infinite;
  animation-delay: 2.93s;
  background-color: #ff4100;
}

@keyframes orbit293 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-92deg) rotateY(260deg) translateX(200px) rotateZ(92deg);
  }
  80% {
    transform: rotateZ(-92deg) rotateY(260deg) translateX(200px) rotateZ(92deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-92deg) rotateY(260deg) translateX(600px) rotateZ(92deg);
  }
}
.c:nth-child(294) {
  animation: orbit294 14s infinite;
  animation-delay: 2.94s;
  background-color: #ff4400;
}

@keyframes orbit294 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-181deg) rotateY(113deg) translateX(200px)
      rotateZ(181deg);
  }
  80% {
    transform: rotateZ(-181deg) rotateY(113deg) translateX(200px)
      rotateZ(181deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-181deg) rotateY(113deg) translateX(600px)
      rotateZ(181deg);
  }
}
.c:nth-child(295) {
  animation: orbit295 14s infinite;
  animation-delay: 2.95s;
  background-color: #ff4700;
}

@keyframes orbit295 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-170deg) rotateY(354deg) translateX(200px)
      rotateZ(170deg);
  }
  80% {
    transform: rotateZ(-170deg) rotateY(354deg) translateX(200px)
      rotateZ(170deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-170deg) rotateY(354deg) translateX(600px)
      rotateZ(170deg);
  }
}
.c:nth-child(296) {
  animation: orbit296 14s infinite;
  animation-delay: 2.96s;
  background-color: #ff4a00;
}

@keyframes orbit296 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-249deg) rotateY(54deg) translateX(200px) rotateZ(249deg);
  }
  80% {
    transform: rotateZ(-249deg) rotateY(54deg) translateX(200px) rotateZ(249deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-249deg) rotateY(54deg) translateX(600px) rotateZ(249deg);
  }
}
.c:nth-child(297) {
  animation: orbit297 14s infinite;
  animation-delay: 2.97s;
  background-color: #ff4d00;
}

@keyframes orbit297 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-40deg) rotateY(158deg) translateX(200px) rotateZ(40deg);
  }
  80% {
    transform: rotateZ(-40deg) rotateY(158deg) translateX(200px) rotateZ(40deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-40deg) rotateY(158deg) translateX(600px) rotateZ(40deg);
  }
}
.c:nth-child(298) {
  animation: orbit298 14s infinite;
  animation-delay: 2.98s;
  background-color: #ff4f00;
}

@keyframes orbit298 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-323deg) rotateY(169deg) translateX(200px)
      rotateZ(323deg);
  }
  80% {
    transform: rotateZ(-323deg) rotateY(169deg) translateX(200px)
      rotateZ(323deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-323deg) rotateY(169deg) translateX(600px)
      rotateZ(323deg);
  }
}
.c:nth-child(299) {
  animation: orbit299 14s infinite;
  animation-delay: 2.99s;
  background-color: #ff5200;
}

@keyframes orbit299 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-318deg) rotateY(350deg) translateX(200px)
      rotateZ(318deg);
  }
  80% {
    transform: rotateZ(-318deg) rotateY(350deg) translateX(200px)
      rotateZ(318deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-318deg) rotateY(350deg) translateX(600px)
      rotateZ(318deg);
  }
}
.c:nth-child(300) {
  animation: orbit300 14s infinite;
  animation-delay: 3s;
  background-color: #ff5500;
}

@keyframes orbit300 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-166deg) rotateY(152deg) translateX(200px)
      rotateZ(166deg);
  }
  80% {
    transform: rotateZ(-166deg) rotateY(152deg) translateX(200px)
      rotateZ(166deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-166deg) rotateY(152deg) translateX(600px)
      rotateZ(166deg);
  }
}
.c:nth-child(301) {
  animation: orbit301 14s infinite;
  animation-delay: 3.01s;
  background-color: #ff5800;
}

@keyframes orbit301 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-177deg) rotateY(286deg) translateX(200px)
      rotateZ(177deg);
  }
  80% {
    transform: rotateZ(-177deg) rotateY(286deg) translateX(200px)
      rotateZ(177deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-177deg) rotateY(286deg) translateX(600px)
      rotateZ(177deg);
  }
}
.c:nth-child(302) {
  animation: orbit302 14s infinite;
  animation-delay: 3.02s;
  background-color: #ff5b00;
}

@keyframes orbit302 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-72deg) rotateY(343deg) translateX(200px) rotateZ(72deg);
  }
  80% {
    transform: rotateZ(-72deg) rotateY(343deg) translateX(200px) rotateZ(72deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-72deg) rotateY(343deg) translateX(600px) rotateZ(72deg);
  }
}
.c:nth-child(303) {
  animation: orbit303 14s infinite;
  animation-delay: 3.03s;
  background-color: #ff5e00;
}

@keyframes orbit303 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-134deg) rotateY(211deg) translateX(200px)
      rotateZ(134deg);
  }
  80% {
    transform: rotateZ(-134deg) rotateY(211deg) translateX(200px)
      rotateZ(134deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-134deg) rotateY(211deg) translateX(600px)
      rotateZ(134deg);
  }
}
.c:nth-child(304) {
  animation: orbit304 14s infinite;
  animation-delay: 3.04s;
  background-color: #ff6000;
}

@keyframes orbit304 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-98deg) rotateY(187deg) translateX(200px) rotateZ(98deg);
  }
  80% {
    transform: rotateZ(-98deg) rotateY(187deg) translateX(200px) rotateZ(98deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-98deg) rotateY(187deg) translateX(600px) rotateZ(98deg);
  }
}
.c:nth-child(305) {
  animation: orbit305 14s infinite;
  animation-delay: 3.05s;
  background-color: #ff6300;
}

@keyframes orbit305 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-137deg) rotateY(180deg) translateX(200px)
      rotateZ(137deg);
  }
  80% {
    transform: rotateZ(-137deg) rotateY(180deg) translateX(200px)
      rotateZ(137deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-137deg) rotateY(180deg) translateX(600px)
      rotateZ(137deg);
  }
}
.c:nth-child(306) {
  animation: orbit306 14s infinite;
  animation-delay: 3.06s;
  background-color: #ff6600;
}

@keyframes orbit306 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-211deg) rotateY(69deg) translateX(200px) rotateZ(211deg);
  }
  80% {
    transform: rotateZ(-211deg) rotateY(69deg) translateX(200px) rotateZ(211deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-211deg) rotateY(69deg) translateX(600px) rotateZ(211deg);
  }
}
.c:nth-child(307) {
  animation: orbit307 14s infinite;
  animation-delay: 3.07s;
  background-color: #ff6900;
}

@keyframes orbit307 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-88deg) rotateY(312deg) translateX(200px) rotateZ(88deg);
  }
  80% {
    transform: rotateZ(-88deg) rotateY(312deg) translateX(200px) rotateZ(88deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-88deg) rotateY(312deg) translateX(600px) rotateZ(88deg);
  }
}
.c:nth-child(308) {
  animation: orbit308 14s infinite;
  animation-delay: 3.08s;
  background-color: #ff6c00;
}

@keyframes orbit308 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-23deg) rotateY(107deg) translateX(200px) rotateZ(23deg);
  }
  80% {
    transform: rotateZ(-23deg) rotateY(107deg) translateX(200px) rotateZ(23deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-23deg) rotateY(107deg) translateX(600px) rotateZ(23deg);
  }
}
.c:nth-child(309) {
  animation: orbit309 14s infinite;
  animation-delay: 3.09s;
  background-color: #ff6f00;
}

@keyframes orbit309 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-169deg) rotateY(94deg) translateX(200px) rotateZ(169deg);
  }
  80% {
    transform: rotateZ(-169deg) rotateY(94deg) translateX(200px) rotateZ(169deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-169deg) rotateY(94deg) translateX(600px) rotateZ(169deg);
  }
}
.c:nth-child(310) {
  animation: orbit310 14s infinite;
  animation-delay: 3.1s;
  background-color: #ff7100;
}

@keyframes orbit310 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-186deg) rotateY(58deg) translateX(200px) rotateZ(186deg);
  }
  80% {
    transform: rotateZ(-186deg) rotateY(58deg) translateX(200px) rotateZ(186deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-186deg) rotateY(58deg) translateX(600px) rotateZ(186deg);
  }
}
.c:nth-child(311) {
  animation: orbit311 14s infinite;
  animation-delay: 3.11s;
  background-color: #ff7400;
}

@keyframes orbit311 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-288deg) rotateY(42deg) translateX(200px) rotateZ(288deg);
  }
  80% {
    transform: rotateZ(-288deg) rotateY(42deg) translateX(200px) rotateZ(288deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-288deg) rotateY(42deg) translateX(600px) rotateZ(288deg);
  }
}
.c:nth-child(312) {
  animation: orbit312 14s infinite;
  animation-delay: 3.12s;
  background-color: #ff7700;
}

@keyframes orbit312 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-237deg) rotateY(293deg) translateX(200px)
      rotateZ(237deg);
  }
  80% {
    transform: rotateZ(-237deg) rotateY(293deg) translateX(200px)
      rotateZ(237deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-237deg) rotateY(293deg) translateX(600px)
      rotateZ(237deg);
  }
}
.c:nth-child(313) {
  animation: orbit313 14s infinite;
  animation-delay: 3.13s;
  background-color: #ff7a00;
}

@keyframes orbit313 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-356deg) rotateY(40deg) translateX(200px) rotateZ(356deg);
  }
  80% {
    transform: rotateZ(-356deg) rotateY(40deg) translateX(200px) rotateZ(356deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-356deg) rotateY(40deg) translateX(600px) rotateZ(356deg);
  }
}
.c:nth-child(314) {
  animation: orbit314 14s infinite;
  animation-delay: 3.14s;
  background-color: #ff7d00;
}

@keyframes orbit314 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-311deg) rotateY(275deg) translateX(200px)
      rotateZ(311deg);
  }
  80% {
    transform: rotateZ(-311deg) rotateY(275deg) translateX(200px)
      rotateZ(311deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-311deg) rotateY(275deg) translateX(600px)
      rotateZ(311deg);
  }
}
.c:nth-child(315) {
  animation: orbit315 14s infinite;
  animation-delay: 3.15s;
  background-color: #ff8000;
}

@keyframes orbit315 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-194deg) rotateY(302deg) translateX(200px)
      rotateZ(194deg);
  }
  80% {
    transform: rotateZ(-194deg) rotateY(302deg) translateX(200px)
      rotateZ(194deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-194deg) rotateY(302deg) translateX(600px)
      rotateZ(194deg);
  }
}
.c:nth-child(316) {
  animation: orbit316 14s infinite;
  animation-delay: 3.16s;
  background-color: #ff8200;
}

@keyframes orbit316 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-22deg) rotateY(219deg) translateX(200px) rotateZ(22deg);
  }
  80% {
    transform: rotateZ(-22deg) rotateY(219deg) translateX(200px) rotateZ(22deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-22deg) rotateY(219deg) translateX(600px) rotateZ(22deg);
  }
}
.c:nth-child(317) {
  animation: orbit317 14s infinite;
  animation-delay: 3.17s;
  background-color: #ff8500;
}

@keyframes orbit317 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-317deg) rotateY(295deg) translateX(200px)
      rotateZ(317deg);
  }
  80% {
    transform: rotateZ(-317deg) rotateY(295deg) translateX(200px)
      rotateZ(317deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-317deg) rotateY(295deg) translateX(600px)
      rotateZ(317deg);
  }
}
.c:nth-child(318) {
  animation: orbit318 14s infinite;
  animation-delay: 3.18s;
  background-color: #ff8800;
}

@keyframes orbit318 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-187deg) rotateY(193deg) translateX(200px)
      rotateZ(187deg);
  }
  80% {
    transform: rotateZ(-187deg) rotateY(193deg) translateX(200px)
      rotateZ(187deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-187deg) rotateY(193deg) translateX(600px)
      rotateZ(187deg);
  }
}
.c:nth-child(319) {
  animation: orbit319 14s infinite;
  animation-delay: 3.19s;
  background-color: #ff8b00;
}

@keyframes orbit319 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-239deg) rotateY(199deg) translateX(200px)
      rotateZ(239deg);
  }
  80% {
    transform: rotateZ(-239deg) rotateY(199deg) translateX(200px)
      rotateZ(239deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-239deg) rotateY(199deg) translateX(600px)
      rotateZ(239deg);
  }
}
.c:nth-child(320) {
  animation: orbit320 14s infinite;
  animation-delay: 3.2s;
  background-color: #ff8e00;
}

@keyframes orbit320 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-162deg) rotateY(98deg) translateX(200px) rotateZ(162deg);
  }
  80% {
    transform: rotateZ(-162deg) rotateY(98deg) translateX(200px) rotateZ(162deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-162deg) rotateY(98deg) translateX(600px) rotateZ(162deg);
  }
}
.c:nth-child(321) {
  animation: orbit321 14s infinite;
  animation-delay: 3.21s;
  background-color: #ff9100;
}

@keyframes orbit321 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-276deg) rotateY(298deg) translateX(200px)
      rotateZ(276deg);
  }
  80% {
    transform: rotateZ(-276deg) rotateY(298deg) translateX(200px)
      rotateZ(276deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-276deg) rotateY(298deg) translateX(600px)
      rotateZ(276deg);
  }
}
.c:nth-child(322) {
  animation: orbit322 14s infinite;
  animation-delay: 3.22s;
  background-color: #ff9300;
}

@keyframes orbit322 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-21deg) rotateY(203deg) translateX(200px) rotateZ(21deg);
  }
  80% {
    transform: rotateZ(-21deg) rotateY(203deg) translateX(200px) rotateZ(21deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-21deg) rotateY(203deg) translateX(600px) rotateZ(21deg);
  }
}
.c:nth-child(323) {
  animation: orbit323 14s infinite;
  animation-delay: 3.23s;
  background-color: #ff9600;
}

@keyframes orbit323 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-350deg) rotateY(94deg) translateX(200px) rotateZ(350deg);
  }
  80% {
    transform: rotateZ(-350deg) rotateY(94deg) translateX(200px) rotateZ(350deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-350deg) rotateY(94deg) translateX(600px) rotateZ(350deg);
  }
}
.c:nth-child(324) {
  animation: orbit324 14s infinite;
  animation-delay: 3.24s;
  background-color: #ff9900;
}

@keyframes orbit324 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-203deg) rotateY(319deg) translateX(200px)
      rotateZ(203deg);
  }
  80% {
    transform: rotateZ(-203deg) rotateY(319deg) translateX(200px)
      rotateZ(203deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-203deg) rotateY(319deg) translateX(600px)
      rotateZ(203deg);
  }
}
.c:nth-child(325) {
  animation: orbit325 14s infinite;
  animation-delay: 3.25s;
  background-color: #ff9c00;
}

@keyframes orbit325 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-200deg) rotateY(206deg) translateX(200px)
      rotateZ(200deg);
  }
  80% {
    transform: rotateZ(-200deg) rotateY(206deg) translateX(200px)
      rotateZ(200deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-200deg) rotateY(206deg) translateX(600px)
      rotateZ(200deg);
  }
}
.c:nth-child(326) {
  animation: orbit326 14s infinite;
  animation-delay: 3.26s;
  background-color: #ff9f00;
}

@keyframes orbit326 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-360deg) rotateY(161deg) translateX(200px)
      rotateZ(360deg);
  }
  80% {
    transform: rotateZ(-360deg) rotateY(161deg) translateX(200px)
      rotateZ(360deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-360deg) rotateY(161deg) translateX(600px)
      rotateZ(360deg);
  }
}
.c:nth-child(327) {
  animation: orbit327 14s infinite;
  animation-delay: 3.27s;
  background-color: #ffa200;
}

@keyframes orbit327 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-50deg) rotateY(152deg) translateX(200px) rotateZ(50deg);
  }
  80% {
    transform: rotateZ(-50deg) rotateY(152deg) translateX(200px) rotateZ(50deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-50deg) rotateY(152deg) translateX(600px) rotateZ(50deg);
  }
}
.c:nth-child(328) {
  animation: orbit328 14s infinite;
  animation-delay: 3.28s;
  background-color: #ffa400;
}

@keyframes orbit328 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-208deg) rotateY(196deg) translateX(200px)
      rotateZ(208deg);
  }
  80% {
    transform: rotateZ(-208deg) rotateY(196deg) translateX(200px)
      rotateZ(208deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-208deg) rotateY(196deg) translateX(600px)
      rotateZ(208deg);
  }
}
.c:nth-child(329) {
  animation: orbit329 14s infinite;
  animation-delay: 3.29s;
  background-color: #ffa700;
}

@keyframes orbit329 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-330deg) rotateY(135deg) translateX(200px)
      rotateZ(330deg);
  }
  80% {
    transform: rotateZ(-330deg) rotateY(135deg) translateX(200px)
      rotateZ(330deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-330deg) rotateY(135deg) translateX(600px)
      rotateZ(330deg);
  }
}
.c:nth-child(330) {
  animation: orbit330 14s infinite;
  animation-delay: 3.3s;
  background-color: #ffaa00;
}

@keyframes orbit330 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-222deg) rotateY(178deg) translateX(200px)
      rotateZ(222deg);
  }
  80% {
    transform: rotateZ(-222deg) rotateY(178deg) translateX(200px)
      rotateZ(222deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-222deg) rotateY(178deg) translateX(600px)
      rotateZ(222deg);
  }
}
.c:nth-child(331) {
  animation: orbit331 14s infinite;
  animation-delay: 3.31s;
  background-color: #ffad00;
}

@keyframes orbit331 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-352deg) rotateY(266deg) translateX(200px)
      rotateZ(352deg);
  }
  80% {
    transform: rotateZ(-352deg) rotateY(266deg) translateX(200px)
      rotateZ(352deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-352deg) rotateY(266deg) translateX(600px)
      rotateZ(352deg);
  }
}
.c:nth-child(332) {
  animation: orbit332 14s infinite;
  animation-delay: 3.32s;
  background-color: #ffb000;
}

@keyframes orbit332 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-358deg) rotateY(123deg) translateX(200px)
      rotateZ(358deg);
  }
  80% {
    transform: rotateZ(-358deg) rotateY(123deg) translateX(200px)
      rotateZ(358deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-358deg) rotateY(123deg) translateX(600px)
      rotateZ(358deg);
  }
}
.c:nth-child(333) {
  animation: orbit333 14s infinite;
  animation-delay: 3.33s;
  background-color: #ffb300;
}

@keyframes orbit333 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-246deg) rotateY(1deg) translateX(200px) rotateZ(246deg);
  }
  80% {
    transform: rotateZ(-246deg) rotateY(1deg) translateX(200px) rotateZ(246deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-246deg) rotateY(1deg) translateX(600px) rotateZ(246deg);
  }
}
.c:nth-child(334) {
  animation: orbit334 14s infinite;
  animation-delay: 3.34s;
  background-color: #ffb500;
}

@keyframes orbit334 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-265deg) rotateY(77deg) translateX(200px) rotateZ(265deg);
  }
  80% {
    transform: rotateZ(-265deg) rotateY(77deg) translateX(200px) rotateZ(265deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-265deg) rotateY(77deg) translateX(600px) rotateZ(265deg);
  }
}
.c:nth-child(335) {
  animation: orbit335 14s infinite;
  animation-delay: 3.35s;
  background-color: #ffb800;
}

@keyframes orbit335 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-235deg) rotateY(138deg) translateX(200px)
      rotateZ(235deg);
  }
  80% {
    transform: rotateZ(-235deg) rotateY(138deg) translateX(200px)
      rotateZ(235deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-235deg) rotateY(138deg) translateX(600px)
      rotateZ(235deg);
  }
}
.c:nth-child(336) {
  animation: orbit336 14s infinite;
  animation-delay: 3.36s;
  background-color: #ffbb00;
}

@keyframes orbit336 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-283deg) rotateY(31deg) translateX(200px) rotateZ(283deg);
  }
  80% {
    transform: rotateZ(-283deg) rotateY(31deg) translateX(200px) rotateZ(283deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-283deg) rotateY(31deg) translateX(600px) rotateZ(283deg);
  }
}
.c:nth-child(337) {
  animation: orbit337 14s infinite;
  animation-delay: 3.37s;
  background-color: #ffbe00;
}

@keyframes orbit337 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-20deg) rotateY(81deg) translateX(200px) rotateZ(20deg);
  }
  80% {
    transform: rotateZ(-20deg) rotateY(81deg) translateX(200px) rotateZ(20deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-20deg) rotateY(81deg) translateX(600px) rotateZ(20deg);
  }
}
.c:nth-child(338) {
  animation: orbit338 14s infinite;
  animation-delay: 3.38s;
  background-color: #ffc100;
}

@keyframes orbit338 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-157deg) rotateY(111deg) translateX(200px)
      rotateZ(157deg);
  }
  80% {
    transform: rotateZ(-157deg) rotateY(111deg) translateX(200px)
      rotateZ(157deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-157deg) rotateY(111deg) translateX(600px)
      rotateZ(157deg);
  }
}
.c:nth-child(339) {
  animation: orbit339 14s infinite;
  animation-delay: 3.39s;
  background-color: #ffc400;
}

@keyframes orbit339 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-223deg) rotateY(43deg) translateX(200px) rotateZ(223deg);
  }
  80% {
    transform: rotateZ(-223deg) rotateY(43deg) translateX(200px) rotateZ(223deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-223deg) rotateY(43deg) translateX(600px) rotateZ(223deg);
  }
}
.c:nth-child(340) {
  animation: orbit340 14s infinite;
  animation-delay: 3.4s;
  background-color: #ffc600;
}

@keyframes orbit340 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-306deg) rotateY(92deg) translateX(200px) rotateZ(306deg);
  }
  80% {
    transform: rotateZ(-306deg) rotateY(92deg) translateX(200px) rotateZ(306deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-306deg) rotateY(92deg) translateX(600px) rotateZ(306deg);
  }
}
.c:nth-child(341) {
  animation: orbit341 14s infinite;
  animation-delay: 3.41s;
  background-color: #ffc900;
}

@keyframes orbit341 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-152deg) rotateY(1deg) translateX(200px) rotateZ(152deg);
  }
  80% {
    transform: rotateZ(-152deg) rotateY(1deg) translateX(200px) rotateZ(152deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-152deg) rotateY(1deg) translateX(600px) rotateZ(152deg);
  }
}
.c:nth-child(342) {
  animation: orbit342 14s infinite;
  animation-delay: 3.42s;
  background-color: #ffcc00;
}

@keyframes orbit342 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-215deg) rotateY(180deg) translateX(200px)
      rotateZ(215deg);
  }
  80% {
    transform: rotateZ(-215deg) rotateY(180deg) translateX(200px)
      rotateZ(215deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-215deg) rotateY(180deg) translateX(600px)
      rotateZ(215deg);
  }
}
.c:nth-child(343) {
  animation: orbit343 14s infinite;
  animation-delay: 3.43s;
  background-color: #ffcf00;
}

@keyframes orbit343 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-204deg) rotateY(4deg) translateX(200px) rotateZ(204deg);
  }
  80% {
    transform: rotateZ(-204deg) rotateY(4deg) translateX(200px) rotateZ(204deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-204deg) rotateY(4deg) translateX(600px) rotateZ(204deg);
  }
}
.c:nth-child(344) {
  animation: orbit344 14s infinite;
  animation-delay: 3.44s;
  background-color: #ffd200;
}

@keyframes orbit344 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-117deg) rotateY(289deg) translateX(200px)
      rotateZ(117deg);
  }
  80% {
    transform: rotateZ(-117deg) rotateY(289deg) translateX(200px)
      rotateZ(117deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-117deg) rotateY(289deg) translateX(600px)
      rotateZ(117deg);
  }
}
.c:nth-child(345) {
  animation: orbit345 14s infinite;
  animation-delay: 3.45s;
  background-color: #ffd500;
}

@keyframes orbit345 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-130deg) rotateY(218deg) translateX(200px)
      rotateZ(130deg);
  }
  80% {
    transform: rotateZ(-130deg) rotateY(218deg) translateX(200px)
      rotateZ(130deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-130deg) rotateY(218deg) translateX(600px)
      rotateZ(130deg);
  }
}
.c:nth-child(346) {
  animation: orbit346 14s infinite;
  animation-delay: 3.46s;
  background-color: gold;
}

@keyframes orbit346 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-68deg) rotateY(353deg) translateX(200px) rotateZ(68deg);
  }
  80% {
    transform: rotateZ(-68deg) rotateY(353deg) translateX(200px) rotateZ(68deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-68deg) rotateY(353deg) translateX(600px) rotateZ(68deg);
  }
}
.c:nth-child(347) {
  animation: orbit347 14s infinite;
  animation-delay: 3.47s;
  background-color: #ffda00;
}

@keyframes orbit347 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-250deg) rotateY(143deg) translateX(200px)
      rotateZ(250deg);
  }
  80% {
    transform: rotateZ(-250deg) rotateY(143deg) translateX(200px)
      rotateZ(250deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-250deg) rotateY(143deg) translateX(600px)
      rotateZ(250deg);
  }
}
.c:nth-child(348) {
  animation: orbit348 14s infinite;
  animation-delay: 3.48s;
  background-color: #ffdd00;
}

@keyframes orbit348 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-53deg) rotateY(294deg) translateX(200px) rotateZ(53deg);
  }
  80% {
    transform: rotateZ(-53deg) rotateY(294deg) translateX(200px) rotateZ(53deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-53deg) rotateY(294deg) translateX(600px) rotateZ(53deg);
  }
}
.c:nth-child(349) {
  animation: orbit349 14s infinite;
  animation-delay: 3.49s;
  background-color: #ffe000;
}

@keyframes orbit349 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-97deg) rotateY(170deg) translateX(200px) rotateZ(97deg);
  }
  80% {
    transform: rotateZ(-97deg) rotateY(170deg) translateX(200px) rotateZ(97deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-97deg) rotateY(170deg) translateX(600px) rotateZ(97deg);
  }
}
.c:nth-child(350) {
  animation: orbit350 14s infinite;
  animation-delay: 3.5s;
  background-color: #ffe300;
}

@keyframes orbit350 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-43deg) rotateY(305deg) translateX(200px) rotateZ(43deg);
  }
  80% {
    transform: rotateZ(-43deg) rotateY(305deg) translateX(200px) rotateZ(43deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-43deg) rotateY(305deg) translateX(600px) rotateZ(43deg);
  }
}
.c:nth-child(351) {
  animation: orbit351 14s infinite;
  animation-delay: 3.51s;
  background-color: #ffe600;
}

@keyframes orbit351 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-172deg) rotateY(131deg) translateX(200px)
      rotateZ(172deg);
  }
  80% {
    transform: rotateZ(-172deg) rotateY(131deg) translateX(200px)
      rotateZ(172deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-172deg) rotateY(131deg) translateX(600px)
      rotateZ(172deg);
  }
}
.c:nth-child(352) {
  animation: orbit352 14s infinite;
  animation-delay: 3.52s;
  background-color: #ffe800;
}

@keyframes orbit352 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-209deg) rotateY(190deg) translateX(200px)
      rotateZ(209deg);
  }
  80% {
    transform: rotateZ(-209deg) rotateY(190deg) translateX(200px)
      rotateZ(209deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-209deg) rotateY(190deg) translateX(600px)
      rotateZ(209deg);
  }
}
.c:nth-child(353) {
  animation: orbit353 14s infinite;
  animation-delay: 3.53s;
  background-color: #ffeb00;
}

@keyframes orbit353 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-86deg) rotateY(35deg) translateX(200px) rotateZ(86deg);
  }
  80% {
    transform: rotateZ(-86deg) rotateY(35deg) translateX(200px) rotateZ(86deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-86deg) rotateY(35deg) translateX(600px) rotateZ(86deg);
  }
}
.c:nth-child(354) {
  animation: orbit354 14s infinite;
  animation-delay: 3.54s;
  background-color: #ffee00;
}

@keyframes orbit354 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-154deg) rotateY(300deg) translateX(200px)
      rotateZ(154deg);
  }
  80% {
    transform: rotateZ(-154deg) rotateY(300deg) translateX(200px)
      rotateZ(154deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-154deg) rotateY(300deg) translateX(600px)
      rotateZ(154deg);
  }
}
.c:nth-child(355) {
  animation: orbit355 14s infinite;
  animation-delay: 3.55s;
  background-color: #fff100;
}

@keyframes orbit355 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-341deg) rotateY(212deg) translateX(200px)
      rotateZ(341deg);
  }
  80% {
    transform: rotateZ(-341deg) rotateY(212deg) translateX(200px)
      rotateZ(341deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-341deg) rotateY(212deg) translateX(600px)
      rotateZ(341deg);
  }
}
.c:nth-child(356) {
  animation: orbit356 14s infinite;
  animation-delay: 3.56s;
  background-color: #fff400;
}

@keyframes orbit356 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-39deg) rotateY(341deg) translateX(200px) rotateZ(39deg);
  }
  80% {
    transform: rotateZ(-39deg) rotateY(341deg) translateX(200px) rotateZ(39deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-39deg) rotateY(341deg) translateX(600px) rotateZ(39deg);
  }
}
.c:nth-child(357) {
  animation: orbit357 14s infinite;
  animation-delay: 3.57s;
  background-color: #fff700;
}

@keyframes orbit357 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-196deg) rotateY(35deg) translateX(200px) rotateZ(196deg);
  }
  80% {
    transform: rotateZ(-196deg) rotateY(35deg) translateX(200px) rotateZ(196deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-196deg) rotateY(35deg) translateX(600px) rotateZ(196deg);
  }
}
.c:nth-child(358) {
  animation: orbit358 14s infinite;
  animation-delay: 3.58s;
  background-color: #fff900;
}

@keyframes orbit358 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-48deg) rotateY(218deg) translateX(200px) rotateZ(48deg);
  }
  80% {
    transform: rotateZ(-48deg) rotateY(218deg) translateX(200px) rotateZ(48deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-48deg) rotateY(218deg) translateX(600px) rotateZ(48deg);
  }
}
.c:nth-child(359) {
  animation: orbit359 14s infinite;
  animation-delay: 3.59s;
  background-color: #fffc00;
}

@keyframes orbit359 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-355deg) rotateY(179deg) translateX(200px)
      rotateZ(355deg);
  }
  80% {
    transform: rotateZ(-355deg) rotateY(179deg) translateX(200px)
      rotateZ(355deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-355deg) rotateY(179deg) translateX(600px)
      rotateZ(355deg);
  }
}
.c:nth-child(360) {
  animation: orbit360 14s infinite;
  animation-delay: 3.6s;
  background-color: yellow;
}

@keyframes orbit360 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-236deg) rotateY(199deg) translateX(200px)
      rotateZ(236deg);
  }
  80% {
    transform: rotateZ(-236deg) rotateY(199deg) translateX(200px)
      rotateZ(236deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-236deg) rotateY(199deg) translateX(600px)
      rotateZ(236deg);
  }
}
.c:nth-child(361) {
  animation: orbit361 14s infinite;
  animation-delay: 3.61s;
  background-color: #fcff00;
}

@keyframes orbit361 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-207deg) rotateY(203deg) translateX(200px)
      rotateZ(207deg);
  }
  80% {
    transform: rotateZ(-207deg) rotateY(203deg) translateX(200px)
      rotateZ(207deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-207deg) rotateY(203deg) translateX(600px)
      rotateZ(207deg);
  }
}
.c:nth-child(362) {
  animation: orbit362 14s infinite;
  animation-delay: 3.62s;
  background-color: #f9ff00;
}

@keyframes orbit362 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-279deg) rotateY(295deg) translateX(200px)
      rotateZ(279deg);
  }
  80% {
    transform: rotateZ(-279deg) rotateY(295deg) translateX(200px)
      rotateZ(279deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-279deg) rotateY(295deg) translateX(600px)
      rotateZ(279deg);
  }
}
.c:nth-child(363) {
  animation: orbit363 14s infinite;
  animation-delay: 3.63s;
  background-color: #f7ff00;
}

@keyframes orbit363 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-349deg) rotateY(90deg) translateX(200px) rotateZ(349deg);
  }
  80% {
    transform: rotateZ(-349deg) rotateY(90deg) translateX(200px) rotateZ(349deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-349deg) rotateY(90deg) translateX(600px) rotateZ(349deg);
  }
}
.c:nth-child(364) {
  animation: orbit364 14s infinite;
  animation-delay: 3.64s;
  background-color: #f4ff00;
}

@keyframes orbit364 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-194deg) rotateY(333deg) translateX(200px)
      rotateZ(194deg);
  }
  80% {
    transform: rotateZ(-194deg) rotateY(333deg) translateX(200px)
      rotateZ(194deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-194deg) rotateY(333deg) translateX(600px)
      rotateZ(194deg);
  }
}
.c:nth-child(365) {
  animation: orbit365 14s infinite;
  animation-delay: 3.65s;
  background-color: #f1ff00;
}

@keyframes orbit365 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-17deg) rotateY(317deg) translateX(200px) rotateZ(17deg);
  }
  80% {
    transform: rotateZ(-17deg) rotateY(317deg) translateX(200px) rotateZ(17deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-17deg) rotateY(317deg) translateX(600px) rotateZ(17deg);
  }
}
.c:nth-child(366) {
  animation: orbit366 14s infinite;
  animation-delay: 3.66s;
  background-color: #eeff00;
}

@keyframes orbit366 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-295deg) rotateY(270deg) translateX(200px)
      rotateZ(295deg);
  }
  80% {
    transform: rotateZ(-295deg) rotateY(270deg) translateX(200px)
      rotateZ(295deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-295deg) rotateY(270deg) translateX(600px)
      rotateZ(295deg);
  }
}
.c:nth-child(367) {
  animation: orbit367 14s infinite;
  animation-delay: 3.67s;
  background-color: #ebff00;
}

@keyframes orbit367 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-272deg) rotateY(243deg) translateX(200px)
      rotateZ(272deg);
  }
  80% {
    transform: rotateZ(-272deg) rotateY(243deg) translateX(200px)
      rotateZ(272deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-272deg) rotateY(243deg) translateX(600px)
      rotateZ(272deg);
  }
}
.c:nth-child(368) {
  animation: orbit368 14s infinite;
  animation-delay: 3.68s;
  background-color: #e8ff00;
}

@keyframes orbit368 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-52deg) rotateY(155deg) translateX(200px) rotateZ(52deg);
  }
  80% {
    transform: rotateZ(-52deg) rotateY(155deg) translateX(200px) rotateZ(52deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-52deg) rotateY(155deg) translateX(600px) rotateZ(52deg);
  }
}
.c:nth-child(369) {
  animation: orbit369 14s infinite;
  animation-delay: 3.69s;
  background-color: #e6ff00;
}

@keyframes orbit369 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-18deg) rotateY(290deg) translateX(200px) rotateZ(18deg);
  }
  80% {
    transform: rotateZ(-18deg) rotateY(290deg) translateX(200px) rotateZ(18deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-18deg) rotateY(290deg) translateX(600px) rotateZ(18deg);
  }
}
.c:nth-child(370) {
  animation: orbit370 14s infinite;
  animation-delay: 3.7s;
  background-color: #e3ff00;
}

@keyframes orbit370 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-169deg) rotateY(146deg) translateX(200px)
      rotateZ(169deg);
  }
  80% {
    transform: rotateZ(-169deg) rotateY(146deg) translateX(200px)
      rotateZ(169deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-169deg) rotateY(146deg) translateX(600px)
      rotateZ(169deg);
  }
}
.c:nth-child(371) {
  animation: orbit371 14s infinite;
  animation-delay: 3.71s;
  background-color: #e0ff00;
}

@keyframes orbit371 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-99deg) rotateY(323deg) translateX(200px) rotateZ(99deg);
  }
  80% {
    transform: rotateZ(-99deg) rotateY(323deg) translateX(200px) rotateZ(99deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-99deg) rotateY(323deg) translateX(600px) rotateZ(99deg);
  }
}
.c:nth-child(372) {
  animation: orbit372 14s infinite;
  animation-delay: 3.72s;
  background-color: #ddff00;
}

@keyframes orbit372 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-289deg) rotateY(26deg) translateX(200px) rotateZ(289deg);
  }
  80% {
    transform: rotateZ(-289deg) rotateY(26deg) translateX(200px) rotateZ(289deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-289deg) rotateY(26deg) translateX(600px) rotateZ(289deg);
  }
}
.c:nth-child(373) {
  animation: orbit373 14s infinite;
  animation-delay: 3.73s;
  background-color: #daff00;
}

@keyframes orbit373 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-328deg) rotateY(341deg) translateX(200px)
      rotateZ(328deg);
  }
  80% {
    transform: rotateZ(-328deg) rotateY(341deg) translateX(200px)
      rotateZ(328deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-328deg) rotateY(341deg) translateX(600px)
      rotateZ(328deg);
  }
}
.c:nth-child(374) {
  animation: orbit374 14s infinite;
  animation-delay: 3.74s;
  background-color: #d7ff00;
}

@keyframes orbit374 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-276deg) rotateY(306deg) translateX(200px)
      rotateZ(276deg);
  }
  80% {
    transform: rotateZ(-276deg) rotateY(306deg) translateX(200px)
      rotateZ(276deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-276deg) rotateY(306deg) translateX(600px)
      rotateZ(276deg);
  }
}
.c:nth-child(375) {
  animation: orbit375 14s infinite;
  animation-delay: 3.75s;
  background-color: #d5ff00;
}

@keyframes orbit375 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-166deg) rotateY(40deg) translateX(200px) rotateZ(166deg);
  }
  80% {
    transform: rotateZ(-166deg) rotateY(40deg) translateX(200px) rotateZ(166deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-166deg) rotateY(40deg) translateX(600px) rotateZ(166deg);
  }
}
.c:nth-child(376) {
  animation: orbit376 14s infinite;
  animation-delay: 3.76s;
  background-color: #d2ff00;
}

@keyframes orbit376 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-269deg) rotateY(154deg) translateX(200px)
      rotateZ(269deg);
  }
  80% {
    transform: rotateZ(-269deg) rotateY(154deg) translateX(200px)
      rotateZ(269deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-269deg) rotateY(154deg) translateX(600px)
      rotateZ(269deg);
  }
}
.c:nth-child(377) {
  animation: orbit377 14s infinite;
  animation-delay: 3.77s;
  background-color: #cfff00;
}

@keyframes orbit377 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-243deg) rotateY(255deg) translateX(200px)
      rotateZ(243deg);
  }
  80% {
    transform: rotateZ(-243deg) rotateY(255deg) translateX(200px)
      rotateZ(243deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-243deg) rotateY(255deg) translateX(600px)
      rotateZ(243deg);
  }
}
.c:nth-child(378) {
  animation: orbit378 14s infinite;
  animation-delay: 3.78s;
  background-color: #ccff00;
}

@keyframes orbit378 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-203deg) rotateY(190deg) translateX(200px)
      rotateZ(203deg);
  }
  80% {
    transform: rotateZ(-203deg) rotateY(190deg) translateX(200px)
      rotateZ(203deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-203deg) rotateY(190deg) translateX(600px)
      rotateZ(203deg);
  }
}
.c:nth-child(379) {
  animation: orbit379 14s infinite;
  animation-delay: 3.79s;
  background-color: #c9ff00;
}

@keyframes orbit379 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-237deg) rotateY(37deg) translateX(200px) rotateZ(237deg);
  }
  80% {
    transform: rotateZ(-237deg) rotateY(37deg) translateX(200px) rotateZ(237deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-237deg) rotateY(37deg) translateX(600px) rotateZ(237deg);
  }
}
.c:nth-child(380) {
  animation: orbit380 14s infinite;
  animation-delay: 3.8s;
  background-color: #c6ff00;
}

@keyframes orbit380 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-213deg) rotateY(211deg) translateX(200px)
      rotateZ(213deg);
  }
  80% {
    transform: rotateZ(-213deg) rotateY(211deg) translateX(200px)
      rotateZ(213deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-213deg) rotateY(211deg) translateX(600px)
      rotateZ(213deg);
  }
}
.c:nth-child(381) {
  animation: orbit381 14s infinite;
  animation-delay: 3.81s;
  background-color: #c4ff00;
}

@keyframes orbit381 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-142deg) rotateY(20deg) translateX(200px) rotateZ(142deg);
  }
  80% {
    transform: rotateZ(-142deg) rotateY(20deg) translateX(200px) rotateZ(142deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-142deg) rotateY(20deg) translateX(600px) rotateZ(142deg);
  }
}
.c:nth-child(382) {
  animation: orbit382 14s infinite;
  animation-delay: 3.82s;
  background-color: #c1ff00;
}

@keyframes orbit382 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-185deg) rotateY(351deg) translateX(200px)
      rotateZ(185deg);
  }
  80% {
    transform: rotateZ(-185deg) rotateY(351deg) translateX(200px)
      rotateZ(185deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-185deg) rotateY(351deg) translateX(600px)
      rotateZ(185deg);
  }
}
.c:nth-child(383) {
  animation: orbit383 14s infinite;
  animation-delay: 3.83s;
  background-color: #beff00;
}

@keyframes orbit383 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-159deg) rotateY(284deg) translateX(200px)
      rotateZ(159deg);
  }
  80% {
    transform: rotateZ(-159deg) rotateY(284deg) translateX(200px)
      rotateZ(159deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-159deg) rotateY(284deg) translateX(600px)
      rotateZ(159deg);
  }
}
.c:nth-child(384) {
  animation: orbit384 14s infinite;
  animation-delay: 3.84s;
  background-color: #bbff00;
}

@keyframes orbit384 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-356deg) rotateY(146deg) translateX(200px)
      rotateZ(356deg);
  }
  80% {
    transform: rotateZ(-356deg) rotateY(146deg) translateX(200px)
      rotateZ(356deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-356deg) rotateY(146deg) translateX(600px)
      rotateZ(356deg);
  }
}
.c:nth-child(385) {
  animation: orbit385 14s infinite;
  animation-delay: 3.85s;
  background-color: #b8ff00;
}

@keyframes orbit385 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-276deg) rotateY(191deg) translateX(200px)
      rotateZ(276deg);
  }
  80% {
    transform: rotateZ(-276deg) rotateY(191deg) translateX(200px)
      rotateZ(276deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-276deg) rotateY(191deg) translateX(600px)
      rotateZ(276deg);
  }
}
.c:nth-child(386) {
  animation: orbit386 14s infinite;
  animation-delay: 3.86s;
  background-color: #b5ff00;
}

@keyframes orbit386 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-269deg) rotateY(35deg) translateX(200px) rotateZ(269deg);
  }
  80% {
    transform: rotateZ(-269deg) rotateY(35deg) translateX(200px) rotateZ(269deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-269deg) rotateY(35deg) translateX(600px) rotateZ(269deg);
  }
}
.c:nth-child(387) {
  animation: orbit387 14s infinite;
  animation-delay: 3.87s;
  background-color: #b3ff00;
}

@keyframes orbit387 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-142deg) rotateY(174deg) translateX(200px)
      rotateZ(142deg);
  }
  80% {
    transform: rotateZ(-142deg) rotateY(174deg) translateX(200px)
      rotateZ(142deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-142deg) rotateY(174deg) translateX(600px)
      rotateZ(142deg);
  }
}
.c:nth-child(388) {
  animation: orbit388 14s infinite;
  animation-delay: 3.88s;
  background-color: #b0ff00;
}

@keyframes orbit388 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-93deg) rotateY(74deg) translateX(200px) rotateZ(93deg);
  }
  80% {
    transform: rotateZ(-93deg) rotateY(74deg) translateX(200px) rotateZ(93deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-93deg) rotateY(74deg) translateX(600px) rotateZ(93deg);
  }
}
.c:nth-child(389) {
  animation: orbit389 14s infinite;
  animation-delay: 3.89s;
  background-color: #adff00;
}

@keyframes orbit389 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-47deg) rotateY(88deg) translateX(200px) rotateZ(47deg);
  }
  80% {
    transform: rotateZ(-47deg) rotateY(88deg) translateX(200px) rotateZ(47deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-47deg) rotateY(88deg) translateX(600px) rotateZ(47deg);
  }
}
.c:nth-child(390) {
  animation: orbit390 14s infinite;
  animation-delay: 3.9s;
  background-color: #aaff00;
}

@keyframes orbit390 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-156deg) rotateY(128deg) translateX(200px)
      rotateZ(156deg);
  }
  80% {
    transform: rotateZ(-156deg) rotateY(128deg) translateX(200px)
      rotateZ(156deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-156deg) rotateY(128deg) translateX(600px)
      rotateZ(156deg);
  }
}
.c:nth-child(391) {
  animation: orbit391 14s infinite;
  animation-delay: 3.91s;
  background-color: #a7ff00;
}

@keyframes orbit391 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-148deg) rotateY(48deg) translateX(200px) rotateZ(148deg);
  }
  80% {
    transform: rotateZ(-148deg) rotateY(48deg) translateX(200px) rotateZ(148deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-148deg) rotateY(48deg) translateX(600px) rotateZ(148deg);
  }
}
.c:nth-child(392) {
  animation: orbit392 14s infinite;
  animation-delay: 3.92s;
  background-color: #a4ff00;
}

@keyframes orbit392 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-289deg) rotateY(4deg) translateX(200px) rotateZ(289deg);
  }
  80% {
    transform: rotateZ(-289deg) rotateY(4deg) translateX(200px) rotateZ(289deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-289deg) rotateY(4deg) translateX(600px) rotateZ(289deg);
  }
}
.c:nth-child(393) {
  animation: orbit393 14s infinite;
  animation-delay: 3.93s;
  background-color: #a2ff00;
}

@keyframes orbit393 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-180deg) rotateY(53deg) translateX(200px) rotateZ(180deg);
  }
  80% {
    transform: rotateZ(-180deg) rotateY(53deg) translateX(200px) rotateZ(180deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-180deg) rotateY(53deg) translateX(600px) rotateZ(180deg);
  }
}
.c:nth-child(394) {
  animation: orbit394 14s infinite;
  animation-delay: 3.94s;
  background-color: #9fff00;
}

@keyframes orbit394 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-17deg) rotateY(55deg) translateX(200px) rotateZ(17deg);
  }
  80% {
    transform: rotateZ(-17deg) rotateY(55deg) translateX(200px) rotateZ(17deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-17deg) rotateY(55deg) translateX(600px) rotateZ(17deg);
  }
}
.c:nth-child(395) {
  animation: orbit395 14s infinite;
  animation-delay: 3.95s;
  background-color: #9cff00;
}

@keyframes orbit395 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-28deg) rotateY(64deg) translateX(200px) rotateZ(28deg);
  }
  80% {
    transform: rotateZ(-28deg) rotateY(64deg) translateX(200px) rotateZ(28deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-28deg) rotateY(64deg) translateX(600px) rotateZ(28deg);
  }
}
.c:nth-child(396) {
  animation: orbit396 14s infinite;
  animation-delay: 3.96s;
  background-color: #99ff00;
}

@keyframes orbit396 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-134deg) rotateY(130deg) translateX(200px)
      rotateZ(134deg);
  }
  80% {
    transform: rotateZ(-134deg) rotateY(130deg) translateX(200px)
      rotateZ(134deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-134deg) rotateY(130deg) translateX(600px)
      rotateZ(134deg);
  }
}
.c:nth-child(397) {
  animation: orbit397 14s infinite;
  animation-delay: 3.97s;
  background-color: #96ff00;
}

@keyframes orbit397 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-89deg) rotateY(258deg) translateX(200px) rotateZ(89deg);
  }
  80% {
    transform: rotateZ(-89deg) rotateY(258deg) translateX(200px) rotateZ(89deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-89deg) rotateY(258deg) translateX(600px) rotateZ(89deg);
  }
}
.c:nth-child(398) {
  animation: orbit398 14s infinite;
  animation-delay: 3.98s;
  background-color: #93ff00;
}

@keyframes orbit398 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-52deg) rotateY(42deg) translateX(200px) rotateZ(52deg);
  }
  80% {
    transform: rotateZ(-52deg) rotateY(42deg) translateX(200px) rotateZ(52deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-52deg) rotateY(42deg) translateX(600px) rotateZ(52deg);
  }
}
.c:nth-child(399) {
  animation: orbit399 14s infinite;
  animation-delay: 3.99s;
  background-color: #91ff00;
}

@keyframes orbit399 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-290deg) rotateY(311deg) translateX(200px)
      rotateZ(290deg);
  }
  80% {
    transform: rotateZ(-290deg) rotateY(311deg) translateX(200px)
      rotateZ(290deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-290deg) rotateY(311deg) translateX(600px)
      rotateZ(290deg);
  }
}
.c:nth-child(400) {
  animation: orbit400 14s infinite;
  animation-delay: 4s;
  background-color: #8eff00;
}

@keyframes orbit400 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-175deg) rotateY(208deg) translateX(200px)
      rotateZ(175deg);
  }
  80% {
    transform: rotateZ(-175deg) rotateY(208deg) translateX(200px)
      rotateZ(175deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-175deg) rotateY(208deg) translateX(600px)
      rotateZ(175deg);
  }
}
.c:nth-child(401) {
  animation: orbit401 14s infinite;
  animation-delay: 4.01s;
  background-color: #8bff00;
}

@keyframes orbit401 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-237deg) rotateY(301deg) translateX(200px)
      rotateZ(237deg);
  }
  80% {
    transform: rotateZ(-237deg) rotateY(301deg) translateX(200px)
      rotateZ(237deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-237deg) rotateY(301deg) translateX(600px)
      rotateZ(237deg);
  }
}
.c:nth-child(402) {
  animation: orbit402 14s infinite;
  animation-delay: 4.02s;
  background-color: #88ff00;
}

@keyframes orbit402 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-230deg) rotateY(339deg) translateX(200px)
      rotateZ(230deg);
  }
  80% {
    transform: rotateZ(-230deg) rotateY(339deg) translateX(200px)
      rotateZ(230deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-230deg) rotateY(339deg) translateX(600px)
      rotateZ(230deg);
  }
}
.c:nth-child(403) {
  animation: orbit403 14s infinite;
  animation-delay: 4.03s;
  background-color: #85ff00;
}

@keyframes orbit403 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-253deg) rotateY(7deg) translateX(200px) rotateZ(253deg);
  }
  80% {
    transform: rotateZ(-253deg) rotateY(7deg) translateX(200px) rotateZ(253deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-253deg) rotateY(7deg) translateX(600px) rotateZ(253deg);
  }
}
.c:nth-child(404) {
  animation: orbit404 14s infinite;
  animation-delay: 4.04s;
  background-color: #82ff00;
}

@keyframes orbit404 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-220deg) rotateY(120deg) translateX(200px)
      rotateZ(220deg);
  }
  80% {
    transform: rotateZ(-220deg) rotateY(120deg) translateX(200px)
      rotateZ(220deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-220deg) rotateY(120deg) translateX(600px)
      rotateZ(220deg);
  }
}
.c:nth-child(405) {
  animation: orbit405 14s infinite;
  animation-delay: 4.05s;
  background-color: #80ff00;
}

@keyframes orbit405 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-339deg) rotateY(94deg) translateX(200px) rotateZ(339deg);
  }
  80% {
    transform: rotateZ(-339deg) rotateY(94deg) translateX(200px) rotateZ(339deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-339deg) rotateY(94deg) translateX(600px) rotateZ(339deg);
  }
}
.c:nth-child(406) {
  animation: orbit406 14s infinite;
  animation-delay: 4.06s;
  background-color: #7dff00;
}

@keyframes orbit406 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-143deg) rotateY(52deg) translateX(200px) rotateZ(143deg);
  }
  80% {
    transform: rotateZ(-143deg) rotateY(52deg) translateX(200px) rotateZ(143deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-143deg) rotateY(52deg) translateX(600px) rotateZ(143deg);
  }
}
.c:nth-child(407) {
  animation: orbit407 14s infinite;
  animation-delay: 4.07s;
  background-color: #7aff00;
}

@keyframes orbit407 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-34deg) rotateY(215deg) translateX(200px) rotateZ(34deg);
  }
  80% {
    transform: rotateZ(-34deg) rotateY(215deg) translateX(200px) rotateZ(34deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-34deg) rotateY(215deg) translateX(600px) rotateZ(34deg);
  }
}
.c:nth-child(408) {
  animation: orbit408 14s infinite;
  animation-delay: 4.08s;
  background-color: #77ff00;
}

@keyframes orbit408 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-84deg) rotateY(140deg) translateX(200px) rotateZ(84deg);
  }
  80% {
    transform: rotateZ(-84deg) rotateY(140deg) translateX(200px) rotateZ(84deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-84deg) rotateY(140deg) translateX(600px) rotateZ(84deg);
  }
}
.c:nth-child(409) {
  animation: orbit409 14s infinite;
  animation-delay: 4.09s;
  background-color: #74ff00;
}

@keyframes orbit409 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-115deg) rotateY(43deg) translateX(200px) rotateZ(115deg);
  }
  80% {
    transform: rotateZ(-115deg) rotateY(43deg) translateX(200px) rotateZ(115deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-115deg) rotateY(43deg) translateX(600px) rotateZ(115deg);
  }
}
.c:nth-child(410) {
  animation: orbit410 14s infinite;
  animation-delay: 4.1s;
  background-color: #71ff00;
}

@keyframes orbit410 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-223deg) rotateY(74deg) translateX(200px) rotateZ(223deg);
  }
  80% {
    transform: rotateZ(-223deg) rotateY(74deg) translateX(200px) rotateZ(223deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-223deg) rotateY(74deg) translateX(600px) rotateZ(223deg);
  }
}
.c:nth-child(411) {
  animation: orbit411 14s infinite;
  animation-delay: 4.11s;
  background-color: #6fff00;
}

@keyframes orbit411 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-343deg) rotateY(331deg) translateX(200px)
      rotateZ(343deg);
  }
  80% {
    transform: rotateZ(-343deg) rotateY(331deg) translateX(200px)
      rotateZ(343deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-343deg) rotateY(331deg) translateX(600px)
      rotateZ(343deg);
  }
}
.c:nth-child(412) {
  animation: orbit412 14s infinite;
  animation-delay: 4.12s;
  background-color: #6cff00;
}

@keyframes orbit412 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-83deg) rotateY(235deg) translateX(200px) rotateZ(83deg);
  }
  80% {
    transform: rotateZ(-83deg) rotateY(235deg) translateX(200px) rotateZ(83deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-83deg) rotateY(235deg) translateX(600px) rotateZ(83deg);
  }
}
.c:nth-child(413) {
  animation: orbit413 14s infinite;
  animation-delay: 4.13s;
  background-color: #69ff00;
}

@keyframes orbit413 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-92deg) rotateY(7deg) translateX(200px) rotateZ(92deg);
  }
  80% {
    transform: rotateZ(-92deg) rotateY(7deg) translateX(200px) rotateZ(92deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-92deg) rotateY(7deg) translateX(600px) rotateZ(92deg);
  }
}
.c:nth-child(414) {
  animation: orbit414 14s infinite;
  animation-delay: 4.14s;
  background-color: #66ff00;
}

@keyframes orbit414 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-345deg) rotateY(105deg) translateX(200px)
      rotateZ(345deg);
  }
  80% {
    transform: rotateZ(-345deg) rotateY(105deg) translateX(200px)
      rotateZ(345deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-345deg) rotateY(105deg) translateX(600px)
      rotateZ(345deg);
  }
}
.c:nth-child(415) {
  animation: orbit415 14s infinite;
  animation-delay: 4.15s;
  background-color: #63ff00;
}

@keyframes orbit415 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-270deg) rotateY(310deg) translateX(200px)
      rotateZ(270deg);
  }
  80% {
    transform: rotateZ(-270deg) rotateY(310deg) translateX(200px)
      rotateZ(270deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-270deg) rotateY(310deg) translateX(600px)
      rotateZ(270deg);
  }
}
.c:nth-child(416) {
  animation: orbit416 14s infinite;
  animation-delay: 4.16s;
  background-color: #60ff00;
}

@keyframes orbit416 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-129deg) rotateY(198deg) translateX(200px)
      rotateZ(129deg);
  }
  80% {
    transform: rotateZ(-129deg) rotateY(198deg) translateX(200px)
      rotateZ(129deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-129deg) rotateY(198deg) translateX(600px)
      rotateZ(129deg);
  }
}
.c:nth-child(417) {
  animation: orbit417 14s infinite;
  animation-delay: 4.17s;
  background-color: #5eff00;
}

@keyframes orbit417 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-342deg) rotateY(265deg) translateX(200px)
      rotateZ(342deg);
  }
  80% {
    transform: rotateZ(-342deg) rotateY(265deg) translateX(200px)
      rotateZ(342deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-342deg) rotateY(265deg) translateX(600px)
      rotateZ(342deg);
  }
}
.c:nth-child(418) {
  animation: orbit418 14s infinite;
  animation-delay: 4.18s;
  background-color: #5bff00;
}

@keyframes orbit418 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-178deg) rotateY(214deg) translateX(200px)
      rotateZ(178deg);
  }
  80% {
    transform: rotateZ(-178deg) rotateY(214deg) translateX(200px)
      rotateZ(178deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-178deg) rotateY(214deg) translateX(600px)
      rotateZ(178deg);
  }
}
.c:nth-child(419) {
  animation: orbit419 14s infinite;
  animation-delay: 4.19s;
  background-color: #58ff00;
}

@keyframes orbit419 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-293deg) rotateY(242deg) translateX(200px)
      rotateZ(293deg);
  }
  80% {
    transform: rotateZ(-293deg) rotateY(242deg) translateX(200px)
      rotateZ(293deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-293deg) rotateY(242deg) translateX(600px)
      rotateZ(293deg);
  }
}
.c:nth-child(420) {
  animation: orbit420 14s infinite;
  animation-delay: 4.2s;
  background-color: #55ff00;
}

@keyframes orbit420 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-293deg) rotateY(256deg) translateX(200px)
      rotateZ(293deg);
  }
  80% {
    transform: rotateZ(-293deg) rotateY(256deg) translateX(200px)
      rotateZ(293deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-293deg) rotateY(256deg) translateX(600px)
      rotateZ(293deg);
  }
}
.c:nth-child(421) {
  animation: orbit421 14s infinite;
  animation-delay: 4.21s;
  background-color: #52ff00;
}

@keyframes orbit421 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-105deg) rotateY(260deg) translateX(200px)
      rotateZ(105deg);
  }
  80% {
    transform: rotateZ(-105deg) rotateY(260deg) translateX(200px)
      rotateZ(105deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-105deg) rotateY(260deg) translateX(600px)
      rotateZ(105deg);
  }
}
.c:nth-child(422) {
  animation: orbit422 14s infinite;
  animation-delay: 4.22s;
  background-color: #4fff00;
}

@keyframes orbit422 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-243deg) rotateY(324deg) translateX(200px)
      rotateZ(243deg);
  }
  80% {
    transform: rotateZ(-243deg) rotateY(324deg) translateX(200px)
      rotateZ(243deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-243deg) rotateY(324deg) translateX(600px)
      rotateZ(243deg);
  }
}
.c:nth-child(423) {
  animation: orbit423 14s infinite;
  animation-delay: 4.23s;
  background-color: #4dff00;
}

@keyframes orbit423 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-359deg) rotateY(44deg) translateX(200px) rotateZ(359deg);
  }
  80% {
    transform: rotateZ(-359deg) rotateY(44deg) translateX(200px) rotateZ(359deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-359deg) rotateY(44deg) translateX(600px) rotateZ(359deg);
  }
}
.c:nth-child(424) {
  animation: orbit424 14s infinite;
  animation-delay: 4.24s;
  background-color: #4aff00;
}

@keyframes orbit424 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-29deg) rotateY(275deg) translateX(200px) rotateZ(29deg);
  }
  80% {
    transform: rotateZ(-29deg) rotateY(275deg) translateX(200px) rotateZ(29deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-29deg) rotateY(275deg) translateX(600px) rotateZ(29deg);
  }
}
.c:nth-child(425) {
  animation: orbit425 14s infinite;
  animation-delay: 4.25s;
  background-color: #47ff00;
}

@keyframes orbit425 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-96deg) rotateY(38deg) translateX(200px) rotateZ(96deg);
  }
  80% {
    transform: rotateZ(-96deg) rotateY(38deg) translateX(200px) rotateZ(96deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-96deg) rotateY(38deg) translateX(600px) rotateZ(96deg);
  }
}
.c:nth-child(426) {
  animation: orbit426 14s infinite;
  animation-delay: 4.26s;
  background-color: #44ff00;
}

@keyframes orbit426 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-50deg) rotateY(313deg) translateX(200px) rotateZ(50deg);
  }
  80% {
    transform: rotateZ(-50deg) rotateY(313deg) translateX(200px) rotateZ(50deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-50deg) rotateY(313deg) translateX(600px) rotateZ(50deg);
  }
}
.c:nth-child(427) {
  animation: orbit427 14s infinite;
  animation-delay: 4.27s;
  background-color: #41ff00;
}

@keyframes orbit427 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-233deg) rotateY(43deg) translateX(200px) rotateZ(233deg);
  }
  80% {
    transform: rotateZ(-233deg) rotateY(43deg) translateX(200px) rotateZ(233deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-233deg) rotateY(43deg) translateX(600px) rotateZ(233deg);
  }
}
.c:nth-child(428) {
  animation: orbit428 14s infinite;
  animation-delay: 4.28s;
  background-color: #3eff00;
}

@keyframes orbit428 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-59deg) rotateY(335deg) translateX(200px) rotateZ(59deg);
  }
  80% {
    transform: rotateZ(-59deg) rotateY(335deg) translateX(200px) rotateZ(59deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-59deg) rotateY(335deg) translateX(600px) rotateZ(59deg);
  }
}
.c:nth-child(429) {
  animation: orbit429 14s infinite;
  animation-delay: 4.29s;
  background-color: #3cff00;
}

@keyframes orbit429 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-182deg) rotateY(101deg) translateX(200px)
      rotateZ(182deg);
  }
  80% {
    transform: rotateZ(-182deg) rotateY(101deg) translateX(200px)
      rotateZ(182deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-182deg) rotateY(101deg) translateX(600px)
      rotateZ(182deg);
  }
}
.c:nth-child(430) {
  animation: orbit430 14s infinite;
  animation-delay: 4.3s;
  background-color: #39ff00;
}

@keyframes orbit430 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-73deg) rotateY(163deg) translateX(200px) rotateZ(73deg);
  }
  80% {
    transform: rotateZ(-73deg) rotateY(163deg) translateX(200px) rotateZ(73deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-73deg) rotateY(163deg) translateX(600px) rotateZ(73deg);
  }
}
.c:nth-child(431) {
  animation: orbit431 14s infinite;
  animation-delay: 4.31s;
  background-color: #36ff00;
}

@keyframes orbit431 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-193deg) rotateY(39deg) translateX(200px) rotateZ(193deg);
  }
  80% {
    transform: rotateZ(-193deg) rotateY(39deg) translateX(200px) rotateZ(193deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-193deg) rotateY(39deg) translateX(600px) rotateZ(193deg);
  }
}
.c:nth-child(432) {
  animation: orbit432 14s infinite;
  animation-delay: 4.32s;
  background-color: #33ff00;
}

@keyframes orbit432 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-219deg) rotateY(347deg) translateX(200px)
      rotateZ(219deg);
  }
  80% {
    transform: rotateZ(-219deg) rotateY(347deg) translateX(200px)
      rotateZ(219deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-219deg) rotateY(347deg) translateX(600px)
      rotateZ(219deg);
  }
}
.c:nth-child(433) {
  animation: orbit433 14s infinite;
  animation-delay: 4.33s;
  background-color: #30ff00;
}

@keyframes orbit433 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-41deg) rotateY(257deg) translateX(200px) rotateZ(41deg);
  }
  80% {
    transform: rotateZ(-41deg) rotateY(257deg) translateX(200px) rotateZ(41deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-41deg) rotateY(257deg) translateX(600px) rotateZ(41deg);
  }
}
.c:nth-child(434) {
  animation: orbit434 14s infinite;
  animation-delay: 4.34s;
  background-color: #2dff00;
}

@keyframes orbit434 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-317deg) rotateY(93deg) translateX(200px) rotateZ(317deg);
  }
  80% {
    transform: rotateZ(-317deg) rotateY(93deg) translateX(200px) rotateZ(317deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-317deg) rotateY(93deg) translateX(600px) rotateZ(317deg);
  }
}
.c:nth-child(435) {
  animation: orbit435 14s infinite;
  animation-delay: 4.35s;
  background-color: #2bff00;
}

@keyframes orbit435 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-154deg) rotateY(87deg) translateX(200px) rotateZ(154deg);
  }
  80% {
    transform: rotateZ(-154deg) rotateY(87deg) translateX(200px) rotateZ(154deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-154deg) rotateY(87deg) translateX(600px) rotateZ(154deg);
  }
}
.c:nth-child(436) {
  animation: orbit436 14s infinite;
  animation-delay: 4.36s;
  background-color: #28ff00;
}

@keyframes orbit436 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-16deg) rotateY(64deg) translateX(200px) rotateZ(16deg);
  }
  80% {
    transform: rotateZ(-16deg) rotateY(64deg) translateX(200px) rotateZ(16deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-16deg) rotateY(64deg) translateX(600px) rotateZ(16deg);
  }
}
.c:nth-child(437) {
  animation: orbit437 14s infinite;
  animation-delay: 4.37s;
  background-color: #25ff00;
}

@keyframes orbit437 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-11deg) rotateY(142deg) translateX(200px) rotateZ(11deg);
  }
  80% {
    transform: rotateZ(-11deg) rotateY(142deg) translateX(200px) rotateZ(11deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-11deg) rotateY(142deg) translateX(600px) rotateZ(11deg);
  }
}
.c:nth-child(438) {
  animation: orbit438 14s infinite;
  animation-delay: 4.38s;
  background-color: #22ff00;
}

@keyframes orbit438 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-152deg) rotateY(192deg) translateX(200px)
      rotateZ(152deg);
  }
  80% {
    transform: rotateZ(-152deg) rotateY(192deg) translateX(200px)
      rotateZ(152deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-152deg) rotateY(192deg) translateX(600px)
      rotateZ(152deg);
  }
}
.c:nth-child(439) {
  animation: orbit439 14s infinite;
  animation-delay: 4.39s;
  background-color: #1fff00;
}

@keyframes orbit439 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-14deg) rotateY(253deg) translateX(200px) rotateZ(14deg);
  }
  80% {
    transform: rotateZ(-14deg) rotateY(253deg) translateX(200px) rotateZ(14deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-14deg) rotateY(253deg) translateX(600px) rotateZ(14deg);
  }
}
.c:nth-child(440) {
  animation: orbit440 14s infinite;
  animation-delay: 4.4s;
  background-color: #1cff00;
}

@keyframes orbit440 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-187deg) rotateY(189deg) translateX(200px)
      rotateZ(187deg);
  }
  80% {
    transform: rotateZ(-187deg) rotateY(189deg) translateX(200px)
      rotateZ(187deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-187deg) rotateY(189deg) translateX(600px)
      rotateZ(187deg);
  }
}
.c:nth-child(441) {
  animation: orbit441 14s infinite;
  animation-delay: 4.41s;
  background-color: #1aff00;
}

@keyframes orbit441 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-92deg) rotateY(120deg) translateX(200px) rotateZ(92deg);
  }
  80% {
    transform: rotateZ(-92deg) rotateY(120deg) translateX(200px) rotateZ(92deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-92deg) rotateY(120deg) translateX(600px) rotateZ(92deg);
  }
}
.c:nth-child(442) {
  animation: orbit442 14s infinite;
  animation-delay: 4.42s;
  background-color: #17ff00;
}

@keyframes orbit442 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-349deg) rotateY(201deg) translateX(200px)
      rotateZ(349deg);
  }
  80% {
    transform: rotateZ(-349deg) rotateY(201deg) translateX(200px)
      rotateZ(349deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-349deg) rotateY(201deg) translateX(600px)
      rotateZ(349deg);
  }
}
.c:nth-child(443) {
  animation: orbit443 14s infinite;
  animation-delay: 4.43s;
  background-color: #14ff00;
}

@keyframes orbit443 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-142deg) rotateY(139deg) translateX(200px)
      rotateZ(142deg);
  }
  80% {
    transform: rotateZ(-142deg) rotateY(139deg) translateX(200px)
      rotateZ(142deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-142deg) rotateY(139deg) translateX(600px)
      rotateZ(142deg);
  }
}
.c:nth-child(444) {
  animation: orbit444 14s infinite;
  animation-delay: 4.44s;
  background-color: #11ff00;
}

@keyframes orbit444 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-171deg) rotateY(151deg) translateX(200px)
      rotateZ(171deg);
  }
  80% {
    transform: rotateZ(-171deg) rotateY(151deg) translateX(200px)
      rotateZ(171deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-171deg) rotateY(151deg) translateX(600px)
      rotateZ(171deg);
  }
}
.c:nth-child(445) {
  animation: orbit445 14s infinite;
  animation-delay: 4.45s;
  background-color: #0eff00;
}

@keyframes orbit445 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-304deg) rotateY(260deg) translateX(200px)
      rotateZ(304deg);
  }
  80% {
    transform: rotateZ(-304deg) rotateY(260deg) translateX(200px)
      rotateZ(304deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-304deg) rotateY(260deg) translateX(600px)
      rotateZ(304deg);
  }
}
.c:nth-child(446) {
  animation: orbit446 14s infinite;
  animation-delay: 4.46s;
  background-color: #0bff00;
}

@keyframes orbit446 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-265deg) rotateY(101deg) translateX(200px)
      rotateZ(265deg);
  }
  80% {
    transform: rotateZ(-265deg) rotateY(101deg) translateX(200px)
      rotateZ(265deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-265deg) rotateY(101deg) translateX(600px)
      rotateZ(265deg);
  }
}
.c:nth-child(447) {
  animation: orbit447 14s infinite;
  animation-delay: 4.47s;
  background-color: #09ff00;
}

@keyframes orbit447 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-195deg) rotateY(221deg) translateX(200px)
      rotateZ(195deg);
  }
  80% {
    transform: rotateZ(-195deg) rotateY(221deg) translateX(200px)
      rotateZ(195deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-195deg) rotateY(221deg) translateX(600px)
      rotateZ(195deg);
  }
}
.c:nth-child(448) {
  animation: orbit448 14s infinite;
  animation-delay: 4.48s;
  background-color: #06ff00;
}

@keyframes orbit448 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-358deg) rotateY(259deg) translateX(200px)
      rotateZ(358deg);
  }
  80% {
    transform: rotateZ(-358deg) rotateY(259deg) translateX(200px)
      rotateZ(358deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-358deg) rotateY(259deg) translateX(600px)
      rotateZ(358deg);
  }
}
.c:nth-child(449) {
  animation: orbit449 14s infinite;
  animation-delay: 4.49s;
  background-color: #03ff00;
}

@keyframes orbit449 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-318deg) rotateY(228deg) translateX(200px)
      rotateZ(318deg);
  }
  80% {
    transform: rotateZ(-318deg) rotateY(228deg) translateX(200px)
      rotateZ(318deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-318deg) rotateY(228deg) translateX(600px)
      rotateZ(318deg);
  }
}
.c:nth-child(450) {
  animation: orbit450 14s infinite;
  animation-delay: 4.5s;
  background-color: lime;
}

@keyframes orbit450 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-314deg) rotateY(134deg) translateX(200px)
      rotateZ(314deg);
  }
  80% {
    transform: rotateZ(-314deg) rotateY(134deg) translateX(200px)
      rotateZ(314deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-314deg) rotateY(134deg) translateX(600px)
      rotateZ(314deg);
  }
}
.c:nth-child(451) {
  animation: orbit451 14s infinite;
  animation-delay: 4.51s;
  background-color: #00ff03;
}

@keyframes orbit451 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-68deg) rotateY(300deg) translateX(200px) rotateZ(68deg);
  }
  80% {
    transform: rotateZ(-68deg) rotateY(300deg) translateX(200px) rotateZ(68deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-68deg) rotateY(300deg) translateX(600px) rotateZ(68deg);
  }
}
.c:nth-child(452) {
  animation: orbit452 14s infinite;
  animation-delay: 4.52s;
  background-color: #00ff06;
}

@keyframes orbit452 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-235deg) rotateY(71deg) translateX(200px) rotateZ(235deg);
  }
  80% {
    transform: rotateZ(-235deg) rotateY(71deg) translateX(200px) rotateZ(235deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-235deg) rotateY(71deg) translateX(600px) rotateZ(235deg);
  }
}
.c:nth-child(453) {
  animation: orbit453 14s infinite;
  animation-delay: 4.53s;
  background-color: #00ff09;
}

@keyframes orbit453 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-109deg) rotateY(352deg) translateX(200px)
      rotateZ(109deg);
  }
  80% {
    transform: rotateZ(-109deg) rotateY(352deg) translateX(200px)
      rotateZ(109deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-109deg) rotateY(352deg) translateX(600px)
      rotateZ(109deg);
  }
}
.c:nth-child(454) {
  animation: orbit454 14s infinite;
  animation-delay: 4.54s;
  background-color: #00ff0b;
}

@keyframes orbit454 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-1deg) rotateY(279deg) translateX(200px) rotateZ(1deg);
  }
  80% {
    transform: rotateZ(-1deg) rotateY(279deg) translateX(200px) rotateZ(1deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-1deg) rotateY(279deg) translateX(600px) rotateZ(1deg);
  }
}
.c:nth-child(455) {
  animation: orbit455 14s infinite;
  animation-delay: 4.55s;
  background-color: #00ff0e;
}

@keyframes orbit455 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-254deg) rotateY(159deg) translateX(200px)
      rotateZ(254deg);
  }
  80% {
    transform: rotateZ(-254deg) rotateY(159deg) translateX(200px)
      rotateZ(254deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-254deg) rotateY(159deg) translateX(600px)
      rotateZ(254deg);
  }
}
.c:nth-child(456) {
  animation: orbit456 14s infinite;
  animation-delay: 4.56s;
  background-color: #00ff11;
}

@keyframes orbit456 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-336deg) rotateY(335deg) translateX(200px)
      rotateZ(336deg);
  }
  80% {
    transform: rotateZ(-336deg) rotateY(335deg) translateX(200px)
      rotateZ(336deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-336deg) rotateY(335deg) translateX(600px)
      rotateZ(336deg);
  }
}
.c:nth-child(457) {
  animation: orbit457 14s infinite;
  animation-delay: 4.57s;
  background-color: #00ff14;
}

@keyframes orbit457 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-210deg) rotateY(42deg) translateX(200px) rotateZ(210deg);
  }
  80% {
    transform: rotateZ(-210deg) rotateY(42deg) translateX(200px) rotateZ(210deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-210deg) rotateY(42deg) translateX(600px) rotateZ(210deg);
  }
}
.c:nth-child(458) {
  animation: orbit458 14s infinite;
  animation-delay: 4.58s;
  background-color: #00ff17;
}

@keyframes orbit458 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-161deg) rotateY(142deg) translateX(200px)
      rotateZ(161deg);
  }
  80% {
    transform: rotateZ(-161deg) rotateY(142deg) translateX(200px)
      rotateZ(161deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-161deg) rotateY(142deg) translateX(600px)
      rotateZ(161deg);
  }
}
.c:nth-child(459) {
  animation: orbit459 14s infinite;
  animation-delay: 4.59s;
  background-color: #00ff1a;
}

@keyframes orbit459 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-61deg) rotateY(319deg) translateX(200px) rotateZ(61deg);
  }
  80% {
    transform: rotateZ(-61deg) rotateY(319deg) translateX(200px) rotateZ(61deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-61deg) rotateY(319deg) translateX(600px) rotateZ(61deg);
  }
}
.c:nth-child(460) {
  animation: orbit460 14s infinite;
  animation-delay: 4.6s;
  background-color: #00ff1c;
}

@keyframes orbit460 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-171deg) rotateY(172deg) translateX(200px)
      rotateZ(171deg);
  }
  80% {
    transform: rotateZ(-171deg) rotateY(172deg) translateX(200px)
      rotateZ(171deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-171deg) rotateY(172deg) translateX(600px)
      rotateZ(171deg);
  }
}
.c:nth-child(461) {
  animation: orbit461 14s infinite;
  animation-delay: 4.61s;
  background-color: #00ff1f;
}

@keyframes orbit461 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-142deg) rotateY(157deg) translateX(200px)
      rotateZ(142deg);
  }
  80% {
    transform: rotateZ(-142deg) rotateY(157deg) translateX(200px)
      rotateZ(142deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-142deg) rotateY(157deg) translateX(600px)
      rotateZ(142deg);
  }
}
.c:nth-child(462) {
  animation: orbit462 14s infinite;
  animation-delay: 4.62s;
  background-color: #00ff22;
}

@keyframes orbit462 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-81deg) rotateY(320deg) translateX(200px) rotateZ(81deg);
  }
  80% {
    transform: rotateZ(-81deg) rotateY(320deg) translateX(200px) rotateZ(81deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-81deg) rotateY(320deg) translateX(600px) rotateZ(81deg);
  }
}
.c:nth-child(463) {
  animation: orbit463 14s infinite;
  animation-delay: 4.63s;
  background-color: #00ff25;
}

@keyframes orbit463 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-113deg) rotateY(162deg) translateX(200px)
      rotateZ(113deg);
  }
  80% {
    transform: rotateZ(-113deg) rotateY(162deg) translateX(200px)
      rotateZ(113deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-113deg) rotateY(162deg) translateX(600px)
      rotateZ(113deg);
  }
}
.c:nth-child(464) {
  animation: orbit464 14s infinite;
  animation-delay: 4.64s;
  background-color: #00ff28;
}

@keyframes orbit464 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-158deg) rotateY(279deg) translateX(200px)
      rotateZ(158deg);
  }
  80% {
    transform: rotateZ(-158deg) rotateY(279deg) translateX(200px)
      rotateZ(158deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-158deg) rotateY(279deg) translateX(600px)
      rotateZ(158deg);
  }
}
.c:nth-child(465) {
  animation: orbit465 14s infinite;
  animation-delay: 4.65s;
  background-color: #00ff2b;
}

@keyframes orbit465 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-357deg) rotateY(159deg) translateX(200px)
      rotateZ(357deg);
  }
  80% {
    transform: rotateZ(-357deg) rotateY(159deg) translateX(200px)
      rotateZ(357deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-357deg) rotateY(159deg) translateX(600px)
      rotateZ(357deg);
  }
}
.c:nth-child(466) {
  animation: orbit466 14s infinite;
  animation-delay: 4.66s;
  background-color: #00ff2d;
}

@keyframes orbit466 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-149deg) rotateY(65deg) translateX(200px) rotateZ(149deg);
  }
  80% {
    transform: rotateZ(-149deg) rotateY(65deg) translateX(200px) rotateZ(149deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-149deg) rotateY(65deg) translateX(600px) rotateZ(149deg);
  }
}
.c:nth-child(467) {
  animation: orbit467 14s infinite;
  animation-delay: 4.67s;
  background-color: #00ff30;
}

@keyframes orbit467 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-77deg) rotateY(253deg) translateX(200px) rotateZ(77deg);
  }
  80% {
    transform: rotateZ(-77deg) rotateY(253deg) translateX(200px) rotateZ(77deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-77deg) rotateY(253deg) translateX(600px) rotateZ(77deg);
  }
}
.c:nth-child(468) {
  animation: orbit468 14s infinite;
  animation-delay: 4.68s;
  background-color: #00ff33;
}

@keyframes orbit468 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-249deg) rotateY(147deg) translateX(200px)
      rotateZ(249deg);
  }
  80% {
    transform: rotateZ(-249deg) rotateY(147deg) translateX(200px)
      rotateZ(249deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-249deg) rotateY(147deg) translateX(600px)
      rotateZ(249deg);
  }
}
.c:nth-child(469) {
  animation: orbit469 14s infinite;
  animation-delay: 4.69s;
  background-color: #00ff36;
}

@keyframes orbit469 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-141deg) rotateY(316deg) translateX(200px)
      rotateZ(141deg);
  }
  80% {
    transform: rotateZ(-141deg) rotateY(316deg) translateX(200px)
      rotateZ(141deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-141deg) rotateY(316deg) translateX(600px)
      rotateZ(141deg);
  }
}
.c:nth-child(470) {
  animation: orbit470 14s infinite;
  animation-delay: 4.7s;
  background-color: #00ff39;
}

@keyframes orbit470 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-105deg) rotateY(322deg) translateX(200px)
      rotateZ(105deg);
  }
  80% {
    transform: rotateZ(-105deg) rotateY(322deg) translateX(200px)
      rotateZ(105deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-105deg) rotateY(322deg) translateX(600px)
      rotateZ(105deg);
  }
}
.c:nth-child(471) {
  animation: orbit471 14s infinite;
  animation-delay: 4.71s;
  background-color: #00ff3c;
}

@keyframes orbit471 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-104deg) rotateY(28deg) translateX(200px) rotateZ(104deg);
  }
  80% {
    transform: rotateZ(-104deg) rotateY(28deg) translateX(200px) rotateZ(104deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-104deg) rotateY(28deg) translateX(600px) rotateZ(104deg);
  }
}
.c:nth-child(472) {
  animation: orbit472 14s infinite;
  animation-delay: 4.72s;
  background-color: #00ff3e;
}

@keyframes orbit472 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-234deg) rotateY(273deg) translateX(200px)
      rotateZ(234deg);
  }
  80% {
    transform: rotateZ(-234deg) rotateY(273deg) translateX(200px)
      rotateZ(234deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-234deg) rotateY(273deg) translateX(600px)
      rotateZ(234deg);
  }
}
.c:nth-child(473) {
  animation: orbit473 14s infinite;
  animation-delay: 4.73s;
  background-color: #00ff41;
}

@keyframes orbit473 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-354deg) rotateY(217deg) translateX(200px)
      rotateZ(354deg);
  }
  80% {
    transform: rotateZ(-354deg) rotateY(217deg) translateX(200px)
      rotateZ(354deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-354deg) rotateY(217deg) translateX(600px)
      rotateZ(354deg);
  }
}
.c:nth-child(474) {
  animation: orbit474 14s infinite;
  animation-delay: 4.74s;
  background-color: #00ff44;
}

@keyframes orbit474 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-39deg) rotateY(21deg) translateX(200px) rotateZ(39deg);
  }
  80% {
    transform: rotateZ(-39deg) rotateY(21deg) translateX(200px) rotateZ(39deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-39deg) rotateY(21deg) translateX(600px) rotateZ(39deg);
  }
}
.c:nth-child(475) {
  animation: orbit475 14s infinite;
  animation-delay: 4.75s;
  background-color: #00ff47;
}

@keyframes orbit475 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-137deg) rotateY(233deg) translateX(200px)
      rotateZ(137deg);
  }
  80% {
    transform: rotateZ(-137deg) rotateY(233deg) translateX(200px)
      rotateZ(137deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-137deg) rotateY(233deg) translateX(600px)
      rotateZ(137deg);
  }
}
.c:nth-child(476) {
  animation: orbit476 14s infinite;
  animation-delay: 4.76s;
  background-color: #00ff4a;
}

@keyframes orbit476 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-184deg) rotateY(350deg) translateX(200px)
      rotateZ(184deg);
  }
  80% {
    transform: rotateZ(-184deg) rotateY(350deg) translateX(200px)
      rotateZ(184deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-184deg) rotateY(350deg) translateX(600px)
      rotateZ(184deg);
  }
}
.c:nth-child(477) {
  animation: orbit477 14s infinite;
  animation-delay: 4.77s;
  background-color: #00ff4d;
}

@keyframes orbit477 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-320deg) rotateY(242deg) translateX(200px)
      rotateZ(320deg);
  }
  80% {
    transform: rotateZ(-320deg) rotateY(242deg) translateX(200px)
      rotateZ(320deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-320deg) rotateY(242deg) translateX(600px)
      rotateZ(320deg);
  }
}
.c:nth-child(478) {
  animation: orbit478 14s infinite;
  animation-delay: 4.78s;
  background-color: #00ff4f;
}

@keyframes orbit478 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-33deg) rotateY(240deg) translateX(200px) rotateZ(33deg);
  }
  80% {
    transform: rotateZ(-33deg) rotateY(240deg) translateX(200px) rotateZ(33deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-33deg) rotateY(240deg) translateX(600px) rotateZ(33deg);
  }
}
.c:nth-child(479) {
  animation: orbit479 14s infinite;
  animation-delay: 4.79s;
  background-color: #00ff52;
}

@keyframes orbit479 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-272deg) rotateY(333deg) translateX(200px)
      rotateZ(272deg);
  }
  80% {
    transform: rotateZ(-272deg) rotateY(333deg) translateX(200px)
      rotateZ(272deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-272deg) rotateY(333deg) translateX(600px)
      rotateZ(272deg);
  }
}
.c:nth-child(480) {
  animation: orbit480 14s infinite;
  animation-delay: 4.8s;
  background-color: #00ff55;
}

@keyframes orbit480 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-297deg) rotateY(277deg) translateX(200px)
      rotateZ(297deg);
  }
  80% {
    transform: rotateZ(-297deg) rotateY(277deg) translateX(200px)
      rotateZ(297deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-297deg) rotateY(277deg) translateX(600px)
      rotateZ(297deg);
  }
}
.c:nth-child(481) {
  animation: orbit481 14s infinite;
  animation-delay: 4.81s;
  background-color: #00ff58;
}

@keyframes orbit481 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-2deg) rotateY(310deg) translateX(200px) rotateZ(2deg);
  }
  80% {
    transform: rotateZ(-2deg) rotateY(310deg) translateX(200px) rotateZ(2deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-2deg) rotateY(310deg) translateX(600px) rotateZ(2deg);
  }
}
.c:nth-child(482) {
  animation: orbit482 14s infinite;
  animation-delay: 4.82s;
  background-color: #00ff5b;
}

@keyframes orbit482 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-103deg) rotateY(347deg) translateX(200px)
      rotateZ(103deg);
  }
  80% {
    transform: rotateZ(-103deg) rotateY(347deg) translateX(200px)
      rotateZ(103deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-103deg) rotateY(347deg) translateX(600px)
      rotateZ(103deg);
  }
}
.c:nth-child(483) {
  animation: orbit483 14s infinite;
  animation-delay: 4.83s;
  background-color: #00ff5e;
}

@keyframes orbit483 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-94deg) rotateY(13deg) translateX(200px) rotateZ(94deg);
  }
  80% {
    transform: rotateZ(-94deg) rotateY(13deg) translateX(200px) rotateZ(94deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-94deg) rotateY(13deg) translateX(600px) rotateZ(94deg);
  }
}
.c:nth-child(484) {
  animation: orbit484 14s infinite;
  animation-delay: 4.84s;
  background-color: #00ff60;
}

@keyframes orbit484 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-44deg) rotateY(245deg) translateX(200px) rotateZ(44deg);
  }
  80% {
    transform: rotateZ(-44deg) rotateY(245deg) translateX(200px) rotateZ(44deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-44deg) rotateY(245deg) translateX(600px) rotateZ(44deg);
  }
}
.c:nth-child(485) {
  animation: orbit485 14s infinite;
  animation-delay: 4.85s;
  background-color: #00ff63;
}

@keyframes orbit485 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-163deg) rotateY(247deg) translateX(200px)
      rotateZ(163deg);
  }
  80% {
    transform: rotateZ(-163deg) rotateY(247deg) translateX(200px)
      rotateZ(163deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-163deg) rotateY(247deg) translateX(600px)
      rotateZ(163deg);
  }
}
.c:nth-child(486) {
  animation: orbit486 14s infinite;
  animation-delay: 4.86s;
  background-color: #00ff66;
}

@keyframes orbit486 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-245deg) rotateY(70deg) translateX(200px) rotateZ(245deg);
  }
  80% {
    transform: rotateZ(-245deg) rotateY(70deg) translateX(200px) rotateZ(245deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-245deg) rotateY(70deg) translateX(600px) rotateZ(245deg);
  }
}
.c:nth-child(487) {
  animation: orbit487 14s infinite;
  animation-delay: 4.87s;
  background-color: #00ff69;
}

@keyframes orbit487 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-175deg) rotateY(246deg) translateX(200px)
      rotateZ(175deg);
  }
  80% {
    transform: rotateZ(-175deg) rotateY(246deg) translateX(200px)
      rotateZ(175deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-175deg) rotateY(246deg) translateX(600px)
      rotateZ(175deg);
  }
}
.c:nth-child(488) {
  animation: orbit488 14s infinite;
  animation-delay: 4.88s;
  background-color: #00ff6c;
}

@keyframes orbit488 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-162deg) rotateY(123deg) translateX(200px)
      rotateZ(162deg);
  }
  80% {
    transform: rotateZ(-162deg) rotateY(123deg) translateX(200px)
      rotateZ(162deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-162deg) rotateY(123deg) translateX(600px)
      rotateZ(162deg);
  }
}
.c:nth-child(489) {
  animation: orbit489 14s infinite;
  animation-delay: 4.89s;
  background-color: #00ff6f;
}

@keyframes orbit489 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-319deg) rotateY(24deg) translateX(200px) rotateZ(319deg);
  }
  80% {
    transform: rotateZ(-319deg) rotateY(24deg) translateX(200px) rotateZ(319deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-319deg) rotateY(24deg) translateX(600px) rotateZ(319deg);
  }
}
.c:nth-child(490) {
  animation: orbit490 14s infinite;
  animation-delay: 4.9s;
  background-color: #00ff71;
}

@keyframes orbit490 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-212deg) rotateY(257deg) translateX(200px)
      rotateZ(212deg);
  }
  80% {
    transform: rotateZ(-212deg) rotateY(257deg) translateX(200px)
      rotateZ(212deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-212deg) rotateY(257deg) translateX(600px)
      rotateZ(212deg);
  }
}
.c:nth-child(491) {
  animation: orbit491 14s infinite;
  animation-delay: 4.91s;
  background-color: #00ff74;
}

@keyframes orbit491 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-41deg) rotateY(294deg) translateX(200px) rotateZ(41deg);
  }
  80% {
    transform: rotateZ(-41deg) rotateY(294deg) translateX(200px) rotateZ(41deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-41deg) rotateY(294deg) translateX(600px) rotateZ(41deg);
  }
}
.c:nth-child(492) {
  animation: orbit492 14s infinite;
  animation-delay: 4.92s;
  background-color: #00ff77;
}

@keyframes orbit492 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-340deg) rotateY(198deg) translateX(200px)
      rotateZ(340deg);
  }
  80% {
    transform: rotateZ(-340deg) rotateY(198deg) translateX(200px)
      rotateZ(340deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-340deg) rotateY(198deg) translateX(600px)
      rotateZ(340deg);
  }
}
.c:nth-child(493) {
  animation: orbit493 14s infinite;
  animation-delay: 4.93s;
  background-color: #00ff7a;
}

@keyframes orbit493 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-314deg) rotateY(33deg) translateX(200px) rotateZ(314deg);
  }
  80% {
    transform: rotateZ(-314deg) rotateY(33deg) translateX(200px) rotateZ(314deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-314deg) rotateY(33deg) translateX(600px) rotateZ(314deg);
  }
}
.c:nth-child(494) {
  animation: orbit494 14s infinite;
  animation-delay: 4.94s;
  background-color: #00ff7d;
}

@keyframes orbit494 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-6deg) rotateY(305deg) translateX(200px) rotateZ(6deg);
  }
  80% {
    transform: rotateZ(-6deg) rotateY(305deg) translateX(200px) rotateZ(6deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-6deg) rotateY(305deg) translateX(600px) rotateZ(6deg);
  }
}
.c:nth-child(495) {
  animation: orbit495 14s infinite;
  animation-delay: 4.95s;
  background-color: #00ff80;
}

@keyframes orbit495 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-143deg) rotateY(132deg) translateX(200px)
      rotateZ(143deg);
  }
  80% {
    transform: rotateZ(-143deg) rotateY(132deg) translateX(200px)
      rotateZ(143deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-143deg) rotateY(132deg) translateX(600px)
      rotateZ(143deg);
  }
}
.c:nth-child(496) {
  animation: orbit496 14s infinite;
  animation-delay: 4.96s;
  background-color: #00ff82;
}

@keyframes orbit496 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-70deg) rotateY(303deg) translateX(200px) rotateZ(70deg);
  }
  80% {
    transform: rotateZ(-70deg) rotateY(303deg) translateX(200px) rotateZ(70deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-70deg) rotateY(303deg) translateX(600px) rotateZ(70deg);
  }
}
.c:nth-child(497) {
  animation: orbit497 14s infinite;
  animation-delay: 4.97s;
  background-color: #00ff85;
}

@keyframes orbit497 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-113deg) rotateY(291deg) translateX(200px)
      rotateZ(113deg);
  }
  80% {
    transform: rotateZ(-113deg) rotateY(291deg) translateX(200px)
      rotateZ(113deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-113deg) rotateY(291deg) translateX(600px)
      rotateZ(113deg);
  }
}
.c:nth-child(498) {
  animation: orbit498 14s infinite;
  animation-delay: 4.98s;
  background-color: #00ff88;
}

@keyframes orbit498 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-44deg) rotateY(180deg) translateX(200px) rotateZ(44deg);
  }
  80% {
    transform: rotateZ(-44deg) rotateY(180deg) translateX(200px) rotateZ(44deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-44deg) rotateY(180deg) translateX(600px) rotateZ(44deg);
  }
}
.c:nth-child(499) {
  animation: orbit499 14s infinite;
  animation-delay: 4.99s;
  background-color: #00ff8b;
}

@keyframes orbit499 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-264deg) rotateY(307deg) translateX(200px)
      rotateZ(264deg);
  }
  80% {
    transform: rotateZ(-264deg) rotateY(307deg) translateX(200px)
      rotateZ(264deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-264deg) rotateY(307deg) translateX(600px)
      rotateZ(264deg);
  }
}
.c:nth-child(500) {
  animation: orbit500 14s infinite;
  animation-delay: 5s;
  background-color: #00ff8e;
}

@keyframes orbit500 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-264deg) rotateY(313deg) translateX(200px)
      rotateZ(264deg);
  }
  80% {
    transform: rotateZ(-264deg) rotateY(313deg) translateX(200px)
      rotateZ(264deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-264deg) rotateY(313deg) translateX(600px)
      rotateZ(264deg);
  }
}
.c:nth-child(501) {
  animation: orbit501 14s infinite;
  animation-delay: 5.01s;
  background-color: #00ff91;
}

@keyframes orbit501 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-143deg) rotateY(88deg) translateX(200px) rotateZ(143deg);
  }
  80% {
    transform: rotateZ(-143deg) rotateY(88deg) translateX(200px) rotateZ(143deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-143deg) rotateY(88deg) translateX(600px) rotateZ(143deg);
  }
}
.c:nth-child(502) {
  animation: orbit502 14s infinite;
  animation-delay: 5.02s;
  background-color: #00ff93;
}

@keyframes orbit502 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-37deg) rotateY(268deg) translateX(200px) rotateZ(37deg);
  }
  80% {
    transform: rotateZ(-37deg) rotateY(268deg) translateX(200px) rotateZ(37deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-37deg) rotateY(268deg) translateX(600px) rotateZ(37deg);
  }
}
.c:nth-child(503) {
  animation: orbit503 14s infinite;
  animation-delay: 5.03s;
  background-color: #00ff96;
}

@keyframes orbit503 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-186deg) rotateY(297deg) translateX(200px)
      rotateZ(186deg);
  }
  80% {
    transform: rotateZ(-186deg) rotateY(297deg) translateX(200px)
      rotateZ(186deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-186deg) rotateY(297deg) translateX(600px)
      rotateZ(186deg);
  }
}
.c:nth-child(504) {
  animation: orbit504 14s infinite;
  animation-delay: 5.04s;
  background-color: #00ff99;
}

@keyframes orbit504 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-303deg) rotateY(359deg) translateX(200px)
      rotateZ(303deg);
  }
  80% {
    transform: rotateZ(-303deg) rotateY(359deg) translateX(200px)
      rotateZ(303deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-303deg) rotateY(359deg) translateX(600px)
      rotateZ(303deg);
  }
}
.c:nth-child(505) {
  animation: orbit505 14s infinite;
  animation-delay: 5.05s;
  background-color: #00ff9c;
}

@keyframes orbit505 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-285deg) rotateY(284deg) translateX(200px)
      rotateZ(285deg);
  }
  80% {
    transform: rotateZ(-285deg) rotateY(284deg) translateX(200px)
      rotateZ(285deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-285deg) rotateY(284deg) translateX(600px)
      rotateZ(285deg);
  }
}
.c:nth-child(506) {
  animation: orbit506 14s infinite;
  animation-delay: 5.06s;
  background-color: #00ff9f;
}

@keyframes orbit506 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-195deg) rotateY(313deg) translateX(200px)
      rotateZ(195deg);
  }
  80% {
    transform: rotateZ(-195deg) rotateY(313deg) translateX(200px)
      rotateZ(195deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-195deg) rotateY(313deg) translateX(600px)
      rotateZ(195deg);
  }
}
.c:nth-child(507) {
  animation: orbit507 14s infinite;
  animation-delay: 5.07s;
  background-color: #00ffa2;
}

@keyframes orbit507 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-120deg) rotateY(302deg) translateX(200px)
      rotateZ(120deg);
  }
  80% {
    transform: rotateZ(-120deg) rotateY(302deg) translateX(200px)
      rotateZ(120deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-120deg) rotateY(302deg) translateX(600px)
      rotateZ(120deg);
  }
}
.c:nth-child(508) {
  animation: orbit508 14s infinite;
  animation-delay: 5.08s;
  background-color: #00ffa4;
}

@keyframes orbit508 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-6deg) rotateY(289deg) translateX(200px) rotateZ(6deg);
  }
  80% {
    transform: rotateZ(-6deg) rotateY(289deg) translateX(200px) rotateZ(6deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-6deg) rotateY(289deg) translateX(600px) rotateZ(6deg);
  }
}
.c:nth-child(509) {
  animation: orbit509 14s infinite;
  animation-delay: 5.09s;
  background-color: #00ffa7;
}

@keyframes orbit509 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-226deg) rotateY(173deg) translateX(200px)
      rotateZ(226deg);
  }
  80% {
    transform: rotateZ(-226deg) rotateY(173deg) translateX(200px)
      rotateZ(226deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-226deg) rotateY(173deg) translateX(600px)
      rotateZ(226deg);
  }
}
.c:nth-child(510) {
  animation: orbit510 14s infinite;
  animation-delay: 5.1s;
  background-color: #00ffaa;
}

@keyframes orbit510 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-354deg) rotateY(73deg) translateX(200px) rotateZ(354deg);
  }
  80% {
    transform: rotateZ(-354deg) rotateY(73deg) translateX(200px) rotateZ(354deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-354deg) rotateY(73deg) translateX(600px) rotateZ(354deg);
  }
}
.c:nth-child(511) {
  animation: orbit511 14s infinite;
  animation-delay: 5.11s;
  background-color: #00ffad;
}

@keyframes orbit511 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-79deg) rotateY(156deg) translateX(200px) rotateZ(79deg);
  }
  80% {
    transform: rotateZ(-79deg) rotateY(156deg) translateX(200px) rotateZ(79deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-79deg) rotateY(156deg) translateX(600px) rotateZ(79deg);
  }
}
.c:nth-child(512) {
  animation: orbit512 14s infinite;
  animation-delay: 5.12s;
  background-color: #00ffb0;
}

@keyframes orbit512 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-280deg) rotateY(339deg) translateX(200px)
      rotateZ(280deg);
  }
  80% {
    transform: rotateZ(-280deg) rotateY(339deg) translateX(200px)
      rotateZ(280deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-280deg) rotateY(339deg) translateX(600px)
      rotateZ(280deg);
  }
}
.c:nth-child(513) {
  animation: orbit513 14s infinite;
  animation-delay: 5.13s;
  background-color: #00ffb3;
}

@keyframes orbit513 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-91deg) rotateY(93deg) translateX(200px) rotateZ(91deg);
  }
  80% {
    transform: rotateZ(-91deg) rotateY(93deg) translateX(200px) rotateZ(91deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-91deg) rotateY(93deg) translateX(600px) rotateZ(91deg);
  }
}
.c:nth-child(514) {
  animation: orbit514 14s infinite;
  animation-delay: 5.14s;
  background-color: #00ffb5;
}

@keyframes orbit514 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-304deg) rotateY(13deg) translateX(200px) rotateZ(304deg);
  }
  80% {
    transform: rotateZ(-304deg) rotateY(13deg) translateX(200px) rotateZ(304deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-304deg) rotateY(13deg) translateX(600px) rotateZ(304deg);
  }
}
.c:nth-child(515) {
  animation: orbit515 14s infinite;
  animation-delay: 5.15s;
  background-color: #00ffb8;
}

@keyframes orbit515 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-280deg) rotateY(251deg) translateX(200px)
      rotateZ(280deg);
  }
  80% {
    transform: rotateZ(-280deg) rotateY(251deg) translateX(200px)
      rotateZ(280deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-280deg) rotateY(251deg) translateX(600px)
      rotateZ(280deg);
  }
}
.c:nth-child(516) {
  animation: orbit516 14s infinite;
  animation-delay: 5.16s;
  background-color: #00ffbb;
}

@keyframes orbit516 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-27deg) rotateY(17deg) translateX(200px) rotateZ(27deg);
  }
  80% {
    transform: rotateZ(-27deg) rotateY(17deg) translateX(200px) rotateZ(27deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-27deg) rotateY(17deg) translateX(600px) rotateZ(27deg);
  }
}
.c:nth-child(517) {
  animation: orbit517 14s infinite;
  animation-delay: 5.17s;
  background-color: #00ffbe;
}

@keyframes orbit517 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-360deg) rotateY(306deg) translateX(200px)
      rotateZ(360deg);
  }
  80% {
    transform: rotateZ(-360deg) rotateY(306deg) translateX(200px)
      rotateZ(360deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-360deg) rotateY(306deg) translateX(600px)
      rotateZ(360deg);
  }
}
.c:nth-child(518) {
  animation: orbit518 14s infinite;
  animation-delay: 5.18s;
  background-color: #00ffc1;
}

@keyframes orbit518 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-186deg) rotateY(216deg) translateX(200px)
      rotateZ(186deg);
  }
  80% {
    transform: rotateZ(-186deg) rotateY(216deg) translateX(200px)
      rotateZ(186deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-186deg) rotateY(216deg) translateX(600px)
      rotateZ(186deg);
  }
}
.c:nth-child(519) {
  animation: orbit519 14s infinite;
  animation-delay: 5.19s;
  background-color: #00ffc4;
}

@keyframes orbit519 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-14deg) rotateY(12deg) translateX(200px) rotateZ(14deg);
  }
  80% {
    transform: rotateZ(-14deg) rotateY(12deg) translateX(200px) rotateZ(14deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-14deg) rotateY(12deg) translateX(600px) rotateZ(14deg);
  }
}
.c:nth-child(520) {
  animation: orbit520 14s infinite;
  animation-delay: 5.2s;
  background-color: #00ffc6;
}

@keyframes orbit520 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-238deg) rotateY(168deg) translateX(200px)
      rotateZ(238deg);
  }
  80% {
    transform: rotateZ(-238deg) rotateY(168deg) translateX(200px)
      rotateZ(238deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-238deg) rotateY(168deg) translateX(600px)
      rotateZ(238deg);
  }
}
.c:nth-child(521) {
  animation: orbit521 14s infinite;
  animation-delay: 5.21s;
  background-color: #00ffc9;
}

@keyframes orbit521 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-292deg) rotateY(61deg) translateX(200px) rotateZ(292deg);
  }
  80% {
    transform: rotateZ(-292deg) rotateY(61deg) translateX(200px) rotateZ(292deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-292deg) rotateY(61deg) translateX(600px) rotateZ(292deg);
  }
}
.c:nth-child(522) {
  animation: orbit522 14s infinite;
  animation-delay: 5.22s;
  background-color: #00ffcc;
}

@keyframes orbit522 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-302deg) rotateY(19deg) translateX(200px) rotateZ(302deg);
  }
  80% {
    transform: rotateZ(-302deg) rotateY(19deg) translateX(200px) rotateZ(302deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-302deg) rotateY(19deg) translateX(600px) rotateZ(302deg);
  }
}
.c:nth-child(523) {
  animation: orbit523 14s infinite;
  animation-delay: 5.23s;
  background-color: #00ffcf;
}

@keyframes orbit523 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-335deg) rotateY(173deg) translateX(200px)
      rotateZ(335deg);
  }
  80% {
    transform: rotateZ(-335deg) rotateY(173deg) translateX(200px)
      rotateZ(335deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-335deg) rotateY(173deg) translateX(600px)
      rotateZ(335deg);
  }
}
.c:nth-child(524) {
  animation: orbit524 14s infinite;
  animation-delay: 5.24s;
  background-color: #00ffd2;
}

@keyframes orbit524 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-70deg) rotateY(137deg) translateX(200px) rotateZ(70deg);
  }
  80% {
    transform: rotateZ(-70deg) rotateY(137deg) translateX(200px) rotateZ(70deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-70deg) rotateY(137deg) translateX(600px) rotateZ(70deg);
  }
}
.c:nth-child(525) {
  animation: orbit525 14s infinite;
  animation-delay: 5.25s;
  background-color: #00ffd5;
}

@keyframes orbit525 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-273deg) rotateY(316deg) translateX(200px)
      rotateZ(273deg);
  }
  80% {
    transform: rotateZ(-273deg) rotateY(316deg) translateX(200px)
      rotateZ(273deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-273deg) rotateY(316deg) translateX(600px)
      rotateZ(273deg);
  }
}
.c:nth-child(526) {
  animation: orbit526 14s infinite;
  animation-delay: 5.26s;
  background-color: #00ffd7;
}

@keyframes orbit526 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-44deg) rotateY(343deg) translateX(200px) rotateZ(44deg);
  }
  80% {
    transform: rotateZ(-44deg) rotateY(343deg) translateX(200px) rotateZ(44deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-44deg) rotateY(343deg) translateX(600px) rotateZ(44deg);
  }
}
.c:nth-child(527) {
  animation: orbit527 14s infinite;
  animation-delay: 5.27s;
  background-color: #00ffda;
}

@keyframes orbit527 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-224deg) rotateY(77deg) translateX(200px) rotateZ(224deg);
  }
  80% {
    transform: rotateZ(-224deg) rotateY(77deg) translateX(200px) rotateZ(224deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-224deg) rotateY(77deg) translateX(600px) rotateZ(224deg);
  }
}
.c:nth-child(528) {
  animation: orbit528 14s infinite;
  animation-delay: 5.28s;
  background-color: #00ffdd;
}

@keyframes orbit528 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-97deg) rotateY(265deg) translateX(200px) rotateZ(97deg);
  }
  80% {
    transform: rotateZ(-97deg) rotateY(265deg) translateX(200px) rotateZ(97deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-97deg) rotateY(265deg) translateX(600px) rotateZ(97deg);
  }
}
.c:nth-child(529) {
  animation: orbit529 14s infinite;
  animation-delay: 5.29s;
  background-color: #00ffe0;
}

@keyframes orbit529 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-208deg) rotateY(252deg) translateX(200px)
      rotateZ(208deg);
  }
  80% {
    transform: rotateZ(-208deg) rotateY(252deg) translateX(200px)
      rotateZ(208deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-208deg) rotateY(252deg) translateX(600px)
      rotateZ(208deg);
  }
}
.c:nth-child(530) {
  animation: orbit530 14s infinite;
  animation-delay: 5.3s;
  background-color: #00ffe3;
}

@keyframes orbit530 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-270deg) rotateY(111deg) translateX(200px)
      rotateZ(270deg);
  }
  80% {
    transform: rotateZ(-270deg) rotateY(111deg) translateX(200px)
      rotateZ(270deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-270deg) rotateY(111deg) translateX(600px)
      rotateZ(270deg);
  }
}
.c:nth-child(531) {
  animation: orbit531 14s infinite;
  animation-delay: 5.31s;
  background-color: #00ffe6;
}

@keyframes orbit531 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-131deg) rotateY(128deg) translateX(200px)
      rotateZ(131deg);
  }
  80% {
    transform: rotateZ(-131deg) rotateY(128deg) translateX(200px)
      rotateZ(131deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-131deg) rotateY(128deg) translateX(600px)
      rotateZ(131deg);
  }
}
.c:nth-child(532) {
  animation: orbit532 14s infinite;
  animation-delay: 5.32s;
  background-color: #00ffe8;
}

@keyframes orbit532 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-73deg) rotateY(205deg) translateX(200px) rotateZ(73deg);
  }
  80% {
    transform: rotateZ(-73deg) rotateY(205deg) translateX(200px) rotateZ(73deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-73deg) rotateY(205deg) translateX(600px) rotateZ(73deg);
  }
}
.c:nth-child(533) {
  animation: orbit533 14s infinite;
  animation-delay: 5.33s;
  background-color: #00ffeb;
}

@keyframes orbit533 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-123deg) rotateY(164deg) translateX(200px)
      rotateZ(123deg);
  }
  80% {
    transform: rotateZ(-123deg) rotateY(164deg) translateX(200px)
      rotateZ(123deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-123deg) rotateY(164deg) translateX(600px)
      rotateZ(123deg);
  }
}
.c:nth-child(534) {
  animation: orbit534 14s infinite;
  animation-delay: 5.34s;
  background-color: #00ffee;
}

@keyframes orbit534 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-205deg) rotateY(333deg) translateX(200px)
      rotateZ(205deg);
  }
  80% {
    transform: rotateZ(-205deg) rotateY(333deg) translateX(200px)
      rotateZ(205deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-205deg) rotateY(333deg) translateX(600px)
      rotateZ(205deg);
  }
}
.c:nth-child(535) {
  animation: orbit535 14s infinite;
  animation-delay: 5.35s;
  background-color: #00fff1;
}

@keyframes orbit535 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-141deg) rotateY(49deg) translateX(200px) rotateZ(141deg);
  }
  80% {
    transform: rotateZ(-141deg) rotateY(49deg) translateX(200px) rotateZ(141deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-141deg) rotateY(49deg) translateX(600px) rotateZ(141deg);
  }
}
.c:nth-child(536) {
  animation: orbit536 14s infinite;
  animation-delay: 5.36s;
  background-color: #00fff4;
}

@keyframes orbit536 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-346deg) rotateY(242deg) translateX(200px)
      rotateZ(346deg);
  }
  80% {
    transform: rotateZ(-346deg) rotateY(242deg) translateX(200px)
      rotateZ(346deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-346deg) rotateY(242deg) translateX(600px)
      rotateZ(346deg);
  }
}
.c:nth-child(537) {
  animation: orbit537 14s infinite;
  animation-delay: 5.37s;
  background-color: #00fff7;
}

@keyframes orbit537 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-323deg) rotateY(79deg) translateX(200px) rotateZ(323deg);
  }
  80% {
    transform: rotateZ(-323deg) rotateY(79deg) translateX(200px) rotateZ(323deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-323deg) rotateY(79deg) translateX(600px) rotateZ(323deg);
  }
}
.c:nth-child(538) {
  animation: orbit538 14s infinite;
  animation-delay: 5.38s;
  background-color: #00fff9;
}

@keyframes orbit538 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-9deg) rotateY(204deg) translateX(200px) rotateZ(9deg);
  }
  80% {
    transform: rotateZ(-9deg) rotateY(204deg) translateX(200px) rotateZ(9deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-9deg) rotateY(204deg) translateX(600px) rotateZ(9deg);
  }
}
.c:nth-child(539) {
  animation: orbit539 14s infinite;
  animation-delay: 5.39s;
  background-color: #00fffc;
}

@keyframes orbit539 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-186deg) rotateY(279deg) translateX(200px)
      rotateZ(186deg);
  }
  80% {
    transform: rotateZ(-186deg) rotateY(279deg) translateX(200px)
      rotateZ(186deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-186deg) rotateY(279deg) translateX(600px)
      rotateZ(186deg);
  }
}
.c:nth-child(540) {
  animation: orbit540 14s infinite;
  animation-delay: 5.4s;
  background-color: aqua;
}

@keyframes orbit540 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-20deg) rotateY(344deg) translateX(200px) rotateZ(20deg);
  }
  80% {
    transform: rotateZ(-20deg) rotateY(344deg) translateX(200px) rotateZ(20deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-20deg) rotateY(344deg) translateX(600px) rotateZ(20deg);
  }
}
.c:nth-child(541) {
  animation: orbit541 14s infinite;
  animation-delay: 5.41s;
  background-color: #00fcff;
}

@keyframes orbit541 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-218deg) rotateY(24deg) translateX(200px) rotateZ(218deg);
  }
  80% {
    transform: rotateZ(-218deg) rotateY(24deg) translateX(200px) rotateZ(218deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-218deg) rotateY(24deg) translateX(600px) rotateZ(218deg);
  }
}
.c:nth-child(542) {
  animation: orbit542 14s infinite;
  animation-delay: 5.42s;
  background-color: #00f9ff;
}

@keyframes orbit542 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-19deg) rotateY(125deg) translateX(200px) rotateZ(19deg);
  }
  80% {
    transform: rotateZ(-19deg) rotateY(125deg) translateX(200px) rotateZ(19deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-19deg) rotateY(125deg) translateX(600px) rotateZ(19deg);
  }
}
.c:nth-child(543) {
  animation: orbit543 14s infinite;
  animation-delay: 5.43s;
  background-color: #00f7ff;
}

@keyframes orbit543 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-333deg) rotateY(192deg) translateX(200px)
      rotateZ(333deg);
  }
  80% {
    transform: rotateZ(-333deg) rotateY(192deg) translateX(200px)
      rotateZ(333deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-333deg) rotateY(192deg) translateX(600px)
      rotateZ(333deg);
  }
}
.c:nth-child(544) {
  animation: orbit544 14s infinite;
  animation-delay: 5.44s;
  background-color: #00f4ff;
}

@keyframes orbit544 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-181deg) rotateY(228deg) translateX(200px)
      rotateZ(181deg);
  }
  80% {
    transform: rotateZ(-181deg) rotateY(228deg) translateX(200px)
      rotateZ(181deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-181deg) rotateY(228deg) translateX(600px)
      rotateZ(181deg);
  }
}
.c:nth-child(545) {
  animation: orbit545 14s infinite;
  animation-delay: 5.45s;
  background-color: #00f1ff;
}

@keyframes orbit545 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-77deg) rotateY(62deg) translateX(200px) rotateZ(77deg);
  }
  80% {
    transform: rotateZ(-77deg) rotateY(62deg) translateX(200px) rotateZ(77deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-77deg) rotateY(62deg) translateX(600px) rotateZ(77deg);
  }
}
.c:nth-child(546) {
  animation: orbit546 14s infinite;
  animation-delay: 5.46s;
  background-color: #00eeff;
}

@keyframes orbit546 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-7deg) rotateY(180deg) translateX(200px) rotateZ(7deg);
  }
  80% {
    transform: rotateZ(-7deg) rotateY(180deg) translateX(200px) rotateZ(7deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-7deg) rotateY(180deg) translateX(600px) rotateZ(7deg);
  }
}
.c:nth-child(547) {
  animation: orbit547 14s infinite;
  animation-delay: 5.47s;
  background-color: #00ebff;
}

@keyframes orbit547 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-22deg) rotateY(227deg) translateX(200px) rotateZ(22deg);
  }
  80% {
    transform: rotateZ(-22deg) rotateY(227deg) translateX(200px) rotateZ(22deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-22deg) rotateY(227deg) translateX(600px) rotateZ(22deg);
  }
}
.c:nth-child(548) {
  animation: orbit548 14s infinite;
  animation-delay: 5.48s;
  background-color: #00e8ff;
}

@keyframes orbit548 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-360deg) rotateY(280deg) translateX(200px)
      rotateZ(360deg);
  }
  80% {
    transform: rotateZ(-360deg) rotateY(280deg) translateX(200px)
      rotateZ(360deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-360deg) rotateY(280deg) translateX(600px)
      rotateZ(360deg);
  }
}
.c:nth-child(549) {
  animation: orbit549 14s infinite;
  animation-delay: 5.49s;
  background-color: #00e6ff;
}

@keyframes orbit549 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-225deg) rotateY(337deg) translateX(200px)
      rotateZ(225deg);
  }
  80% {
    transform: rotateZ(-225deg) rotateY(337deg) translateX(200px)
      rotateZ(225deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-225deg) rotateY(337deg) translateX(600px)
      rotateZ(225deg);
  }
}
.c:nth-child(550) {
  animation: orbit550 14s infinite;
  animation-delay: 5.5s;
  background-color: #00e3ff;
}

@keyframes orbit550 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-244deg) rotateY(355deg) translateX(200px)
      rotateZ(244deg);
  }
  80% {
    transform: rotateZ(-244deg) rotateY(355deg) translateX(200px)
      rotateZ(244deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-244deg) rotateY(355deg) translateX(600px)
      rotateZ(244deg);
  }
}
.c:nth-child(551) {
  animation: orbit551 14s infinite;
  animation-delay: 5.51s;
  background-color: #00e0ff;
}

@keyframes orbit551 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-198deg) rotateY(183deg) translateX(200px)
      rotateZ(198deg);
  }
  80% {
    transform: rotateZ(-198deg) rotateY(183deg) translateX(200px)
      rotateZ(198deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-198deg) rotateY(183deg) translateX(600px)
      rotateZ(198deg);
  }
}
.c:nth-child(552) {
  animation: orbit552 14s infinite;
  animation-delay: 5.52s;
  background-color: #00ddff;
}

@keyframes orbit552 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-124deg) rotateY(303deg) translateX(200px)
      rotateZ(124deg);
  }
  80% {
    transform: rotateZ(-124deg) rotateY(303deg) translateX(200px)
      rotateZ(124deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-124deg) rotateY(303deg) translateX(600px)
      rotateZ(124deg);
  }
}
.c:nth-child(553) {
  animation: orbit553 14s infinite;
  animation-delay: 5.53s;
  background-color: #00daff;
}

@keyframes orbit553 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-72deg) rotateY(262deg) translateX(200px) rotateZ(72deg);
  }
  80% {
    transform: rotateZ(-72deg) rotateY(262deg) translateX(200px) rotateZ(72deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-72deg) rotateY(262deg) translateX(600px) rotateZ(72deg);
  }
}
.c:nth-child(554) {
  animation: orbit554 14s infinite;
  animation-delay: 5.54s;
  background-color: #00d7ff;
}

@keyframes orbit554 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-180deg) rotateY(257deg) translateX(200px)
      rotateZ(180deg);
  }
  80% {
    transform: rotateZ(-180deg) rotateY(257deg) translateX(200px)
      rotateZ(180deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-180deg) rotateY(257deg) translateX(600px)
      rotateZ(180deg);
  }
}
.c:nth-child(555) {
  animation: orbit555 14s infinite;
  animation-delay: 5.55s;
  background-color: #00d5ff;
}

@keyframes orbit555 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-11deg) rotateY(95deg) translateX(200px) rotateZ(11deg);
  }
  80% {
    transform: rotateZ(-11deg) rotateY(95deg) translateX(200px) rotateZ(11deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-11deg) rotateY(95deg) translateX(600px) rotateZ(11deg);
  }
}
.c:nth-child(556) {
  animation: orbit556 14s infinite;
  animation-delay: 5.56s;
  background-color: #00d2ff;
}

@keyframes orbit556 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-166deg) rotateY(173deg) translateX(200px)
      rotateZ(166deg);
  }
  80% {
    transform: rotateZ(-166deg) rotateY(173deg) translateX(200px)
      rotateZ(166deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-166deg) rotateY(173deg) translateX(600px)
      rotateZ(166deg);
  }
}
.c:nth-child(557) {
  animation: orbit557 14s infinite;
  animation-delay: 5.57s;
  background-color: #00cfff;
}

@keyframes orbit557 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-59deg) rotateY(243deg) translateX(200px) rotateZ(59deg);
  }
  80% {
    transform: rotateZ(-59deg) rotateY(243deg) translateX(200px) rotateZ(59deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-59deg) rotateY(243deg) translateX(600px) rotateZ(59deg);
  }
}
.c:nth-child(558) {
  animation: orbit558 14s infinite;
  animation-delay: 5.58s;
  background-color: #00ccff;
}

@keyframes orbit558 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-266deg) rotateY(154deg) translateX(200px)
      rotateZ(266deg);
  }
  80% {
    transform: rotateZ(-266deg) rotateY(154deg) translateX(200px)
      rotateZ(266deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-266deg) rotateY(154deg) translateX(600px)
      rotateZ(266deg);
  }
}
.c:nth-child(559) {
  animation: orbit559 14s infinite;
  animation-delay: 5.59s;
  background-color: #00c9ff;
}

@keyframes orbit559 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-101deg) rotateY(219deg) translateX(200px)
      rotateZ(101deg);
  }
  80% {
    transform: rotateZ(-101deg) rotateY(219deg) translateX(200px)
      rotateZ(101deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-101deg) rotateY(219deg) translateX(600px)
      rotateZ(101deg);
  }
}
.c:nth-child(560) {
  animation: orbit560 14s infinite;
  animation-delay: 5.6s;
  background-color: #00c6ff;
}

@keyframes orbit560 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-325deg) rotateY(274deg) translateX(200px)
      rotateZ(325deg);
  }
  80% {
    transform: rotateZ(-325deg) rotateY(274deg) translateX(200px)
      rotateZ(325deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-325deg) rotateY(274deg) translateX(600px)
      rotateZ(325deg);
  }
}
.c:nth-child(561) {
  animation: orbit561 14s infinite;
  animation-delay: 5.61s;
  background-color: #00c4ff;
}

@keyframes orbit561 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-74deg) rotateY(48deg) translateX(200px) rotateZ(74deg);
  }
  80% {
    transform: rotateZ(-74deg) rotateY(48deg) translateX(200px) rotateZ(74deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-74deg) rotateY(48deg) translateX(600px) rotateZ(74deg);
  }
}
.c:nth-child(562) {
  animation: orbit562 14s infinite;
  animation-delay: 5.62s;
  background-color: #00c1ff;
}

@keyframes orbit562 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-317deg) rotateY(220deg) translateX(200px)
      rotateZ(317deg);
  }
  80% {
    transform: rotateZ(-317deg) rotateY(220deg) translateX(200px)
      rotateZ(317deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-317deg) rotateY(220deg) translateX(600px)
      rotateZ(317deg);
  }
}
.c:nth-child(563) {
  animation: orbit563 14s infinite;
  animation-delay: 5.63s;
  background-color: #00beff;
}

@keyframes orbit563 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-186deg) rotateY(218deg) translateX(200px)
      rotateZ(186deg);
  }
  80% {
    transform: rotateZ(-186deg) rotateY(218deg) translateX(200px)
      rotateZ(186deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-186deg) rotateY(218deg) translateX(600px)
      rotateZ(186deg);
  }
}
.c:nth-child(564) {
  animation: orbit564 14s infinite;
  animation-delay: 5.64s;
  background-color: #00bbff;
}

@keyframes orbit564 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-128deg) rotateY(273deg) translateX(200px)
      rotateZ(128deg);
  }
  80% {
    transform: rotateZ(-128deg) rotateY(273deg) translateX(200px)
      rotateZ(128deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-128deg) rotateY(273deg) translateX(600px)
      rotateZ(128deg);
  }
}
.c:nth-child(565) {
  animation: orbit565 14s infinite;
  animation-delay: 5.65s;
  background-color: #00b8ff;
}

@keyframes orbit565 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-351deg) rotateY(198deg) translateX(200px)
      rotateZ(351deg);
  }
  80% {
    transform: rotateZ(-351deg) rotateY(198deg) translateX(200px)
      rotateZ(351deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-351deg) rotateY(198deg) translateX(600px)
      rotateZ(351deg);
  }
}
.c:nth-child(566) {
  animation: orbit566 14s infinite;
  animation-delay: 5.66s;
  background-color: #00b5ff;
}

@keyframes orbit566 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-166deg) rotateY(278deg) translateX(200px)
      rotateZ(166deg);
  }
  80% {
    transform: rotateZ(-166deg) rotateY(278deg) translateX(200px)
      rotateZ(166deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-166deg) rotateY(278deg) translateX(600px)
      rotateZ(166deg);
  }
}
.c:nth-child(567) {
  animation: orbit567 14s infinite;
  animation-delay: 5.67s;
  background-color: #00b3ff;
}

@keyframes orbit567 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-30deg) rotateY(140deg) translateX(200px) rotateZ(30deg);
  }
  80% {
    transform: rotateZ(-30deg) rotateY(140deg) translateX(200px) rotateZ(30deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-30deg) rotateY(140deg) translateX(600px) rotateZ(30deg);
  }
}
.c:nth-child(568) {
  animation: orbit568 14s infinite;
  animation-delay: 5.68s;
  background-color: #00b0ff;
}

@keyframes orbit568 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-134deg) rotateY(325deg) translateX(200px)
      rotateZ(134deg);
  }
  80% {
    transform: rotateZ(-134deg) rotateY(325deg) translateX(200px)
      rotateZ(134deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-134deg) rotateY(325deg) translateX(600px)
      rotateZ(134deg);
  }
}
.c:nth-child(569) {
  animation: orbit569 14s infinite;
  animation-delay: 5.69s;
  background-color: #00adff;
}

@keyframes orbit569 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-99deg) rotateY(12deg) translateX(200px) rotateZ(99deg);
  }
  80% {
    transform: rotateZ(-99deg) rotateY(12deg) translateX(200px) rotateZ(99deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-99deg) rotateY(12deg) translateX(600px) rotateZ(99deg);
  }
}
.c:nth-child(570) {
  animation: orbit570 14s infinite;
  animation-delay: 5.7s;
  background-color: #00aaff;
}

@keyframes orbit570 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-194deg) rotateY(168deg) translateX(200px)
      rotateZ(194deg);
  }
  80% {
    transform: rotateZ(-194deg) rotateY(168deg) translateX(200px)
      rotateZ(194deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-194deg) rotateY(168deg) translateX(600px)
      rotateZ(194deg);
  }
}
.c:nth-child(571) {
  animation: orbit571 14s infinite;
  animation-delay: 5.71s;
  background-color: #00a7ff;
}

@keyframes orbit571 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-230deg) rotateY(260deg) translateX(200px)
      rotateZ(230deg);
  }
  80% {
    transform: rotateZ(-230deg) rotateY(260deg) translateX(200px)
      rotateZ(230deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-230deg) rotateY(260deg) translateX(600px)
      rotateZ(230deg);
  }
}
.c:nth-child(572) {
  animation: orbit572 14s infinite;
  animation-delay: 5.72s;
  background-color: #00a4ff;
}

@keyframes orbit572 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-49deg) rotateY(169deg) translateX(200px) rotateZ(49deg);
  }
  80% {
    transform: rotateZ(-49deg) rotateY(169deg) translateX(200px) rotateZ(49deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-49deg) rotateY(169deg) translateX(600px) rotateZ(49deg);
  }
}
.c:nth-child(573) {
  animation: orbit573 14s infinite;
  animation-delay: 5.73s;
  background-color: #00a2ff;
}

@keyframes orbit573 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-215deg) rotateY(337deg) translateX(200px)
      rotateZ(215deg);
  }
  80% {
    transform: rotateZ(-215deg) rotateY(337deg) translateX(200px)
      rotateZ(215deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-215deg) rotateY(337deg) translateX(600px)
      rotateZ(215deg);
  }
}
.c:nth-child(574) {
  animation: orbit574 14s infinite;
  animation-delay: 5.74s;
  background-color: #009fff;
}

@keyframes orbit574 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-108deg) rotateY(344deg) translateX(200px)
      rotateZ(108deg);
  }
  80% {
    transform: rotateZ(-108deg) rotateY(344deg) translateX(200px)
      rotateZ(108deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-108deg) rotateY(344deg) translateX(600px)
      rotateZ(108deg);
  }
}
.c:nth-child(575) {
  animation: orbit575 14s infinite;
  animation-delay: 5.75s;
  background-color: #009cff;
}

@keyframes orbit575 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-116deg) rotateY(187deg) translateX(200px)
      rotateZ(116deg);
  }
  80% {
    transform: rotateZ(-116deg) rotateY(187deg) translateX(200px)
      rotateZ(116deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-116deg) rotateY(187deg) translateX(600px)
      rotateZ(116deg);
  }
}
.c:nth-child(576) {
  animation: orbit576 14s infinite;
  animation-delay: 5.76s;
  background-color: #0099ff;
}

@keyframes orbit576 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-355deg) rotateY(252deg) translateX(200px)
      rotateZ(355deg);
  }
  80% {
    transform: rotateZ(-355deg) rotateY(252deg) translateX(200px)
      rotateZ(355deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-355deg) rotateY(252deg) translateX(600px)
      rotateZ(355deg);
  }
}
.c:nth-child(577) {
  animation: orbit577 14s infinite;
  animation-delay: 5.77s;
  background-color: #0096ff;
}

@keyframes orbit577 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-302deg) rotateY(325deg) translateX(200px)
      rotateZ(302deg);
  }
  80% {
    transform: rotateZ(-302deg) rotateY(325deg) translateX(200px)
      rotateZ(302deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-302deg) rotateY(325deg) translateX(600px)
      rotateZ(302deg);
  }
}
.c:nth-child(578) {
  animation: orbit578 14s infinite;
  animation-delay: 5.78s;
  background-color: #0093ff;
}

@keyframes orbit578 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-11deg) rotateY(12deg) translateX(200px) rotateZ(11deg);
  }
  80% {
    transform: rotateZ(-11deg) rotateY(12deg) translateX(200px) rotateZ(11deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-11deg) rotateY(12deg) translateX(600px) rotateZ(11deg);
  }
}
.c:nth-child(579) {
  animation: orbit579 14s infinite;
  animation-delay: 5.79s;
  background-color: #0091ff;
}

@keyframes orbit579 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-240deg) rotateY(356deg) translateX(200px)
      rotateZ(240deg);
  }
  80% {
    transform: rotateZ(-240deg) rotateY(356deg) translateX(200px)
      rotateZ(240deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-240deg) rotateY(356deg) translateX(600px)
      rotateZ(240deg);
  }
}
.c:nth-child(580) {
  animation: orbit580 14s infinite;
  animation-delay: 5.8s;
  background-color: #008eff;
}

@keyframes orbit580 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-254deg) rotateY(81deg) translateX(200px) rotateZ(254deg);
  }
  80% {
    transform: rotateZ(-254deg) rotateY(81deg) translateX(200px) rotateZ(254deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-254deg) rotateY(81deg) translateX(600px) rotateZ(254deg);
  }
}
.c:nth-child(581) {
  animation: orbit581 14s infinite;
  animation-delay: 5.81s;
  background-color: #008bff;
}

@keyframes orbit581 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-314deg) rotateY(340deg) translateX(200px)
      rotateZ(314deg);
  }
  80% {
    transform: rotateZ(-314deg) rotateY(340deg) translateX(200px)
      rotateZ(314deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-314deg) rotateY(340deg) translateX(600px)
      rotateZ(314deg);
  }
}
.c:nth-child(582) {
  animation: orbit582 14s infinite;
  animation-delay: 5.82s;
  background-color: #0088ff;
}

@keyframes orbit582 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-264deg) rotateY(244deg) translateX(200px)
      rotateZ(264deg);
  }
  80% {
    transform: rotateZ(-264deg) rotateY(244deg) translateX(200px)
      rotateZ(264deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-264deg) rotateY(244deg) translateX(600px)
      rotateZ(264deg);
  }
}
.c:nth-child(583) {
  animation: orbit583 14s infinite;
  animation-delay: 5.83s;
  background-color: #0085ff;
}

@keyframes orbit583 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-88deg) rotateY(272deg) translateX(200px) rotateZ(88deg);
  }
  80% {
    transform: rotateZ(-88deg) rotateY(272deg) translateX(200px) rotateZ(88deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-88deg) rotateY(272deg) translateX(600px) rotateZ(88deg);
  }
}
.c:nth-child(584) {
  animation: orbit584 14s infinite;
  animation-delay: 5.84s;
  background-color: #0082ff;
}

@keyframes orbit584 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-309deg) rotateY(298deg) translateX(200px)
      rotateZ(309deg);
  }
  80% {
    transform: rotateZ(-309deg) rotateY(298deg) translateX(200px)
      rotateZ(309deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-309deg) rotateY(298deg) translateX(600px)
      rotateZ(309deg);
  }
}
.c:nth-child(585) {
  animation: orbit585 14s infinite;
  animation-delay: 5.85s;
  background-color: #0080ff;
}

@keyframes orbit585 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-15deg) rotateY(269deg) translateX(200px) rotateZ(15deg);
  }
  80% {
    transform: rotateZ(-15deg) rotateY(269deg) translateX(200px) rotateZ(15deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-15deg) rotateY(269deg) translateX(600px) rotateZ(15deg);
  }
}
.c:nth-child(586) {
  animation: orbit586 14s infinite;
  animation-delay: 5.86s;
  background-color: #007dff;
}

@keyframes orbit586 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-327deg) rotateY(139deg) translateX(200px)
      rotateZ(327deg);
  }
  80% {
    transform: rotateZ(-327deg) rotateY(139deg) translateX(200px)
      rotateZ(327deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-327deg) rotateY(139deg) translateX(600px)
      rotateZ(327deg);
  }
}
.c:nth-child(587) {
  animation: orbit587 14s infinite;
  animation-delay: 5.87s;
  background-color: #007aff;
}

@keyframes orbit587 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-226deg) rotateY(316deg) translateX(200px)
      rotateZ(226deg);
  }
  80% {
    transform: rotateZ(-226deg) rotateY(316deg) translateX(200px)
      rotateZ(226deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-226deg) rotateY(316deg) translateX(600px)
      rotateZ(226deg);
  }
}
.c:nth-child(588) {
  animation: orbit588 14s infinite;
  animation-delay: 5.88s;
  background-color: #0077ff;
}

@keyframes orbit588 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-333deg) rotateY(242deg) translateX(200px)
      rotateZ(333deg);
  }
  80% {
    transform: rotateZ(-333deg) rotateY(242deg) translateX(200px)
      rotateZ(333deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-333deg) rotateY(242deg) translateX(600px)
      rotateZ(333deg);
  }
}
.c:nth-child(589) {
  animation: orbit589 14s infinite;
  animation-delay: 5.89s;
  background-color: #0074ff;
}

@keyframes orbit589 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-72deg) rotateY(245deg) translateX(200px) rotateZ(72deg);
  }
  80% {
    transform: rotateZ(-72deg) rotateY(245deg) translateX(200px) rotateZ(72deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-72deg) rotateY(245deg) translateX(600px) rotateZ(72deg);
  }
}
.c:nth-child(590) {
  animation: orbit590 14s infinite;
  animation-delay: 5.9s;
  background-color: #0071ff;
}

@keyframes orbit590 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-133deg) rotateY(293deg) translateX(200px)
      rotateZ(133deg);
  }
  80% {
    transform: rotateZ(-133deg) rotateY(293deg) translateX(200px)
      rotateZ(133deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-133deg) rotateY(293deg) translateX(600px)
      rotateZ(133deg);
  }
}
.c:nth-child(591) {
  animation: orbit591 14s infinite;
  animation-delay: 5.91s;
  background-color: #006fff;
}

@keyframes orbit591 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-333deg) rotateY(343deg) translateX(200px)
      rotateZ(333deg);
  }
  80% {
    transform: rotateZ(-333deg) rotateY(343deg) translateX(200px)
      rotateZ(333deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-333deg) rotateY(343deg) translateX(600px)
      rotateZ(333deg);
  }
}
.c:nth-child(592) {
  animation: orbit592 14s infinite;
  animation-delay: 5.92s;
  background-color: #006cff;
}

@keyframes orbit592 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-331deg) rotateY(218deg) translateX(200px)
      rotateZ(331deg);
  }
  80% {
    transform: rotateZ(-331deg) rotateY(218deg) translateX(200px)
      rotateZ(331deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-331deg) rotateY(218deg) translateX(600px)
      rotateZ(331deg);
  }
}
.c:nth-child(593) {
  animation: orbit593 14s infinite;
  animation-delay: 5.93s;
  background-color: #0069ff;
}

@keyframes orbit593 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-287deg) rotateY(269deg) translateX(200px)
      rotateZ(287deg);
  }
  80% {
    transform: rotateZ(-287deg) rotateY(269deg) translateX(200px)
      rotateZ(287deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-287deg) rotateY(269deg) translateX(600px)
      rotateZ(287deg);
  }
}
.c:nth-child(594) {
  animation: orbit594 14s infinite;
  animation-delay: 5.94s;
  background-color: #0066ff;
}

@keyframes orbit594 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-162deg) rotateY(224deg) translateX(200px)
      rotateZ(162deg);
  }
  80% {
    transform: rotateZ(-162deg) rotateY(224deg) translateX(200px)
      rotateZ(162deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-162deg) rotateY(224deg) translateX(600px)
      rotateZ(162deg);
  }
}
.c:nth-child(595) {
  animation: orbit595 14s infinite;
  animation-delay: 5.95s;
  background-color: #0063ff;
}

@keyframes orbit595 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-340deg) rotateY(6deg) translateX(200px) rotateZ(340deg);
  }
  80% {
    transform: rotateZ(-340deg) rotateY(6deg) translateX(200px) rotateZ(340deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-340deg) rotateY(6deg) translateX(600px) rotateZ(340deg);
  }
}
.c:nth-child(596) {
  animation: orbit596 14s infinite;
  animation-delay: 5.96s;
  background-color: #0060ff;
}

@keyframes orbit596 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-123deg) rotateY(188deg) translateX(200px)
      rotateZ(123deg);
  }
  80% {
    transform: rotateZ(-123deg) rotateY(188deg) translateX(200px)
      rotateZ(123deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-123deg) rotateY(188deg) translateX(600px)
      rotateZ(123deg);
  }
}
.c:nth-child(597) {
  animation: orbit597 14s infinite;
  animation-delay: 5.97s;
  background-color: #005eff;
}

@keyframes orbit597 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-257deg) rotateY(152deg) translateX(200px)
      rotateZ(257deg);
  }
  80% {
    transform: rotateZ(-257deg) rotateY(152deg) translateX(200px)
      rotateZ(257deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-257deg) rotateY(152deg) translateX(600px)
      rotateZ(257deg);
  }
}
.c:nth-child(598) {
  animation: orbit598 14s infinite;
  animation-delay: 5.98s;
  background-color: #005bff;
}

@keyframes orbit598 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-153deg) rotateY(51deg) translateX(200px) rotateZ(153deg);
  }
  80% {
    transform: rotateZ(-153deg) rotateY(51deg) translateX(200px) rotateZ(153deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-153deg) rotateY(51deg) translateX(600px) rotateZ(153deg);
  }
}
.c:nth-child(599) {
  animation: orbit599 14s infinite;
  animation-delay: 5.99s;
  background-color: #0058ff;
}

@keyframes orbit599 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-123deg) rotateY(190deg) translateX(200px)
      rotateZ(123deg);
  }
  80% {
    transform: rotateZ(-123deg) rotateY(190deg) translateX(200px)
      rotateZ(123deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-123deg) rotateY(190deg) translateX(600px)
      rotateZ(123deg);
  }
}
.c:nth-child(600) {
  animation: orbit600 14s infinite;
  animation-delay: 6s;
  background-color: #0055ff;
}

@keyframes orbit600 {
  20% {
    opacity: 1;
  }
  30% {
    transform: rotateZ(-174deg) rotateY(338deg) translateX(200px)
      rotateZ(174deg);
  }
  80% {
    transform: rotateZ(-174deg) rotateY(338deg) translateX(200px)
      rotateZ(174deg);
    opacity: 1;
  }
  100% {
    transform: rotateZ(-174deg) rotateY(338deg) translateX(600px)
      rotateZ(174deg);
  }
}
